import { DropDownIcon, AnswerCheck } from "../../../assets/icon";
import { TurkeyCity } from "../../../data/TurkeyCityData";
import style from "./dropdown.module.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { ApiRoutes } from "../../../utils/ApiRoutes";
import { useSelector } from "react-redux";

const DropDown = ({
  title,
  country,
  selectedCountry,
  selected,
  change,
  data,
}) => {
  const [isOpen, setOpen] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);
  const [selection, setSelection] = useState([]);
  console.log(
    "country",
    selectedCountry && selectedCountry.length && selectedCountry.includes("TR")
  );

  const getCityData = (value) => {
    axios
      .post(`${ApiRoutes.search.city}?country=${value}&lang=${lang}`)
      .then((res) => {
        console.log(res.data);
        const rawData = JSON.parse(res.data.data);
        if (rawData && rawData.length) {
          console.log("rawData", rawData);
          setSelection(rawData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!country) {
    console.log("selectedCountry", selection);
  }

  useEffect(() => {
    if (country) {
      setSelection(data);
    } else if (selectedCountry && selectedCountry.length) {
      for (let index = 0; index < selectedCountry.length; index++) {
        if (
          selectedCountry[index] !== "TR" ||
          selectedCountry[index] !== "DE"
        ) {
          getCityData(selectedCountry[index]);
        }
      }
    }
  }, [selectedCountry, country]);

  return (
    <div className={style.dropDown}>
      <button
        onClick={() => setOpen(!isOpen)}
        className={`${style.title} ${isOpen && style.rotate}`}
      >
        {title} <DropDownIcon />
      </button>
      <div className={`${style.selectionWrapper} ${isOpen && style.open}`}>
        {country
          ? selection && selection.length
            ? selection.map((item) => {
                return (
                  <button
                    className={selected.includes(item[0]) && style.active}
                    onClick={() => {
                      if (selected.includes(item[0])) {
                        change(selected.filter((i) => i !== item[0]));
                      } else {
                        change([...selected, item[0]]);
                      }
                    }}
                  >
                    <div className={style.square}>
                      <AnswerCheck />
                    </div>
                    {item[1].emoji} {item[1].name}
                  </button>
                );
              })
            : null
          : selectedCountry && selectedCountry.length
          ? selection && selection.length
            ? selection.map((item, index) => {
                if (index === 0) {
                  console.log("city", item);
                }
                return (
                  <button
                    className={selected.includes(item.name) && style.active}
                    onClick={() => {
                      if (selected.includes(item.name)) {
                        change(selected.filter((i) => i !== item.name));
                      } else {
                        change([...selected, item.name]);
                      }
                    }}
                  >
                    <div className={style.square}>
                      <AnswerCheck />
                    </div>
                    {item}
                  </button>
                );
              })
            : null
          : null}
        <>
          {selectedCountry &&
          selectedCountry.length &&
          selectedCountry.includes("TR")
            ? TurkeyCity.map((item) => {
                return (
                  <button
                    className={selected.includes(item) && style.active}
                    onClick={() => {
                      if (selected.includes(item)) {
                        change(selected.filter((i) => i !== item));
                      } else {
                        change([...selected, item]);
                      }
                    }}
                  >
                    <div className={style.square}>
                      <AnswerCheck />
                    </div>
                    {item}
                  </button>
                );
              })
            : null}
        </>
      </div>
    </div>
  );
};

export default DropDown;
