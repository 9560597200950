import style from "./login.module.scss";
import {
  EmailInput,
  PasswordInput,
  RememberMe,
} from "../../components/Input/Input";
import { AuthTitle } from "../../components/Title/Title";
import { AuthButton } from "../../components/Button/Button";
import { AuthFooter, LangSelection } from "../../components";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import axios from "axios";

import LoginImage from "../../assets/img/auth/login2.png";
import Logo from "../../assets/img/logo/primary-logo.svg";
import WhiteLogo from "../../assets/img/logo/white-logo.svg";
import { ApiRoutes, BASE_URL } from "../../utils/ApiRoutes";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useWindowDimensions from "../../helpers/windowWidth";
import { LangData } from "../../data/LangData";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { width } = useWindowDimensions();
  const lang = useSelector((state) => state.settings.setting.lang);
  const [isLoad, setLoad] = useState(false);

  const loginSubmit = (values) => {
    axios
      .post(`${ApiRoutes.auth.user.login}?lang=${lang}`, {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        if (res.data.caption === "Success") {
          const userData = JSON.parse(res.data.data);
          localStorage.setItem("refresh", "login");

          axios.defaults.baseURL = BASE_URL;
          axios.interceptors.request.use(
            async (config) => {
              // const token = await AsyncStorage.getItem('token');
              config.headers["Authorization"] = `Bearer ${userData.token}`;
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
          localStorage.setItem("token", userData.token);
          dispatch(
            userLogin({
              token: userData.token,
              userData: userData.account,
            })
          );
          navigate("/dashboard");
        } else if (res.data.caption === "Null") {
          setError("loginError");
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    password: Yup.string()
      .min(8, `${LangData[lang].errors.correctpassword}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  return (
    <div className={style.login}>
      <div
        style={{
          position: "absolute",
          right: "30px",
          bottom: "30px",
        }}
      >
        <LangSelection />
      </div>
      <a href="/">
        <img
          className={style.logo}
          src={width < 940 ? WhiteLogo : Logo}
          alt="primary-logo"
        />
      </a>
      <div className={style.content}>
        <div className={style.wrapper}>
          <AuthTitle title={LangData[lang].login.title} />
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              setLoad(true);
              loginSubmit(values);
              // createPayment(values);
            }}

            // innerRef={formikRef}
          >
            {({ errors, touched, values, handleSubmit }) => (
              <Form id="payment" onSubmit={handleSubmit}>
                <div className={style.inputWrapper}>
                  <EmailInput name={"email"} value={values.email} />
                  {errors.email && touched.email ? (
                    <span>{errors.email}</span>
                  ) : null}
                  <PasswordInput value={values.password} name="password" />
                  {errors.password && touched.password ? (
                    <span>{errors.password}</span>
                  ) : null}
                  {error === "loginError" ? (
                    <span>{LangData[lang].errors.login}</span>
                  ) : null}
                </div>
                <RememberMe />
                <div className={style.buttonWrapper}>
                  <AuthButton
                    text={LangData[lang].login.title}
                    type={"submit"}
                    isLoad={isLoad}
                  />
                  <AuthButton
                    text={LangData[lang].register.title}
                    type={"register"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <img className={style.banner} src={LoginImage} alt="login" />
      <AuthFooter />
    </div>
  );
};

export default Login;
