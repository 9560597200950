import style from "./sidebar.module.scss";
import {
  HomeIcon,
  SearchIcon,
  SignOutIcon,
  ProfileIcon,
  MessageIcon,
  CalendarIcon,
  FolderIcon,
  CreditIcon,
} from "../../../assets/icon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Logo from "../../../assets/img/logo/primary-logo.svg";
import { signOut } from "../../../redux/actions";
import useWindowDimensions from "../../../helpers/windowWidth";
import { useSelector } from "react-redux";
import { LangData } from "../../../data/LangData";
import { useState } from "react";
import LangSelection from "../../LangSelection/LangSelection";
import axios from "axios";
import { useEffect } from "react";

const menuData = (lang) => {
  if (lang === "de") {
    return [
      {
        title: LangData[lang].sideBar.dashboard,
        icon: <HomeIcon />,
        link: "/dashboard",
      },
      {
        title: LangData[lang].sideBar.profile,
        icon: <ProfileIcon />,
        link: "/profile",
      },
      {
        title: LangData[lang].sideBar.message,
        icon: <MessageIcon />,
        link: "/message",
      },
      {
        title: LangData[lang].sideBar.operations,
        icon: <FolderIcon />,
        link: "/operations",
      },
      {
        title: LangData[lang].sideBar.calendar,
        icon: <CalendarIcon />,
        link: "/calendar",
      },
      {
        title: LangData[lang].sideBar.credit,
        icon: <CreditIcon />,
        link: "/credit",
      },
      {
        title: LangData[lang].sideBar.search,
        icon: <SearchIcon />,
        link: "/search",
      },
    ];
  } else {
    return [
      {
        title: LangData[lang].sideBar.dashboard,
        icon: <HomeIcon />,
        link: "/dashboard",
      },
      {
        title: LangData[lang].sideBar.profile,
        icon: <ProfileIcon />,
        link: "/profile",
      },
      {
        title: LangData[lang].sideBar.message,
        icon: <MessageIcon />,
        link: "/message",
      },
      {
        title: LangData[lang].sideBar.operations,
        icon: <FolderIcon />,
        link: "/operations",
      },
      {
        title: LangData[lang].sideBar.calendar,
        icon: <CalendarIcon />,
        link: "/calendar",
      },
      {
        title: LangData[lang].sideBar.search,
        icon: <SearchIcon />,
        link: "/search",
      },
    ];
  }
};

const Sidebar = ({ closeClick, isOpen, searchTrigger }) => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const lang = useSelector((state) => state.settings.setting.lang);
  const [word, setWord] = useState("");
  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value && word) {
      navigate(`/search?keyword=${word}`);
      searchTrigger(word);
    }
  };

  useEffect(() => {
    const searchWord = new URLSearchParams(location.search);
    setWord(searchWord.get("keyword") ? searchWord.get("keyword") : "");
  }, []);

  return (
    <>
      <div className={`${style.sidebar} ${!isOpen && style.close}`}>
        <div className={style.top}>
          {width < 940 ? null : (
            <a href="/dashboard">
              <img src={Logo} alt="logo" />
            </a>
          )}
          <div className={style.menu}>
            {menuData(lang).map((item, index) => {
              return (
                <a
                  href={item.link}
                  className={currentLocation === item.link && style.active}
                >
                  {item.icon} <span>{item.title}</span>
                </a>
              );
            })}
          </div>
        </div>
        {width < 940 ? (
          <label className={style.mobileSearch}>
            <input
              onChange={(e) => setWord(e.target.value)}
              type={"text"}
              placeholder={LangData[lang].search.title}
              onKeyDown={handleKeyDown}
              value={word}
            />
            <button
              onClick={() => {
                navigate(`/search?keyword=${word}`);
                searchTrigger(word);
              }}
            >
              <SearchIcon />
            </button>
          </label>
        ) : null}
        {width < 940 ? <LangSelection sidebar /> : null}
        <button
          onClick={() => {
            delete axios.defaults.headers["Authorization"];

            dispatch(signOut());
            localStorage.removeItem("token");
          }}
          className={style.signOut}
        >
          <SignOutIcon /> <span>{LangData[lang].sideBar.signOut}</span>
        </button>
      </div>

      {width < 940 ? (
        <button
          onClick={closeClick}
          className={`${style.shadowButton} ${!isOpen && style.close}`}
        />
      ) : null}
    </>
  );
};

export default Sidebar;
