export const TurkeyCity = [
  'ADANA',
  'ADIYAMAN',
  'AFYONKARAHİSAR',
  'AĞRI',
  'AMASYA',
  'ANKARA',
  'ANTALYA',
  'ARTVİN',
  'AYDIN',
  'BALIKESİR',
  'BİLECİKK',
  'BİNGÖL',
  'BİTLİS',
  'BOLU',
  'BURDUR',
  'BURSA',
  'ÇANAKKALE',
  'ÇANKIRI',
  'ÇORUM',
  'DENİZLİ',
  'DİYARBAKIR',
  'EDİRNE',
  'ELAZIĞ',
  'ERZİNCAN',
  'ERZURUM',
  'ESKİŞEHİR',
  'GAZİANTEP',
  'GİRESUN',
  'GÜMÜŞHANE',
  'HAKKARİ',
  'HATAY',
  'ISPARTA',
  'MERSİN',
  'İSTANBUL',
  'İZMİR',
  'KARS',
  'KASTAMONU',
  'KAYSERİ',
  'KIRKLARELİ',
  'KIRŞEHİR',
  'KOCAELİ',
  'KONYA',
  'KÜTAHYA',
  'MALATYA',
  'MANİSA',
  'KAHRAMANMARAŞ',
  'MARDİN',
  'MUĞLA',
  'MUŞ',
  'NEVŞEHİR',
  'NİĞDE',
  'ORDU',
  'RİZE',
  'SAKARYA',
  'SAMSUN',
  'SİİRT',
  'SİNOP',
  'SİVAS',
  'TEKİRDAĞ',
  'TOKAT',
  'TRABZON',
  'TUNCELİ',
  'ŞANLIURFA',
  'UŞAK',
  'VAN',
  'YOZGAT',
  'ZONGULDAK',
  'AKSARAY',
  'BAYBURT',
  'KARAMAN',
  'KIRIKKALE',
  'BATMAN',
  'ŞIRNAK',
  'BARTIN',
  'ARDAHAN',
  'IĞDIR',
  'YALOVA',
  'KARABüK',
  'KİLİS',
  'OSMANİYE',
  'DÜZCE',
];
