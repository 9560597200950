import style from "./operationcard.module.scss";
import {
  FlightIcon,
  LocationIcon,
  OperationIcon,
  OperationCardIcon,
  FolderIcon,
  DownloadIcon,
} from "../../../assets/icon";

import DoctorImage from "../../../assets/img/doctor-image.png";
import useWindowDimensions from "../../../helpers/windowWidth";

import Medical1 from "../../../assets/img/dashboard/operation/medical-1.png";
import Medical2 from "../../../assets/img/dashboard/operation/medical-2.png";
import Medical3 from "../../../assets/img/dashboard/operation/medical-3.png";
import Medical4 from "../../../assets/img/dashboard/operation/medical-4.png";
import { useNavigate } from "react-router-dom";
import { CheckImage } from "../../../helpers/CheckImage";
import { useSelector } from "react-redux";
import { LangData } from "../../../data/LangData";

const Card = ({
  image,
  doctorImage,
  doctorName,
  icon,
  operation,
  location,
  time,
  hotel,
  date,
  id,
  userName,
  lang,
}) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  return width > 940 ? (
    <div className={style.card}>
      <div className={style.top}>
        <div className={style.left}>
          <div className={style.doctor}>
            {/* <img src={doctorImage} alt="doctor" /> */}
            <div className={style.avatar}>{`${doctorName ? doctorName[0] : ""}${
              doctorName && doctorName.length ? doctorName.split(" ")[1][0] : ""
            }`}</div>
            <h5>{doctorName}</h5>
          </div>
          <div className={style.doctor}>
            {/* <img src={doctorImage} alt="doctor" /> */}
            <div className={style.avatar}>{`${userName ? userName[0] : ""}${
              userName ? userName.split(" ")[1][0] : ""
            }`}</div>
            <h5>{userName}</h5>
          </div>
          <div className={style.operation}>
            <div className={style.icon}>{<FolderIcon />}</div>
            <h5>{operation}</h5>
          </div>
          {/* <div className={style.flight}>
            <div className={style.icon}>
              <FlightIcon />
            </div>
            <div className={style.info}>
              <h6>{location}</h6>
              <p>{time}</p>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.icon}>
              <LocationIcon />
            </div>
            <div className={style.info}>
              <h6>{hotel}</h6>
              <p>{date}</p>
            </div>
          </div> */}
        </div>
        <div className={style.right}>
          <div className={style.timeWrapper}>
            <div>
              <OperationCardIcon type="date" />
              <h5>{date}</h5>
            </div>
            <div>
              <OperationCardIcon type="clock" />
              <h5>{time}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.imageWrapper}>
          {image && image.length
            ? image.slice(0, 3).map((item) => {
                return CheckImage(item) ? (
                  <img src={item} alt="operation" />
                ) : (
                  <a href={item} download className={style.folderWrapper}>
                    <FolderIcon />
                    <DownloadIcon />
                  </a>
                );
              })
            : null}
          {image && image.length && image.length > 3 ? (
            <button className={style.more}>
              <span>+{image.length - 3}</span> {LangData[lang].operations.more}
            </button>
          ) : null}
        </div>
        <button
          onClick={() => navigate(`/operation/${id}`)}
          className={style.details}
        >
          {LangData[lang].operations.viewDetails}
        </button>
      </div>
    </div>
  ) : (
    <div className={style.card}>
      <div className={style.top}>
        <div className={style.timeWrapper}>
          <div>
            <OperationCardIcon type="date" />
            <h5>{date}</h5>
          </div>
          <div>
            <OperationCardIcon type="clock" />
            <h5>{time}</h5>
          </div>
        </div>
      </div>
      <div className={style.topCenter}>
        <div className={style.doctor}>
          {/* <img src={doctorImage} alt="doctor" /> */}
          <div className={style.avatar}>{`${doctorName ? doctorName[0] : ""}${
            doctorName && doctorName.length ? doctorName.split(" ")[1][0] : ""
          }`}</div>
          <h5>{doctorName}</h5>
        </div>
        <div className={style.doctor}>
          {/* <img src={doctorImage} alt="doctor" /> */}
          <div className={style.avatar}>{`${userName ? userName[0] : ""}${
            userName ? userName.split(" ")[1][0] : ""
          }`}</div>
          <h5>{userName}</h5>
        </div>
        <div className={style.operation}>
          <div className={style.icon}>{<FolderIcon />}</div>
          <h5>{operation}</h5>
        </div>
      </div>

      {/* <div className={style.center}>
        <div className={style.flight}>
          <div className={style.icon}>
            <FlightIcon />
          </div>
          <div className={style.info}>
            <h6>{location}</h6>
            <p>{time}</p>
          </div>
        </div>
        <div className={style.location}>
          <div className={style.icon}>
            <LocationIcon />
          </div>
          <div className={style.info}>
            <h6>{hotel}</h6>
            <p>{date}</p>
          </div>
        </div>
      </div> */}

      <div className={style.bottomCenter}>
        <div className={style.imageWrapper}>
          {image && image.length
            ? image.slice(0, 3).map((item) => {
                return CheckImage(item) ? (
                  <img src={item} alt="operation" />
                ) : (
                  <a href={item} download className={style.folderWrapper}>
                    <FolderIcon />
                    <DownloadIcon />
                  </a>
                );
              })
            : null}
          {image && image.length && image.length > 3 ? (
            <button className={style.more}>
              <span>+{image.length - 3}</span> {LangData[lang].operations.more}
            </button>
          ) : null}
        </div>
      </div>

      <div className={style.bottom}>
        <button
          onClick={() => navigate(`/operation/${id}`)}
          className={style.details}
        >
          {LangData[lang].operations.viewDetails}
        </button>
      </div>
    </div>
  );
};

// const data = [
//   {
//     doctorName: "Doc. Dr. Stephan",
//     doctorImage: DoctorImage,
//     icon: <OperationIcon icon={"rhinoplasty"} />,
//     operation: "Rhinoplasty",
//     location: "America - Istanbul",
//     time: "00:00 - 08:30",
//     date: "10.12.22 - 26.12.22 ",
//     hotel: "Point Hotel",
//     image: [Medical1, Medical2, Medical3, Medical4],
//   },
//   {
//     doctorName: "Doc. Dr. Stephan",
//     doctorImage: DoctorImage,
//     icon: <OperationIcon icon={"rhinoplasty"} />,
//     operation: "Rhinoplasty",
//     location: "America - Istanbul",
//     time: "00:00 - 08:30",
//     date: "10.12.22 - 26.12.22 ",
//     hotel: "Point Hotel",
//     image: [Medical1, Medical2, Medical3, Medical4],
//   },
// ];

const OperationCard = ({ data }) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <div className={style.operationCard}>
      <div className={style.cardWrapper}>
        {data.map((item) => {
          return (
            <Card
              date={item.datetime ? item.datetime.split("T")[0] : ""}
              time={item.time}
              // location={item.location}
              // hotel={item.hotel}
              doctorName={item.doctorNameSurname}
              image={item.url}
              // doctorImage={item.doctorImage}
              // icon={item.icon}
              id={item.Id}
              operation={item.operationName}
              userName={item.userNameSurname}
              lang={lang}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OperationCard;
