import { useState } from "react";
import { AnswerCheck } from "../../../assets/icon";
import style from "./selection.module.scss";

const Selection = ({ title, selection, change, selected }) => {
  console.log(title, selected);
  return (
    <div className={style.selection}>
      <h5>{title}</h5>
      <div className={style.selectionWrapper}>
        {selection.map((item) => {
          return (
            <button
              className={selected.includes(item.Id) && style.active}
              onClick={() => {
                if (selected.includes(item.Id)) {
                  change(selected.filter((i) => i !== item.Id));
                } else {
                  change([...selected, item.Id]);
                }
              }}
            >
              <div className={style.square}>
                <AnswerCheck />
              </div>
              {item.Name ? item.Name : item}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Selection;
