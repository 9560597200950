import style from "./head.module.scss";
import VideoCover from "../../../assets/img/video-cover.jpg";
import { PlayIcon } from "../../../assets/icon";
import LandingVideo from "../../../assets/video/landing-video-turkce.mp4";
import LandingVideoAL from "../../../assets/video/landing-video-almanca.mp4";
import { useEffect, useState } from "react";
import { LangData } from "../../../data/LangData";
import { useSelector } from "react-redux";

const VideoCard = ({ isActive, click, url }) => {
  return (
    <video onClick={click} autoPlay={isActive} controls key={url}>
      <source src={url} type="video/mp4" />
    </video>
  );
};

const Head = () => {
  const [isActive, setActive] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);
  const [currentVideo, setCurrentVideo] = useState();

  useEffect(() => {
    if (lang === "de") {
      setCurrentVideo(LandingVideoAL);
    } else {
      setCurrentVideo(LandingVideo);
    }
  }, [lang]);

  return (
    <div className={`${style.head} ${isActive && style.active}`}>
      <div className={style.info}>
        <h1>{LangData[lang].landing.head.title}</h1>
        <h2>{LangData[lang].landing.head.title2}</h2>
        <p>{LangData[lang].landing.head.text}</p>
        <div className={style.buttonWrapper}>
          <a href="/search" className={style.mainButton}>
            {LangData[lang].landing.head.seeDoctors}
          </a>
          <a href="/contact" className={style.contact}>
            {LangData[lang].landing.head.contact}
          </a>
        </div>
        {/* <a href="/register">Register</a> */}
      </div>
      <div className={style.videoWrapper}>
        <div className={style.videoCard}>
          <img src={VideoCover} alt="cover" />
          <VideoCard
            isActive={isActive}
            click={() => setActive(!isActive)}
            url={lang === "de" ? LandingVideoAL : LandingVideo}
          />
          <button onClick={() => setActive(true)}>
            <PlayIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Head;
