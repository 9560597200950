import style from "./bank.module.scss";
import Logo from "../../../assets/img/dashboard/hsbc-logo.png";
import BankImage from "../../../assets/img/bank-image.png";

const Bank = () => {
  return (
    <a href="/credit" className={style.bank}>
      <img src={BankImage} />
      {/* <div className={style.logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={style.content}>
        <div className={style.info}>
          <h3>Faiz olmadan 20.000 TL’ye kadar kredi imkanı</h3>
          <p>Hemen başvurmak için detaylı bilgi alınız.</p>
        </div>
        <button>Detaylı Bilgi</button>
      </div> */}
    </a>
  );
};

export default Bank;
