import { useState } from "react";
import { useSelector } from "react-redux";

import Blog1 from "../../../assets/img/blog/blog-1.png";
import Blog2 from "../../../assets/img/blog/blog-2.png";
import Blog3 from "../../../assets/img/blog/blog-3.png";
import PersonImage from "../../../assets/img/blog/person.png";
import TitleWrapper from "../TitleWrapper/TitleWrapper";
import style from "./blog.module.scss";
import { LangData } from "../../../data/LangData";


const Card = ({ image, title, text, personImage, date, person,lang }) => {
  return (
    <div className={style.card}>
      <img className={style.cover} src={image} />
      <div className={style.bottomWrapper}>
        <div className={style.textWrapper}>
          <h4>{title}</h4>
          <p>{text}</p>
        </div>
        <div className={style.bottom}>
          <div className={style.personWrapper}>
          {/*   <img src={personImage} />*/}
            <h5>{person}</h5>
          </div>
          <p className={style.date}>{date}</p>
        </div>
        <button className={style.readMore}>{LangData[lang].landing.blog.readMore}</button>
      </div>
    </div>
  );
};

const Blog = () => {
  const [isOpen, setOpen] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <div className={style.wrapper}>
      <TitleWrapper title={LangData[lang].landing.blog.title} subTitle={LangData[lang].landing.blog.subtitle} />
      <div className={style.cardWrapper}>
        {[...new Array(3)].map((item, index) => {
          return (
            <Card
              title={LangData[lang].landing.blog.blogs[index].title}
              text={ LangData[lang].landing.blog.blogs[index].text}
              person={LangData[lang].landing.blog.blogs[index].person}
              date={LangData[lang].landing.blog.blogs[index].date}
              personImage={PersonImage}
              image={index === 0 ? Blog1 : index === 1 ? Blog2 : Blog3}
              lang={lang}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
