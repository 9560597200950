import style from './notfound.module.scss';

const NotFound = () => {
  return (
    <div className={style.notFound}>
      <h2>LOOK LIKE YOU’RE LOST</h2>
      <h1>404</h1>
      <h3>PAGE NOT FOUND</h3>
      <a href="/dashboard">Back to Page</a>
    </div>
  );
};

export default NotFound;
