import style from "./featureddoctor.module.scss";
import {
  FlightIcon,
  HomeOperationIcon,
  HomeDateIcon,
  FlagIcon,
  SearchIcon,
  CountryIconFlag,
  StarIcon,
  OperationIcon,
  OperationCardIcon,
  HomeBodyIcon,
} from "../../../assets/icon";
import { LangData } from "../../../data/LangData";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "../../../utils/ApiRoutes";

import Doctor1 from "../../../assets/img/landing/doctor-1.png";
import Doctor2 from "../../../assets/img/landing/doctor-2.png";
import Doctor3 from "../../../assets/img/landing/doctor-3.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import TitleWrapper from "../TitleWrapper/TitleWrapper";

const InputWrapper = ({ placeholder, searchTextData, change }) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [date, setDate] = useState();
  const lang = useSelector((state) => state.settings.setting.lang);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get(`${ApiRoutes.home.getOperation}?lang=${lang}`).then((res) => {
      setData(JSON.parse(res.data.data));
    });
  }, [lang]);

  return (
    <div className={style.inputWrapper}>
      <div className={style.labelWrapper}>
        <label>
          <HomeOperationIcon />
          {data ? (
            <select onChange={change}>
              <option selected disabled>
                {LangData[lang].form.selectOperation}
              </option>
              {data
                .map((i) => i.Name)
                .sort()
                .map((item, index) => {
                  return <option value={data[index].Id}>{item}</option>;
                })}
            </select>
          ) : null}
        </label>
        {/* <label className={style.date}>
          <HomeDateIcon />
      

          <div className={style.dateWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={open}
                onClose={() => setOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => setOpen(true),
                  },
                }}
                onChange={(e) => {
                  setDate(e);
                }}
                value={date}
              />
            </LocalizationProvider>
          </div>
          <h5>
            {date
              ? `${date.$D < 9 ? `0${date.$D}` : date.$D}-${
                  date.$M < 9 ? `0${date.$M + 1}` : date.$M + 1
                }-${date.$y}`
              : searchTextData.date}
          </h5>
        </label> */}
      </div>
      <a
        onClick={() => {
          navigate(`/search`);
        }}
      >
        <SearchIcon />
      </a>
    </div>
  );
};

const Card = ({ type, title, desc }) => {
  return (
    <div className={style.card}>
      <div className={style.icon}>
        {type === "country" ? (
          <div className={style.countryWrapper}>
            <div>
              <CountryIconFlag type={"de"} />
            </div>
            <div>
              <CountryIconFlag type={"italy"} />
            </div>
            <div>
              <CountryIconFlag type={"swis"} />
            </div>
          </div>
        ) : type === "operation" ? (
          <div className={style.operationWrapper}>
            <div>
              <HomeBodyIcon type={"nose"} />
            </div>
            <div>
              <HomeBodyIcon type={"teeth"} />
            </div>
            <div>
              <HomeBodyIcon type={"lips"} />
            </div>
          </div>
        ) : type === "star" ? (
          <div className={style.starWrapper}>
            <StarIcon type={"on"} />
            <StarIcon type={"on"} />
            <StarIcon type={"on"} />
            <StarIcon />
            <StarIcon />
          </div>
        ) : (
          <h6>€ $ ₺</h6>
        )}
      </div>
      <h4>{title}</h4>
      <p>{desc}</p>
    </div>
  );
};

const DoctorCard = ({ image, name, id, operations, region, lang }) => {
  console.log("doctorId", id);
  return (
    <a href={`/doctor/${id}`} key={id} className={style.doctorCard}>
      <img key={id} src={image} alt="doctor" />
      <div className={style.info}>
        <div className={style.name}>
          <h3>{name}</h3>
          <div className={style.country}>
            <CountryIconFlag type={region.toLowerCase()} />
            {region}
          </div>
        </div>
        <div className={style.operationCard}>
          {operations && operations.length ? (
            <>
              {operations.slice(0, 4).map((item) => {
                return <p>{item.operationName}</p>;
              })}
              {operations.length > 4 ? (
                <button>
                  +{operations.length - 4}{" "}
                  {LangData[lang].landing.featuredDoctor.operation}
                </button>
              ) : null}
            </>
          ) : null}
        </div>
        <div className={style.bottomWrapper}>
          <button>{LangData[lang].landing.featuredDoctor.viewDetails}</button>
        </div>
      </div>
    </a>
  );
};

const FeaturedDoctor = ({ lang }) => {
  const textData = LangData[lang].landing.featuredDoctor;
  const searchTextData = LangData[lang].landing.search;
  const [selected, setSelected] = useState(0);

  const [doctorData, setDoctorData] = useState();

  useEffect(() => {
    axios
      .post(
        `${ApiRoutes.search.doctor.landingDoctor}?lang=${lang}`,
        selected ? [selected] : []
      )
      .then((res) => {
        setDoctorData(JSON.parse(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selected]);

  return (
    <div className={style.featuredDoctor}>
      <TitleWrapper
        title={LangData[lang].landing.featuredDoctor.mainTitle}
        subTitle={LangData[lang].landing.featuredDoctor.subTitle}
      />
      <InputWrapper
        searchTextData={searchTextData}
        placeholder={textData.selectOperation}
        change={(e) => setSelected(parseFloat(e.target.value))}
      />
      {doctorData ? (
        <div className={style.cardWrapper}>
          {doctorData.map((item) => {
            console.log(item);
            return (
              <DoctorCard
                image={item.Picture}
                name={item.Name + " " + item.Surname}
                id={item.DoctorId}
                operations={item.DoctorOperations}
                region={item.Region}
                lang={lang}
              />
            );
          })}
        </div>
      ) : null}
      <a href="/search" className={style.more}>
        <span>{LangData[lang].landing.featuredDoctor.doctorNumber}</span>{" "}
        {LangData[lang].landing.featuredDoctor.doctorText}
      </a>
    </div>
  );
};

/*
      <h1>{textData.mainTitle}</h1>
      <div className={style.wrapper}>
        <div className={style.left}>
          <Card title={textData.card1.title} desc={textData.card1.desc} />
          <Card
            title={textData.card2.title}
            desc={textData.card2.desc}
            type={'country'}
          />
        </div>
        { <div className={style.center}>
          <DoctorCard image={Doctor1} name="Doc. Dr. Farah" />
          <DoctorCard image={Doctor2} name="Doc. Dr. Farah" />
          <DoctorCard image={Doctor3} name="Doc. Dr. Farah" />
        </div> }
        <div className={style.right}>
          <Card
            title={textData.card3.title}
            desc={textData.card3.desc}
            type={'operation'}
          />
          <Card
            title={textData.card4.title}
            desc={textData.card4.desc}
            type={'star'}
          />
        </div>
      </div>
      */

export default FeaturedDoctor;
