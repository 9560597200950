import { LANG_CHANGE } from "../type";

const initialState = {
  setting: {
    lang: "de",
  },
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case LANG_CHANGE:
      return {
        ...state,
        setting: {
          lang: action.payload,
        },
      };

    default:
      return state;
  }
}
