import Branko from "../assets/img/stories/branko.jpg";
import Ilaria from "../assets/img/stories/ilaria.jpg";
import Sulo from "../assets/img/stories/sulo.jpg";

export const LangData = {
  de: {
    landing: {
      menu: {
        contact: "Impressum",
        terms: "Nutzungsbedingungen",
        privacy: "Datenschutzrichtlinien",
      },
      head: {
        title: "Embrace Aesthetics",
        text: `YUMA CARE ist deine Plattform, wenn es um die Themen Schönheit und
        Gesundheit geht. Registriere dich jetzt kostenlos, um in den Genuss aller
        Vorteile zu kommen.`,
        title2: "Elevate Life!",
        seeDoctors: "SIEHE +200 ÄRTZE",
        contact: "Kontaktiere uns",
      },

      infoPartTitle: "Das macht uns einzigartig",
      infoPartSubtitle: "",
      infoPart: [
        {
          title: "Arztsuche",
          text: "Finde schnell und einfach den passenden Arzt für deine Bedürfnisse",
        },
        {
          title: "Kommunikation",
          text: "Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt",
        },
        {
          title: "Organisation",
          text: "Behalte alle wichtigen Informationen und Dokumente im Überblick",
        },
        {
          title: "Vor- und Nachsorge",
          text: "Bleibe mit dem Arzt für alle notwendigen Schritte vor und nach deinem Eingriff in Kontakt",
        },
        {
          title: "Zertifizierte Ärzte",
          text: "Lass dich nur von zertifizierten Ärzten operieren",
        },
        {
          title: "Datenschutz",
          text: "Vergewissere dich, dass deine personenbezogenen Daten nur zwischen dir und deinem Arzt bleiben",
        },
        {
          title: "Feedback",
          text: "Profitiere von den Erfahrungen Anderer",
        },
        {
          title: "Finanzierung",
          text: "Informiere dich über mögliche Finanzierungsmöglichkeiten, um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ALLE OPERATIONEN, DIE SIE SUCHEN",
        doctorNumber: "Siehe +200 Ärzte",
        doctorText: "",
        viewDetails: "Siehe Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Optionen`,
        left: {
          desc: `Wähle das zutreffende Symbol für deine Wunschoperation`,
        },
        right: {
          desc: `Gehe über das Schaubild und klicke auf den gewünschten Körperbereich`,
        },
      },
      faq: {
        mainTitle: `Häufig gestellte Fragen`,
        shortTitle: "FAQ",
        data: [
          {
            title: `Muss ich registriert sein um YUMA CARE nutzen zu können?`,
            desc: `Ja, um unsere Plattform mit allen Vorteilen nutzen zu können, ist eine Registrierung erforderlich.`,
          },
          {
            title: `Wie werden meine Daten an den Arzt übertragen?`,
            desc: `Alle deine Daten (Angaben, Chat, Dokumente, Bilder) werden verschlüsselt an den Arzt übermittelt, sodass Dritte keinen Zugriff darauf erhalten.`,
          },
          {
            title: `Wie ist die Betreuung vor Ort?`,
            desc: `Der von dir gewählte Arzt und sein Team stellen sicher, dass du dich vor Ort um nichts kümmern musst. Ab deiner Anreise bis zur Abreise wirst du bei allen Schritten begleitet und unterstützt. Bei größeren Operationen oder längeren Aufenthalten raten wir dennoch zu einer Begleitperson.`,
          },
          {
            title: `Gibt es bestimmte Kriterien, die ein Arzt erfüllen muss, um auf YUMA CARE zu erscheinen?`,
            desc: `Ja, nur Ärzte mit einer bestimmten Lizenz werden auf YUMA CARE angezeigt. Diese Zulassung stellt sicher, dass der Arzt Patienten aus dem Ausland behandeln darf (länderspezifisch).`,
          },
          {
            title: `Wer kann ein Feedback zum Arzt geben?`,
            desc: `Nur Patienten, die über YUMA CARE registriert sind und beim Arzt eine Behandlung erhalten haben, bekommen den Feedback-Bogen freigeschaltet.`,
          },
          {
            title: `Sind Voruntersuchungen erforderlich?`,
            desc: `Dies ist abhängig von der geplanten Behandlung. Hierzu wird dir der Arzt im persönlichen Gespräch genaue Infos geben, ob und welche Voruntersuchungen erforderlich sind.`,
          },
          {
            title: `Kann ich mit mehreren Ärzten zum gleichen Anliegen in Kontakt treten? `,
            desc: `Nutze die Gelegenheit auf YUMA CARE, um dein Anliegen bei mehreren Ärzten vorzubringen, damit du entscheiden kannst, bei welchem Arzt du dich am besten aufgehoben fühlst.`,
          },
        ],
      },
      search: {
        date: "Datum",
      },
      contact: {
        title: "Kontakt",
        adress: "Adresse",
        phone: "Telefon",
        mail: "E-Mail Adresse",
        social: {
          facebook: "",
          twitter: "",
          instagram: "https://www.instagram.com/yumacare_germany/",
        },
        dataOwner: "Antragsformular für Dateninhaber",
      },
      stories: {
        title: "Echte Erfahrungen",
        subTitle: "",
        cards: [
          {
            name: "Ilaria (23)",
            job: "Bürokauffrau",
            title: "Ich habe meine Lebensqualität gesteigert",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko (42)",
            job: "Fachverkäufer",
            title: "Ich sehe so aus, wie ich aussehen möchte",
            text: `Nach meiner Haartransplantation bin ich überglücklich mit dem Ergebnis und freue mich über meine neuen Haare!`,
            image: Branko,
          },
          {
            name: "Sulo (33)",
            job: "Vertriebsmanagement",
            title: "Wie neu geboren",
            text: `Nachdem ich meine Zähne gemacht habe, fühle ich mich wie ein neuer Mensch! Mein Auftreten hat sich verändert und ich lächle jetzt viel mehr. Ich bin so glücklich mit dem Ergebnis und kann es kaum erwarten, meine neuen Zähne der Welt zu zeigen.`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE GRÜNDER",
        yusuf: {
          title: "Für mehr",
          title2: "Selbstbewusstsein",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches Empfinden zu verbessern. Wir stehen für Professionalität, Sicherheit und individuelle Beratung, um jedem Kunden ein maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen. Mit unserem engagierten Team und einem breiten Spektrum an Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden leisten zu können.`,
        },
        maria: {
          title: "Entfalte deine",
          title2: "individuelle Schönheit",
          text: `Mir ist es ein Anliegen, Menschen dabei zu unterstützen, sich in ihrer Haut wohl zu fühlen. Schönheitsoperationen können nicht nur äußerliche Veränderungen bewirken, sondern auch tiefgreifende positive Auswirkungen auf das Selbstwertgefühl und die sozialen Interaktionen haben. Unsere Plattform ist darauf ausgerichtet, ein unterstützendes und einfühlsames Umfeld zu schaffen, in dem jeder Mensch seine individuelle Schönheit entdecken und entfalten kann. Wir glauben fest daran, dass wahre Schönheit von innen kommt und möchten Menschen dabei helfen, sich in ihrer Haut rundum wohl zu fühlen.`,
        },
      },
      blog: {
        title: "Blog-Beiträge",
        subtitle: "YUMACARE",
        readMore: "Demnächst verfügbar",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Passwort vergessen",
      changePassword: "Passwort ändern",
      checkEmail: `Prüfen Sie Ihre E-Mail`,
      sendEmail: "E-Mail senden",
      anyProblem: `Sie haben die E-Mail nicht erhalten? Prüfen Sie Ihren Spam-Ordner oder`,
      anyProblem1: `versuchen Sie eine andere E-Mail-Adresse.`,
      emailText: `*Bitte geben Sie die E-Mail-Adresse an, die mit Ihrem Konto verknüpft ist, um Anweisungen zum Zurücksetzen Ihres Passworts zu erhalten.`,
      sendedEmail: `*Wir haben Ihnen eine Aufforderung zum Widerruf des Passworts an Ihre registrierte
              e-Mail geschickt.`,
      successChange: "Passwort erfolgreich geändert",
      errorChange: "Es gab einen Fehler beim Ändern des Passworts",
      errorMail: "Bei der Weiterleitung der Mail ist ein Fehler aufgetreten",
    },
    login: {
      title: "Login",
    },
    register: {
      title: "Registrierung",
      doctorRegister: "Doktor Registrierung",
      doctorInfo: "Doctor Informationen",
      hospitalRegister: "Krankenhausregister",
      hospitalInfo: "Krankenhausinformationen",
      backButton: "Vorheriger Schritt",
      birthday: "Geburtsdatum",
      step1: {
        caption: "Anrede",
        title: "Wie dürfen",
        title2: "wir Sie ansprechen?",
      },
      step2: {
        caption: "Operation",
        title: "Welche Operationen",
        title2: "ziehen Sie",
        title3: "in Betracht?",
      },
      step3: {
        caption: "Kontakt",
        title: "Wie können wir",
        title2: "Sie erreichen?",
        title3: "",
      },
      step4: {
        caption: "",
        title: "Möchten Sie den Arzt",
        title2: "den Sie kontaktieren",
        title3: "selbst auswählen?",
      },
      step5: {
        caption: "Profil",
        title: "Ihr Profil",
        title2: "ausfüllen",
      },
      selected: "ausgewählt",
      viewDetails: "Siehe Details",
      searchOperation: "Anrufvorgang",
      continue: "weiter",
      skip: "überspringen",
      doctorregistertitle: "Wenn Sie ein Arzt sind, fahren Sie hier fort ",
      doctorregisterbutton: "Registrierung",
    },
    form: {
      uyelikSozlesmesi: "Mitgliedschaftsvereinbarung",
      chatOncesiAydinlatma: "Erläuterungstext vor dem Chat",
      chatOncesiRiza: "Zustimmung vor dem Chat",
      uyelikRiza: "Zustimmung zur Mitgliedschaft",
      uyelikAydinlatma: "Aufklärungstext zur Mitgliedschaft",
      cerez: "Cookie Richtlinien",
      isOrtakligi: "Vereinbarung zur Geschäftspartnerschaft",
      acceptAll: "Alles akzeptieren",
      email: "E-mail",
      emailPlaceholder: "Deine E-Mail Adresse",
      password: "Passwort",
      passwordPlaceholder: "Dein Passwort",
      remember: "Angemeldet bleiben",
      forgotPassword: "Passwort vergessen?",
      username: "Benutzername",
      name: "Vorname",
      hospitalName: "Hastane İsmi",
      surname: "Nachname",
      phone: "Telefonnummer",
      phonePlaceholder: "Deine Telefonnummer",
      birthday: "Geburtsdatum",
      changePassword: "Passwort ändern",
      emailNotification: "E-Mail Benachrichtigungen",
      phoneNotification: "Handy Benachrichtigungen",
      doYouHaveAccount: "Du hast bereits ein Account? Melde dich jetzt an! ",
      subject: "Betreff",
      message: "Nachricht",
      send: "Senden",
      namePlaceholder: "Vorname",
      surnamePlaceholder: "Nachname",
      phonePlaceholder: "Telefonnummer",
      gender: "Geschlecht",
      man: "Männlich",
      woman: "Weiblich",
      divers: "Divers",
      date: "Datum",
      region: "Land",
      regionPlaceholder: "Wähle ein Land aus",
      province: "Stadt",
      provincePlaceholder: "Wähle deine Stadt aus",
      district: "Bundesland",
      districtPlaceholder: "Gebe dein Bundesland an",
      address: "Adresse",
      addressPlaceholder: "Gebe deine Adresse an",
      zipcode: "Postleitzahl",
      zipcodePlaceholder: "Gebe deine Postleitzahl an",
      passwordAgain: "Passwort wiederholen",
      title: "Titel",
      titlePlaceholder: "Wähle deinen Titel",
      workingPlace: "Arbeitsplatz",
      workingPlacePlaceholder: "Gebe deinen Arbeitsplatz an",

      profileImage: "Profilbild",
      cv: "Über dich",
      cvPlaceholer: "Stelle dich vor ",
      reward: "Auszeichnungen und/oder Zertifikate",
      captionPlaceholder: "Auszeichnungs- und/oder Zertifikatstitel",
      detailPlaceholder: "Details",

      addReward: "Auszeichnungen und/oder Zertifikate hinzufügen",
      document: "Dokument",
      documentTitle: "Diplom",
      documentFile: "Datei",
      addDocument: "Dokument hinzufügen",
      documentTitle: "Genehmigungszertifikat für Gesundheitstourismus",
      documentFile: "Datei",
      addDocument: "Datei hinzufügen",
      categoryOperation: "Kategorie & Operation",
      selectCategory: "Wähle eine Kategorie",
      newOperation: " Erstelle eine Operation",
      selection: "Optionen",
      operationDetail: "Operationsbeschreibung ",
      selectOperation: "Wähle eine Operation",
      addOperation: "Operation hinzufügen",
      reference: "Referenz",
      referencePlaceholder: "Referenz hinzufügen",
      referenceTitle: "Referenztitel",
      referenceCaption: "Referenzbeschreibung",
      addReference: "Referenz hinzufügen",
      operation: "Operation / Vorher Nachher Bilder",
      operationTitle: "Bildtitel ",
      operationImage: "Vorher Nachher Bild",
      addOperationImage: "Vorher Nachher Bild hinzufügen",
      professionTitle: "Fachgebiet ",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Doktor",
        },
        {
          name: "Fa.",
          value: "Facharzt",
        },
        {
          name: "Prof. Dr.",
          value: "Professor Doktor",
        },
      ],
      professionSelect: [
        {
          name: "Gesichtschirurgie",
        },
        {
          name: "HNO",
        },
        {
          name: "Ästhetische und plastische Chirurgie",
        },
        {
          name: "Plastische, Rekonstruktive und Ästhetische Chirurgie",
        },
        {
          name: "Adipositas (Fettleibigkeit)",
        },
        {
          name: "Augenchirurgie",
        },
        {
          name: "Mund-, Zahn- und Kieferchirurgie",
        },
        {
          name: "Medizinische Ästhetik",
        },
        {
          name: "Gynäkologie",
        },
      ],
      healthTourismFile: "Genehmigungszertifikat für Gesundheitstourismus",
      rewardFile: "Zertifikatsdatei",
    },
    footer: {
      terms: "Nutzungsbedingungen",
      privacy: "Datenschutzrichtlinien",
    },
    dashboard: {
      message: "Nachrichten",
      welcome: "Willkommen",
      more: "Mehr",
      medicalDetails: "Weitere medizinische Informationen",
      viewDetails: "Details",
      giveFeedback: "Bewerte uns",
      whatAbout: "Wie findest du YUMA CARE?",
      thought: "Hast du Ideen oder Vorschläge für uns?",
      signOut: "Abmelden",
    },
    message: {
      new: "Neue Nachricht",
      message: "Nachricht",
      image: "Bild",
      file: "Datei",
      video: "Video",
      meetLink: "Meet-Link",
      doctorInvite: "Arzt bestätigen",
      meetText:
        "Hey, ich habe dir eine Anfrage für ein Treffen geschickt, bitte klicke, um beizutreten.",
      sendedFile: "Gesendete Datei",
      doctorInviteText: "Sende eine Bestätigung an den Arzt.",
      doctorInviteMessage:
        "Hey, ich habe dir eine Operationsanfrage geschickt.",
    },
    operations: {
      title: "Operationen",

      addOperation: "Operation hinzufügen",
      viewDetails: "Details anzeigen",
      operationDetail: "Operation Detail",
      more: "Mehr",
    },
    calendar: {
      title: "Kalender",
      flightTime: "Flüge",
      accomodation: "Unterkunft",
      operations: "Operationen",

      myReminder: "Meine Erinnerung",
      newOperation: "Neuer Vorgang",
      newFlight: "Neuer Flug",
      newHotel: "Neue Unterkunft",
      day: "Tag",
      week: "Woche",
      month: "Mond",
      popup: {
        title: "Titel",
        note: "Notiz",
        save: "Speichern",
        operationDate: "Operationsdatum",
        operationTime: "Uhrzeit der Operation",
        doctor: "Arzt",
        user: "Arzt",
        selectOperation: "Operation auswählen",
        selectDoctor: "Arzt auswählen",
        selectUser: "Benutzer auswählen",
        addFile: "Datei Hinzufügen",
        uploadFile: "Datei auswählen",
        departureDate: "Ankunftsdatum",
        departureTime: "Ankunftszeit",
        returnDate: "Abflugsdatum",
        returnTime: "Rückkehrzeit",
        region: "Region",
        hotelName: "Hotel",
        checkIn: "Eincheckdatum",
        checkInTime: "Anmeldezeitpunkt",
        checkOut: "Freigabedatum",
        checkOutTime: "Abreisezeit",
      },
    },
    sideBar: {
      dashboard: "Dashboard",
      profile: "Profil",
      message: "Nachrichten",
      operations: "Operationen",
      calendar: "Kalender",
      search: "Suche",
      credit: "Finanzierung",
      signOut: "Abmelden",
    },
    feedBack: {
      title: "Feedback",
      subTitle: "",
      text: "Möchtest du uns etwas mitteilen?",
      success: "Erfolgreich übermittelt.",
      send: "Senden",
    },
    search: {
      title: "Doktor, Operation",
      selectBody: "Wähle einen Körperbereich",
      selectOperation: "Wähle eine Operation",
      headArea: "Kopf",
      upperArea: "Oberkörper",
      bottomArea: "Unterkörper",
      handArea: "Arme",
      legArea: "Beine",
      showDoctors: "Zeige mir Ärzte an",
      price: "Kosten",
      rating: "Bewertungen",
      filter: "Filter",
      bodyArea: "Körperbereich",
      gender: "Geschlecht",
      country: "Land",
      city: "Stadt",

      surgeryDate: "Operationsdatum",
      welcome: "Willkommen",
    },
    doctorDetail: {
      reward: "Auszeichnungen",
      references: "Referenzen",
      sendMessage: "Nachricht senden",

      moreOperation: "Mehr Operationen",
    },
    profile: {
      changePassword: "Passwort ändern“",
      emailNotification: "E-Mail-Benachrichtigung",
      phoneNotification: "Telefon-Benachrichtigung",
      addPhoto: "Foto hinzufügen",
      doctorProfile: "Detaillierte Informationen",
      userProfile: "User Information",
    },
    errors: {
      mandatoryfield: "Pflichtfeld",
      nameshort: "Name zu kurz",
      topicshort: "Betreff ist zu kurz",
      messageshort: "Nachricht ist zu kurz",
      surnameshort: "Nachname ist zu kurz",
      correctmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      correctphone: "Telefonnummer ist nicht im richtigen Format",
      correctpassword: "Passwort muss mindestens 8 Zeichen enthalten",
      repeatpassword: "Passwörter müssen übereinstimmen",
      login: "Die Anmeldeinformationen sind falsch",
      uyelikSozlesmesiError:
        "Bitte bestätigen Sie die Mitgliedschaftsvereinbarung",
      chatOncesiRizaError:
        "Bitte stimmen Sie dem Einwilligungstext vor dem Chat zu",
      chatOncesiAydinlatmaError:
        "Bitte stimmen Sie dem Erläuterungstext vor dem Chat zu",
      uyelikAydinlatmaError:
        "Bitte stimmen Sie dem Erläuterungstext zur Mitgliedschaft zu",
      uyelikRizaError: "Bitte stimmen Sie der Mitgliedschaftsvereinbarung zu",
      cerezError: "Bitte stimmen Sie den Cookie-Richtlinien zu",
      isOrtakligiError:
        "Bitte stimmen Sie der Geschäftspartnerschaftsvereinbarung zu",
    },
  },
  en: {
    landing: {
      menu: {
        contact: "Contact",
        terms: "Terms of Service",
        privacy: "Privacy Policy",
      },
      head: {
        title: "Embrace Aesthetics",
        text: `YUMA CARE is your platform when it comes to beauty and health. Register now to enjoy all the benefits for free.`,
        title2: "Elevate Life!",
        seeDoctors: "SEE +200 DOCTORS",
        contact: "Contact us",
      },
      infoPartTitle: "That`s what makes us unique",
      infoPartSubtitle: "",
      infoPart: [
        {
          title: "Doctor search",
          text: "Find the right doctor for your needs quickly and easily",
        },
        {
          title: "Communication",
          text: "Contact the doctor through video consultation or chat in your native language",
        },
        {
          title: "Organization",
          text: "Keep track of all important information and documents",
        },
        {
          title: "Pre and post care",
          text: "Stay in touch with the doctor for all necessary steps before and after your procedure",
        },
        {
          title: "Certified doctors",
          text: "Let your surgery be done by only certified doctors",
        },
        {
          title: "Data protection section",
          text: "Be sure that your personal data will stay between you and your doctor",
        },
        {
          title: "Feedback",
          text: "Benefit from other persons experiences",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Our doctors",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ALL OPERATIONS YOU ARE LOOKING FOR",
        doctorNumber: "See + 200 Doctors",
        doctorText: "",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Options`,
        left: {
          desc: `Choose the right symbol to select the desired body area`,
        },
        right: {
          desc: `Hower over the diagram to select the desired body area`,
        },
      },
      faq: {
        mainTitle: "Frequently asked questions",
        shortTitle: "FAQ",
        data: [
          {
            title: `Do I need to be registered to be able to use YUMA CARE?`,
            desc: `Yes, registration on YUMA CARE is required in order to be able to use all the benefits we are offering.`,
          },
          {
            title: `How will my data be transmitted to the doctor?`,
            desc: `All your data (information, chat, documents, pictures) are transmitted encrypted to the doctor, so that third parties do not have access to them.`,
          },
          {
            title: `What is the on-site support like? `,
            desc: `The doctor and his team you have chosen ensure that you don't have to worry about anything on-site. From your arrival to your departure, you will be accompanied and supported in every step. For major surgeries or longer stays, we still recommend having a companion.`,
          },
          {
            title: `Are there certain criteria that a doctor must meet to appear on YUMA CARE?`,
            desc: `Yes, only doctors with a specific license are displayed on YUMA CARE. This accreditation ensures that the doctor is authorized to conduct the treatments on patients from abroad (country specific).`,
          },
          {
            title: `Who can give feedback on the doctor?`,
            desc: `Only patients registered on YUMA CARE and who received treatment from the doctor can subsequently access the feedback form.`,
          },
          {
            title: `Are preliminary examinations required?`,
            desc: `This depends on the planned treatment. The doctor will provide you with detailed information during the personal conversation about whether and which preliminary examinations are necessary.`,
          },
          {
            title: `Can I contact multiple doctors about the same concern?`,
            desc: `Feel free to use the option on YUMA CARE to place your inquiry with multiple doctors to finally decide which doctor makes you feel most comfortable.`,
          },
        ],
      },
      search: {
        date: "Date",
      },
      contact: {
        title: "Contact",
        adress: "Adress",
        phone: "Phone",
        mail: "Email Adress",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Data Subject Application Form",
      },
      stories: {
        title: "Real experiences",
        subTitle: "",
        cards: [
          {
            name: "Ilaria (23)",
            job: "Office clerk",
            title: "I have improved my quality of life",
            text: `My stomach reduction has not only reduced my weight, but also significantly improved my quality of life and health. I feel great and look forward to a healthier and happier life!`,
            image: Ilaria,
          },
          {
            name: "Branko (42)",
            job: "Specialist salesperson",
            title: "I look the way I want to look",
            text: `After my hair transplant, I am over the moon with the result and am delighted with my new hair!`,
            image: Branko,
          },
          {
            name: "Suel (33)",
            job: "Sales management",
            title: "As if reborn",
            text: `After having my teeth done, I feel like a new person! My appearance has changed and I smile so much more now. I'm so happy with the result and can't wait to show my new teeth to the world.`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE FOUNDERS",
        yusuf: {
          title: "More",
          title2: "self confidence",
          text: `It is a matter close to my heart to help our users boost their self-confidence and improve their aesthetic perception. We stand for professionalism, safety and personalized advice to provide each client with a customized and satisfying result. With our dedicated team and a wide range of treatment options, we are proud to play our part in enhancing the well-being and self-esteem of our clients.`,
        },
        maria: {
          title: "Discover and unfold",
          title2: "your individual beauty",
          text: `It is important to me to help people feel comfortable in their own skin. Cosmetic surgery can not only bring about external changes, but can also have profound positive effects on self-esteem and social interactions. Our platform is designed to create a supportive and empathetic environment where everyone can discover and develop their individual beauty. We firmly believe that true beauty comes from within and want to help people feel completely comfortable in their own skin.`,
        },
      },
      blog: {
        title: "Blog posts",
        subtitle: "YUMACARE",
        readMore: "Soon",
        blogs: [
          {
            title:
              "Beauty and self-confidence: Why more and more people are deciding to have cosmetic surgery in Turkey",
            text: "In recent years, Turkey has become a popular destination for cosmetic surgery. More and more people from all over the world are traveling to this fascinating country to fulfill their aesthetic desires...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL surgery: A comprehensive guide to the Brazilian Butt Lift",
            text: "In recent years, the demand for aesthetic procedures has increased significantly, and one of the most popular operations is the Brazilian Butt Lift (BBL). This procedure is particularly popular with women...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Cosmetic surgery for men: a growing trend",
            text: "In recent years, the ideal of beauty has changed dramatically, not only for women but also for men. More and more men are opting for cosmetic surgery to improve their appearance...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Login",
    },
    register: {
      title: "Registration",
      doctorRegister: "Doctor regsitration",
      doctorInfo: "Doctor information",
      hospitalRegister: "Hospital Registry",
      hospitalInfo: "Hospital Information",
      backButton: "Previous Step",
      step1: {
        caption: "Form of Adress",
        title: "How may we",
        title2: "adress you?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "perations are you",
        title3: "considering?",
      },
      step3: {
        caption: "Contact",
        title: "How may we",
        title2: "contact",
        title3: "you?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "your profile",
      },
      selected: "Selected",
      viewDetails: "View Details",
      searchOperation: "Search operation",
      continue: "Continue",
      skip: "Skip",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Membership agreement",
      chatOncesiAydinlatma: "Pre-chat clarification text",
      chatOncesiRiza: "Explicit consent text before chat",
      uyelikRiza: "Membership explicit consent text",
      uyelikAydinlatma: "Membership clarification text",
      cerez: "Cookie Policy",
      isOrtakligi: "Business partnership agreement",
      acceptAll: "Accept all",
      email: "E-mail",
      emailPlaceholder: "Your e-mail address",
      password: "Password",
      passwordPlaceholder: "Your password",
      remember: "Stay logged in",
      forgotPassword: "Forgot password?",
      username: "Username",
      name: "First name",
      hospitalName: "Hospital Name",
      surname: "Last name",
      phone: "Phone",
      phonePlaceholder: "Your phone number",
      birthday: "Date of birth",
      changePassword: "Change password",
      emailNotification: "E-mail notifications",
      phoneNotification: "Mobile notifications",
      doYouHaveAccount: "Already have an account? Sign in now!",
      subject: "Subject",
      message: "Message",
      send: "Send",
      namePlaceholder: "Your first name",
      surnamePlaceholder: "Your last name",
      phonePlaceholder: "Your phone number",
      gender: "Gender",
      man: "Male",
      woman: "Female",
      divers: "Diverse",
      date: "Date",
      region: "Country",
      regionPlaceholder: "Choose country",
      province: "State",
      provincePlaceholder: "Your state",
      district: "City",
      districtPlaceholder: "Your city",
      address: "Street",
      addressPlaceholder: "Your street",
      zipcode: "Zip code",
      zipcodePlaceholder: "Your zip code",
      passwordAgain: "Password",
      title: "Title",
      titlePlaceholder: "Choose your title",
      workingPlace: "Workplace",
      workingPlacePlaceholder: "Your workplace",
      profileImage: "Profile picture",
      cv: "About me",
      cvPlaceholer: "Describe yourself",
      reward: "Rewards/certificates",
      captionPlaceholder: "Caption",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      document: "Documents",
      documentTitle: "Diploma",
      documentFile: "File",
      addDocument: "Add file",
      documentTitle: "Authorization Certificate for Health Tourism",
      documentFile: "File",
      addDocument: "Add file",
      categoryOperation: "Category & surgery",
      selectCategory: "Choose category",
      newOperation: "Create surgery",
      selection: "Options",
      operationDetail: "Operation details",
      selectOperation: "Choose operation",
      addOperation: "Add operation",
      reference: "Reference",
      referencePlaceholder: "Add Reference",
      referenceTitle: "Reference name",
      referenceCaption: "Reference position",
      addReference: "Add references",
      operation: "Operation before after pictures",
      operationTitle: "Picture title",
      operationImage: "Before after picture",
      addOperationImage: "Add picture",
      professionTitle: "Field of expertise",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Doctor",
        },
        {
          name: "M.D.",
          value: "Doctor of Medicine",
        },
        {
          name: "Ph.D.",
          value: "Doctor of Philosphy",
        },
      ],
      professionSelect: [
        {
          name: "Facial surgery",
        },
        {
          name: "Otorhinolaryngology",
        },
        {
          name: "Aesthetic and plastic surgery",
        },
        {
          name: "Plastic, reconstructive, and aesthetic surgery",
        },
        {
          name: "Obesity",
        },
        {
          name: "Eye surgery",
        },
        {
          name: "Oral, dental, and jaw surgery",
        },
        {
          name: "Medical aesthetics",
        },
        {
          name: "Gynecology",
        },
      ],
      healthTourismFile: "Authorization Certificate for Health Tourism",
      rewardFile: "Award Certificate File",
    },
    footer: {
      terms: "Terms of use",
      privacy: "Privacy policy",
    },
    dashboard: {
      message: "Messages",
      welcome: "Welcome",
      more: "More",
      medicalDetails: "More medical information",
      viewDetails: "Details",
      giveFeedback: "Give us feedback",
      whatAbout: "What do you think about YUMA Care?",
      thought: "Do you have any ideas or suggestions for us?",
      signOut: "Logout",
    },
    message: {
      new: "New message",
      message: "Message",
      image: "Image",
      file: "File",
      video: "Video",
      meetLink: "Meet Link",
      doctorInvite: "Confirm Doctor",
      meetText: "Hey, I sent you a meet request, please click to join.",
      sendedFile: "Sended File",
      doctorInviteText: "Send an operation invitation to Doctor.",
      doctorInviteMessage: "Hey, I sent you an operation request.",
    },
    operations: {
      title: "Operations",
      addOperation: " Add Operation",
      viewDetails: "Show Details",
      operationDetail: "Operation Details",
      more: "More",
    },
    calendar: {
      title: "Calender",
      flightTime: "Flights",
      accomodation: "Accommodation",
      operations: "Operations",
      myReminder: "My Reminder",
      newOperation: "New Operation",
      newFlight: "New Flight",
      newHotel: "New Accommodation",
      day: "Day",
      week: "Week",
      month: "Month",
      popup: {
        title: "Title",
        note: "Note",
        save: "Save",
        operationDate: "Operation Date",
        operationTime: "Operation Time",
        doctor: "Doctor",
        user: "User",
        selectOperation: "Select Operation",
        selectDoctor: "Select Doctor",
        selectUser: "Select User",
        addFile: "Add File",
        uploadFile: "Select File",
        departureDate: "Arrival Date",
        departureTime: "Arrival Time",
        returnDate: "Departure Date",
        returnTime: "Departure Time",
        region: "Region",
        hotelName: "Hotel",
        checkIn: "Check In",
        checkInTime: "Check In Time",
        checkOut: "Check Out",
        checkOutTime: "Check Out Time",
      },
    },
    sideBar: {
      dashboard: "Dashboard",
      profile: "Profile",
      message: "Message",
      operations: "Operations",
      calendar: "Calender",
      search: "Search",
      credit: "Financing",
      signOut: "Sign Out",
    },
    feedBack: {
      title: "Feedback",
      subTitle: "",
      text: "Do you have any feedback you would like to share?",
      success: "Successfully transmitted.",
      send: "Send",
    },
    search: {
      title: "Doctor, Operation",
      selectBody: "Choose a body area",
      selectOperation: "Choose an operation",
      headArea: "Head",
      upperArea: "Upper body",
      bottomArea: "Lower body",
      handArea: "Arms",
      legArea: "Legs",
      showDoctors: "Show me doctors",
      price: "Costs",
      rating: "Reviews",
      filter: "Filters",
      bodyArea: "Body area",
      gender: "Gender",
      country: "Country",
      city: "City",
      surgeryDate: "Operation Date",
      welcome: "Welcome",
    },
    doctorDetail: {
      reward: "Awards",
      references: "References",
      sendMessage: "Send message",
      moreOperation: "More Operation",
    },
    profile: {
      changePassword: "Change Password",
      emailNotification: "E-Mail Notification",
      phoneNotification: "Phone Notification",
      addPhoto: "Add Image",
      doctorProfile: "Detailed Information",
      userProfile: "User Information",
    },
    errors: {
      mandatoryfield: "Required field",
      nameshort: "Name is too short",
      topicshort: "Subject is too short",
      messageshort: "Message is too short",
      surnameshort: "Last name is too short",
      correctmail: "Please enter a valid email address",
      correctphone: "Phone number is not in the correct format",
      correctpassword: "Password must be at least 8 characters long",
      repeatpassword: "Passwords must match",
      login: "Login information is incorrect",
      uyelikSozlesmesiError: "Please confirm the membership agreement",
      chatOncesiRizaError: "Please confirm the consent text before chat",
      chatOncesiAydinlatmaError:
        "Please play the clarification text before the chat",
      uyelikAydinlatmaError: "Please confirm the membership clarification text",
      uyelikRizaError: "Please confirm membership consent",
      cerezError: "Please confirm the cookie policy",
      isOrtakligiError: "Please confirm the partnership agreement",
    },
  },
  es: {
    landing: {
      menu: {
        contact: "Contacto",
        terms: "Términos de servicio",
        privacy: "Política de privacidad",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE es tu plataforma cuando se trata de belleza y salud. Regístrate ahora para disfrutar de todos los beneficios.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "Eso es lo que nos hace únicos.",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Búsqueda de médicos ",
          text: "Encuentra rápidamente el médico adecuado para tus necesidades",
        },
        {
          title: "Comunicación",
          text: "Contacta al médico a través de consulta por video o chat en tu idioma nativo",
        },
        {
          title: "Organización ",
          text: "Mantén un registro de toda la información importante y documentos",
        },
        {
          title: "Cuidado pre y postoperatorio",
          text: "Mantén contacto con el médico para todos los pasos necesarios antes y después de tu procedimiento",
        },
        {
          title: "Medicos certificados",
          text: "Solo permite que te operen médicos certificados",
        },
        {
          title: "Privacidad de datos",
          text: "Asegúrate de que tus datos personales queden solo entre tú y tu médico",
        },
        {
          title: "Comentarios",
          text: "Benefíciate de las experiencias de otros",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Opciones`,
        left: {
          desc: `Selecciona el símbolo apropiado para tu operación deseada`,
        },
        right: {
          desc: `Pasa sobre el gráfico y haz clic en la zona del cuerpo deseada`,
        },
      },
      faq: {
        mainTitle: `Preguntas frecuentes`,
        shortTitle: "FAQ",
        data: [
          {
            title: `¿Necesito registrarme para utilizar YUMA CARE?`,
            desc: `Sí, es necesario registrarse para poder aprovechar todas las ventajas de nuestra plataforma.`,
          },
          {
            title: `¿Cómo se transmiten mis datos al médico? `,
            desc: `Todos tus datos (información, chat, documentos, imágenes) se transmiten al médico de forma encriptada, de manera que terceros no tengan acceso a ellos.`,
          },
          {
            title: `¿Cómo es la atención en el lugar? `,
            desc: `El médico y su equipo elegidos de ti te aseguran que no tengas que preocuparte por nada en el lugar. Desde tu llegada hasta tu partida, serás acompañado y apoyado en cada paso.Sin embargo recomendamos un acompañamiento personal durante cirugías o estancias prolongadas. `,
          },
          {
            title: `¿Existen criterios específicos que un médico debe cumplir para aparecer en YUMA CARE? `,
            desc: `Sí, solo los médicos con una licencia específica se mostrarán en YUMA CARE. Esta acreditación garantiza que el médico puede tratar a pacientes extranjeros (dependiendo del país).`,
          },
          {
            title: `¿Quién puede dar feedback sobre el médico? `,
            desc: `Solo los pacientes que estén registrados en YUMA CARE y hayan recibido tratamiento médico podrán acceder al formulario de retroalimentación.`,
          },
          {
            title: `¿Son necesarios exámenes preliminares? `,
            desc: `Esto depende del tratamiento planificado. El médico te proporcionará información detallada durante la conversación personal sobre si son necesarios exámenes preliminares y cuáles.`,
          },
          {
            title: `¿Puedo ponerme en contacto con varios médicos para el mismo problema?`,
            desc: `Siéntete libre de utilizar la opción en YUMA CARE para presentar tu consulta a varios médicos y decidir finalmente con qué médico te sientes más cómodo.`,
          },
        ],
      },
      search: {
        date: "Fecha",
      },
      contact: {
        title: "Contacto",
        adress: "Dirección",
        phone: "Teléfono",
        mail: "Dirección de correo electrónico",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Inicio de sesión",
    },
    register: {
      title: "Registro",
      doctorRegister: "Registro del Doctor",
      doctorInfo: "Información del Doctor",
      hospitalRegister: "Registro hospitalario",
      hospitalInfo: "Información del hospital",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Cookie Richtlinien",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "Correo electrónico",
      emailPlaceholder: "Tu dirección de correo electrónico",
      password: "Contraseña",
      passwordPlaceholder: "Tu contraseña",
      remember: "Mantener sesión iniciada",
      forgotPassword: "¿Olvidaste tu contraseña?",
      username: "Nombre de usuario",
      name: "Nombre",
      hospitalName: "Hastane İsmi",
      surname: "Apellido",
      phone: "Teléfono",
      phonePlaceholder: "Tu número de teléfono",
      birthday: "Fecha de nacimiento",
      changePassword: "Cambiar contraseña",
      emailNotification: "Notificaciones por correo electrónico",
      phoneNotification: "Notificaciones del teléfono móvil",
      doYouHaveAccount: "¿Ya tienes una cuenta? ¡Inicia sesión ahora!",
      subject: "Asunto",
      message: "Mensaje",
      send: "Enviar",
      namePlaceholder: "Nombre",
      surnamePlaceholder: "Apwllido",
      phonePlaceholder: "Número de teléfono",
      gender: "Género",
      man: "Masculino",
      woman: "Femenino",
      divers: "Diverso",
      date: "Fecha",
      region: "País",
      regionPlaceholder: "Selecciona un país",
      province: "Estado",
      provincePlaceholder: "Selecciona un estado",
      district: "Ciudad",
      districtPlaceholder: "Indica tu ciudad",
      address: "Dirección",
      addressPlaceholder: "Indica tu dirección",
      zipcode: "Código postal",
      zipcodePlaceholder: "Indica tu código postal",
      passwordAgain: "Repetir contraseña",
      title: "Título",
      titlePlaceholder: "Selecciona tu título",
      workingPlace: "Lugar de trabajo",
      workingPlacePlaceholder: "Indica tu lugar de trabajo",
      profileImage: "Foto de perfil",
      cv: "Acerca de ti",
      cvPlaceholer: "Presentación",
      reward: "Premios y/o Certificado",
      captionPlaceholder: "Título del premio y/o certificado",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      detailPlace: "Agregar premios y/o certificados",
      document: "Documento",
      documentTitle: "Diploma",
      documentFile: "Archivo",
      addDocument: "Agregar documento",
      documentTitle: "Certificado de autorización para turismo de salud",
      documentFile: "Archivo",
      addDocument: "Agregar documento",
      categoryOperation: "Categoría y Operación",
      selectCategory: "Selecciona una categoría",
      newOperation: " Crea una operación",
      selection: "Opciones",
      operationDetail: "Descripción de la operación",
      selectOperation: "Selecciona una operación",
      addOperation: "Agregar operación",
      reference: "Referencia",
      referencePlaceholder: "Agregar referencia",
      referenceTitle: "Título de la referencia",
      referenceCaption: "Descripción de la referencia",
      addReference: "Agregar referencia",
      operation: "Operación / Imágenes Antes y Después",
      operationTitle: "Título de la imagen",
      operationImage: "Imagen Antes y Después",
      addOperationImage: "Agregar imagen antes y después",
      professionTitle: "Campo de Especialización",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Doctor",
        },
        {
          name: "M.E.",
          value: "Médico Especialista",
        },
        {
          name: "Prof. Dr.",
          value: "Profesor",
        },
      ],
      professionSelect: [
        {
          name: "Cirugía facial",
        },
        {
          name: "Otorrinolaringología",
        },
        {
          name: "Cirugía estética y plástica",
        },
        {
          name: "Cirugía plástica, reconstructiva y estética",
        },
        {
          name: "Obesidad",
        },
        {
          name: "Cirugía ocular",
        },
        {
          name: "Cirugía oral, dental y maxilofacial",
        },
        {
          name: "Estética médica",
        },
        {
          name: "Ginecología",
        },
      ],
      healthTourismFile: "Certificado de Autorización para Turismo de Salud",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Términos de uso",
      privacy: "Política de privacidad",
    },
    dashboard: {
      message: "Mensajes",
      welcome: "Bienvenido/a",
      more: "Más",
      medicalDetails: "Información médica",
      viewDetails: "Detalles",
      giveFeedback: "Evalúanos",
      whatAbout: "¿Qué opinas de YUMA Care?",
      thought: "¿Tienes ideas o sugerencias para nosotros?",
      signOut: "Cerrar sesión",
    },
    message: {
      new: "Nuevo mensaje",
      message: "Mensaje",
      image: "Visual",
      file: "Archivo",
      video: "Vídeo",
      meetLink: "Meet Link",
      doctorInvite: "Invitación de médico",
      meetText:
        "Hola, te he enviado una solicitud de reunión, haz clic para unirte.",
      sendedFile: "Archivo enviado",
      doctorInviteText: "Enviar una invitación de operación a Doctor.",
      doctorInviteMessage: "Oye, te he enviado una solicitud de operación.",
    },
    operations: {
      title: "Operaciones",
      addOperation: "Añadir operación",
      viewDetails: "Mostrar detalle",
      operationDetail: "Detalle de la operación",
      more: "Más",
    },
    calendar: {
      title: "Calendario",
      flightTime: "Vuelos",
      accomodation: "Alojamiento",
      operations: "Operaciones",
      myReminder: "Mi Recordatorio",
      newOperation: "Nueva operación",
      newFlight: "Nuevo Vuelo",
      newHotel: "Nuevo alojamiento",
      day: "Día",
      week: "Semana",
      month: "Luna",
      popup: {
        title: "Título",
        note: "Nota",
        save: "Guardar",
        operationDate: "Fecha de operación",
        operationTime: "Hora de la operación",
        doctor: "Doktor",
        user: "Usuario",
        selectOperation: "Seleccionar operación",
        selectDoctor: "Seleccionar Médico",
        selectUser: "Seleccionar Usuario",
        addFile: "Añadir Archivo",
        uploadFile: "Seleccionar Archivo",
        departureDate: "Hora de salida",
        departureTime: "Hora de salida",
        returnDate: "Hora de Regreso",
        returnTime: "Hora de vuelta",
        region: "Región",
        hotelName: "Nombre del hotel",
        checkIn: "Fecha de Entrada",
        checkInTime: "Hora de Entrada",
        checkOut: "Fecha de Salida",
        checkOutTime: "Hora de Salida",
      },
    },
    sideBar: {
      dashboard: "Dashboard",
      profile: "Perfil",
      message: "Mensaje",
      operations: "Operaciones",
      calendar: "Calendario",
      search: "Búsqueda",
      credit: "Crédito",
      signOut: "Cerrar sesión",
    },
    feedBack: {
      title: "Feedback",
      subTitle: "",
      text: "Tienes algún comentario que quieras compartir?",
      success: "Transmitido con éxito.",
      send: "Enviar",
    },
    search: {
      title: "Doctor, Operación",
      selectBody: "Selecciona una área del cuerpo",
      selectOperation: "Selecciona una operación",
      headArea: "Cabeza",
      upperArea: "Torso superior",
      bottomArea: "Torso inferior",
      handArea: "Brazos",
      legArea: "Espalda",
      showDoctors: "Mostrarme médicos",
      price: "Precios",
      rating: "Opiniones",
      filter: "Filtro",
      bodyArea: "Área del cuerpo",
      gender: "Género",
      country: "País",
      city: "Ciudad",
      surgeryDate: "Fecha Operación",
      welcome: "Bienvenida",
    },
    doctorDetail: {
      reward: "Premios",
      references: "Referencias",
      sendMessage: "Enviar mensaje",
      moreOperation: "Más operación",
    },
    profile: {
      changePassword: "Cambiar contraseña",
      emailNotification: "Notificación por correo electrónico",
      phoneNotification: "Notificación telefónica",
      addPhoto: "Añadir foto",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Campo obligatorio",
      nameshort: "El nombre es demasiado corto",
      topicshort: "El asunto es demasiado corto",
      messageshort: "El mensaje es demasiado corto",
      surnameshort: "El apellido es demasiado corto",
      correctmail:
        "Por favor, introduzca una dirección de correo electrónico válida",
      correctphone: "El número de teléfono no está en el formato correcto",
      correctpassword: "La contraseña debe tener al menos 8 caracteres",
      repeatpassword: "Las contraseñas deben coincidir",
      login: "La información de inicio de sesión es incorrecta",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
  italy: {
    landing: {
      menu: {
        contact: "Contatto",
        terms: "Termini di servizio",
        privacy: "Informativa sulla privacy",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE è la tua piattaforma quando si tratta di bellezza e salute. Registrati ora per godere di tutti i vantaggi.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "Ciò che ci rende unici",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Ricerca medici",
          text: "Trova facilmente il medico adatto alle tue esigenze",
        },
        {
          title: "Comunicazione",
          text: "Entra in contatto con il medico tramite consulenza video o chat nella tua lingua madre",
        },
        {
          title: "Organizzazione",
          text: "Tieni traccia di tutte le informazioni e documenti importanti",
        },
        {
          title: "Pre e post operatorio",
          text: "Rimani in contatto con il medico per tutte le fasi necessarie prima e dopo l`intervento",
        },
        {
          title: "Medici certificati",
          text: "Fatti operare solo da medici certificati",
        },
        {
          title: "Privacy",
          text: "Assicurati che i tuoi dati personali rimangano solo tra te e il tuo medico",
        },
        {
          title: "Feedback",
          text: "Approfitta delle esperienze degli altri",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Opzioni`,
        left: {
          desc: `Scegli il simbolo appropriato per l'intervento desiderato`,
        },
        right: {
          desc: `Passa il mouse sull'immagine e fai clic sull'area del corpo desiderata`,
        },
      },
      faq: {
        mainTitle: `Domande frequenti`,
        shortTitle: "FAQ",
        data: [
          {
            title: `Devo essere registrato per utilizzare YUMA CARE?`,
            desc: `Sì, è necessaria una registrazione per poter usufruire di tutte le vantaggi della nostra piattaforma.`,
          },
          {
            title: `Come vengono trasmessi i miei dati al medico?`,
            desc: `Tutti i tuoi dati (informazioni, chat, documenti, immagini) vengono trasmessi in modo cifrato al medico, in modo che terze parti non possano accedervi.`,
          },
          {
            title: `Come funziona l'assistenza in loco?`,
            desc: `Il medico e il suo team da te scelti si assicurano che tu non debba preoccuparti di nulla durante il tuo soggiorno. Dall'arrivo alla partenza, sarai accompagnato e supportato in ogni fase.Per interventi chirurgici di maggior entità o soggiorni prolungati, tuttavia, consigliamo di essere accompagnati da una persona di supporto.`,
          },
          {
            title: `Ci sono determinati criteri che un medico deve soddisfare per apparire su YUMA CARE?`,
            desc: `Sì, solo i medici con una specifica licenza verranno visualizzati su YUMA CARE. Questa autorizzazione garantisce che il medico possa trattare pazienti stranieri (a seconda del paese).`,
          },
          {
            title: `Chi può fornire un feedback sul medico?`,
            desc: `Solo i pazienti registrati su YUMA CARE che hanno ricevuto un trattamento medico avranno accesso al modulo di feedback.`,
          },
          {
            title: `Sono necessari esami preliminari?`,
            desc: `Ciò dipende dal trattamento pianificato. Il medico ti fornirà informazioni precise durante la consulenza personale sulla necessità e la tipologia di esami preliminari.`,
          },
          {
            title: `Posso entrare in contatto con diversi medici per la stessa questione?`,
            desc: `Approfitta dell'opportunità su YUMA CARE di presentare la tua richiesta a diversi medici per decidere definitivamente con quale ti senti più a tuo agio.`,
          },
        ],
      },
      search: {
        date: "Data",
      },
      contact: {
        title: "Contatto",
        adress: "Indirizzo",
        phone: "Telefono",
        mail: "Indirizzo email",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Accesso",
    },
    register: {
      title: "Registrazione",
      doctorRegister: "Informazioni sul medico",
      doctorInfo: "Informazioni sul medico",
      hospitalRegister: "Registro dell'ospedale",
      hospitalInfo: "Informazioni sull'ospedale",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "E-mail",
      emailPlaceholder: "Il tuo indirizzo e-mail",
      password: "Password",
      passwordPlaceholder: "La tua password",
      remember: "Rimani connesso",
      forgotPassword: "Password dimenticata?",
      username: "Nome utente",
      name: "Nome",
      hospitalName: "Hastane İsmi",
      surname: "Cognome",
      phone: "Telefono",
      phonePlaceholder: "Il tuo numero di telefono",
      birthday: "Data di nascita",
      changePassword: "Modifica password",
      emailNotification: "Notifiche tramite e-mail",
      phoneNotification: "Notifiche sul cellulare",
      doYouHaveAccount: "Hai già un account? Accedi adesso!",
      subject: "Oggetto",
      message: "Messaggio",
      send: "Invia",
      namePlaceholder: "Nome",
      surnamePlaceholder: "Cognome",
      phonePlaceholder: "Numero di telefono",
      gender: "Sesso",
      man: "Maschile",
      woman: "Femminile",
      divers: "Diverso",
      date: "Data di nascita",
      region: "Paese",
      regionPlaceholder: "Scegli un paese",
      province: "Stato federale",
      provincePlaceholder: "Scegli uno stato federale",
      district: "Città",
      districtPlaceholder: "Inserisci la tua città",
      address: "Indirizzo",
      addressPlaceholder: "Inserisci il tuo indirizzo",
      zipcode: "CAP (Codice di Avviamento Postale)",
      zipcodePlaceholder: "Inserisci il tuo CAP",
      passwordAgain: "Ripeti la password",
      title: "Titolo",
      titlePlaceholder: "Scegli il tuo titolo",
      workingPlace: "Luogo di lavoro",
      workingPlacePlaceholder: "Il tuo luogo di lavoro",
      profileImage: "Foto del profilo",
      cv: "Su di te",
      cvPlaceholer: "Presentati",
      reward: "Premi e/o Certificati",
      captionPlaceholder: "Titolo del premio e/o certificato",
      addReward: "Aggiungi premi e/o certificati",
      detailPlaceholder: "Details",

      document: "Documento",
      documentTitle: "Diploma",
      documentFile: "File",
      addDocument: "Aggiungi documento",
      documentTitle: "Certificato di autorizzazione per il turismo sanitario",
      documentFile: "File",
      addDocument: "Aggiungi documento",
      categoryOperation: "Categoria e Operazione",
      selectCategory: "Scegli una categoria",
      newOperation: "Crea un operazione",
      selection: "Opzioni",
      operationDetail: "Descrizione dell`operazione",
      selectOperation: "Scegli un`operazione",
      addOperation: "Aggiungi operazione",
      reference: "Riferimento",
      referencePlaceholder: "Aggiungi riferimento",
      referenceTitle: "Titolo del riferimento",
      referenceCaption: "Descrizione del riferimento",
      addReference: "Aggiungi riferimento",
      operation: "Operazione / Immagini Prima e Dopo",
      operationTitle: "Titolo dell`immagine",
      operationImage: "Immagine Prima e Dopo",
      addOperationImage: "Aggiungi immagine prima e dopo",
      professionTitle: "Campo di Specializzazione",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Dottore",
        },
        {
          name: "Med. Spec.",
          value: "Medico Specialista",
        },
        {
          name: "Prof. Dr.",
          value: "Professore",
        },
      ],
      professionSelect: [
        {
          name: "Chirurgia facciale",
        },
        {
          name: "Otorinolaringoiatria",
        },
        {
          name: "Chirurgia estetica e plastica",
        },
        {
          name: "Chirurgia plastica, ricostruttiva ed estetica",
        },
        {
          name: "Obesità",
        },
        {
          name: "Chirurgia oculare",
        },
        {
          name: "Chirurgia orale, dentale e maxillo-facciale",
        },
        {
          name: "Estetica medica",
        },
        {
          name: "Ginecologia",
        },
      ],
      healthTourismFile:
        "Certificato di Autorizzazione per il Turismo Sanitario",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Termini di utilizzo",
      privacy: "Informativa sulla privacy",
    },
    dashboard: {
      message: "Messaggi",
      welcome: "Benvenuto",
      more: "Più",
      medicalDetails: "Ulteriori informazioni mediche",
      viewDetails: "Dettagli",
      giveFeedback: "Valutaci",
      whatAbout: "Come trovi YUMA Care?",
      thought: "Hai idee o suggerimenti per noi?",
      signOut: "Disconnetti",
    },
    message: {
      new: "Nuovo messaggio",

      message: "Messaggio",
      image: "Visuale",
      file: "File",
      video: "Video",
      meetLink: "Incontra link",
      doctorInvite: "Invito del medico",
      meetText:
        "Ehi, ti ho inviato una richiesta di incontro, per favore clicca per unirti.",
      sendedFile: "File inviato",
      doctorInviteText: "Invia un invito a un'operazione a Doctor.",
      doctorInviteMessage: "Ehi, ti ho inviato una richiesta di operazione.",
    },
    operations: {
      title: "Procedure",
      addOperation: "Aggiungi operazione",
      viewDetails: "Mostra dettagli",
      operationDetail: "Dettaglio operazione",
      more: "Altro",
    },
    calendar: {
      title: "Calendario",
      flightTime: "Voli",
      accomodation: "Alloggio",
      operations: "Procedure",
      myReminder: "Il mio promemoria",
      newOperation: "Nuova operazione",
      newFlight: "Nuovo volo",
      newHotel: "Nuovo alloggio",
      day: "Giorno",
      week: "Settimana",
      month: "Luna",
      popup: {
        title: "Titolo",
        note: "Non",
        save: "Salva",
        operationDate: "Data operazione",
        operationTime: "Ora dell'operazione",
        doctor: "Medico",
        user: "Utente",
        selectOperation: "Seleziona operazione",
        selectDoctor: "Seleziona medico",
        selectUser: "Seleziona utente",
        addFile: "Aggiungi file",
        uploadFile: "Seleziona file",
        departureDate: "Ora di partenza",
        departureTime: "Ora di partenza",
        returnDate: "Ora di ritorno",
        returnTime: "Ora di ritorno",
        region: "Regione",
        hotelName: "Nome dell'hotel",
        checkIn: "Data di check-in",
        checkInTime: "Ora di accesso",
        checkOut: "Data di uscita",
        checkOutTime: "Ora di uscita",
      },
    },
    sideBar: {
      dashboard: "Dashboard",
      profile: "Profilo",
      message: "Messaggio",
      operations: "Operazioni",
      calendar: "Calendario",
      search: "Ricerca",
      credit: "Credito",
      signOut: "Sign Out",
    },
    feedBack: {
      title: "Feedback",
      subTitle: "",
      text: "Avete un feedback da condividere?",
      success: "Trasmesso con successo.",
      send: "Invia",
    },
    search: {
      title: "Medico, Operazione",
      selectBody: "Scegli una parte del corpo",
      selectOperation: "Scegli un`operazione",
      headArea: "Testa",
      upperArea: "Parte superiore del corpo",
      bottomArea: "Parte inferiore del corpo",
      handArea: "Braccia",
      legArea: "Schiena",
      showDoctors: "Mostrami i medici",
      price: "Prezzi",
      rating: "Recensioni",
      filter: "Filtro",
      bodyArea: "Parte del corpo",
      gender: "Genere",
      country: "Paese",
      city: "Città",

      surgeryDate: "Data operazione",
      welcome: "Benvenuto",
    },
    doctorDetail: {
      reward: "Premi",
      references: "Referenze",
      sendMessage: "Invia un messaggio",
      moreOperation: "Altre operazioni",
    },
    profile: {
      changePassword: "Cambia password",
      emailNotification: "Notifica via e-mail",
      phoneNotification: "Notifica telefonica",
      addPhoto: "Aggiungi foto",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Campo obbligatorio",
      nameshort: "Il nome è troppo corto",
      topicshort: "Oggetto troppo breve",
      messageshort: "Il messaggio è troppo corto",
      surnameshort: "Il cognome è troppo corto",
      correctmail: "Inserisci un indirizzo email valido",
      correctphone: "Il numero di telefono non è nel formato corretto",
      correctpassword: "La password deve contenere almeno 8 caratteri",
      repeatpassword: "Le password devono corrispondere",
      login: "Le informazioni di accesso non sono corrette",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
  tr: {
    landing: {
      menu: {
        contact: "İletişim",
        terms: "Kullanım Şartları",
        privacy: "Gizlilik Politikası",
      },
      head: {
        title: "Embrace Aesthetics",
        text: `Güzellik ve sağlık alanında öne çıkan bir platform olarak YUMA CARE, benzersiz hizmetler sunmaktadır. Tüm avantajlardan yararlanmak için şimdi ücretsiz kaydolun.`,
        title2: "Elevate Life!",
        seeDoctors: "+200 DOKTORU GÖR",
        contact: "Bizimle İletişime Geçin",
      },
      infoPartTitle: "Eşsiz özelliklerimiz",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Doktor arama",
          text: "İhtiyaçlarınız için doğru doktoru hızlı ve kolay bir şekilde bulun",
        },
        {
          title: "İletişim",
          text: "Görüntülü konsültasyon yoluyla doktorla iletişime geçin veya ana dilinizde sohbet edin",
        },
        {
          title: "Organizasyon",
          text: "Tüm önemli bilgi ve belgelere tek bir arayüzden ulaşın",
        },
        {
          title: "Bakım öncesi ve sonrası",
          text: "İşlem öncesi ve sonrası için gerekli tüm adımlarda doktorunuzla iletişimde kalın ",
        },
        {
          title: "Sağlık Turizm Yetki Belgesi",
          text: "Sadece Sağlık Turizm Yetki Belgesine sahip olan doktorlarda ameliyat olun",
        },
        {
          title: "Veri güvenliği",
          text: "Şahsi bilgi veya fotoğraflarınız sadece doktorunuz ve sizin aranızda kalsın",
        },
        {
          title: "Geri bildirim",
          text: "Uluslarası üyelerimizin doktorlarımızla ilgili görüşlerinden faydalanın",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Doktorlarımız",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+200 Doktor",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operasyon",
      },
      operationDetail: {
        mainTitle: `Seçenekler`,
        left: {
          desc: `Sembole tıklayarak ameliyat olmak istediğiniz bölgeyi seçin`,
        },
        right: {
          desc: `İmleci grafiğin üzerine getirerek ameliyat olmak istediğiniz bölgeyi seçin`,
        },
      },
      faq: {
        mainTitle: "Sıkça sorulan sorular",
        shortTitle: "FAQ",
        data: [
          {
            title: `YUMA CARE'i kullanabilmem için kayıt olmam gerekiyor mu?`,
            desc: `Evet, platformumuzu kullanıp tüm avantajlardan faydalanabilmek adına, YUMA CARE üyesi olmanız gerekmektedir.`,
          },
          {
            title: `Verilerim doktora nasıl iletilir?`,
            desc: "Tüm verileriniz (ayrıntılar, sohbet, belgeler, resimler) üçüncü şahısların erişemeyeceği şekilde şifreli olarak doktora iletilir.",
          },
          {
            title: "Yerinde destek nasil verilir?",
            desc: `Süreciniz başlangıcı ve bitişi arası, yani varışınızdan ayrılışınıza kadar her adımda doktorunuz ve ekibi size eşlik edecek ve destek sağlayacak. Büyük ameliyatlar veya uzun süreli konaklamalar durumunda, yine de bir refakatçı bulundurmanızı öneririz.`,
          },
          {
            title: `Bir doktorun YUMA CARE'de görünebilmesi için karşılaması gereken belirli kriterler var mı?`,
            desc: `Evet, YUMA CARE'de yalnızca sağlık turizm yetki belgesine sahip olan doktorlar görülmektedir. Bu belge, doktorun yurt dışından gelen hastaları tedavi edebilmesini sağlar (ülkeye özgü).`,
          },
          {
            title: `Doktor hakkında kim geri bildirim sağlayabilir?`,
            desc: `Yalnızca YUMA CARE aracılığıyla kaydolan ve platformumuzda yer alan doktordan tedavi gören hastaların geri bildirim formu etkinleştirilir.`,
          },
          {
            title: `Ön inceleme gerekli midir?`,
            desc: `Bu planlanan tedaviye bağlıdır. Bu amaçla doktor, ön muayenelerin gerekli olup olmadığı ve hangilerinin gerekli olduğu konusunda size kişisel bir görüşmede kesin bilgi verecektir.`,
          },
          {
            title: `Aynı sorunla ilgili birkaç doktorla görüşebilir miyim?`,
            desc: `YUMA CARE size doğru ve size iyi hissettiren kararı almanızda yardımcı olmak isteyen bir platformdur. İçinize sinen seçimi yapabilmek adına, birden fazla doktorla görüşmekte özgürsünüz.`,
          },
        ],
      },
      search: {
        date: "Tarih",
      },
      contact: {
        title: "İletişim",
        adress: "Adres",
        phone: "Telefon",
        mail: "Mail Adresi",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria (23)",
            job: "Ofis Yöneticisi",
            title: "Yaşam Kalitemi Artırdım",
            text: `Mide küçültme ameliyatım sadece kilomu azaltmakla kalmadı, aynı zamanda yaşam kalitemi ve sağlığımı da önemli ölçüde iyileştirdi. Kendimi harika hissediyorum ve daha sağlıklı ve mutlu bir yaşam için sabırsızlanıyorum!`,
            image: Ilaria,
          },
          {
            name: "Branko (42)",
            job: "Uzman Satış Elemanı",
            title: "İstediğim gibi görünüyorum",
            text: `Saç ekimimden sonra, sonuçtan çok memnunum ve yeni saçlarımdan çok memnunum!`,
            image: Branko,
          },
          {
            name: "Suel (33)",
            job: "Satış Yöneticisi",
            title: "Sanki yeniden doğmuş gibi",
            text: `Dişlerimi yaptırdıktan sonra kendimi yeni bir insan gibi hissediyorum! Görünüşüm değişti ve artık çok daha fazla gülümsüyorum. Sonuçtan çok memnunum ve yeni dişlerimi dünyaya göstermek için sabırsızlanıyorum.`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "Daha fazla",
          title2: "özgüven",
          text: `Kullanıcılarımızın özgüvenlerini güçlendirmelerine ve estetik algılarını geliştirmelerine yardımcı olan tutum ve yaklaşımımızla sizlere en iyi şekilde hizmet sunmak bu işin temelini oluşturuyor. Müşteri memnuniyetine odaklanarak, ihtiyaçlarınıza duyarlı olmayı ve en iyi halinize ulaşmanızı istiyoruz. Bu misyonu gerçekleştirmek, güveninizi sağlamak ve ekibimizin tutku ve bağlılıkla sağladığı katkılarla sizlerin memnuniyetini en üst düzeye çıkarmayı hedefliyoruz.`,
        },
        maria: {
          title: "Bireysel güzelliğinizi",
          title2: "keşfedin",
          text: `İnsanların kendi ciltlerinde rahat hissetmelerine yardımcı olmak, estetik cerrahi ve güzellik hizmetlerinde anlamlı bir hedef. Gerçek güzelliğin içten geldiğine olan inancımız, estetik müdahalenin ötesine geçerek iyi hissetmenizi sağlamayı bir adım öne taşıyor. Bu sebeple platformumuz, destekleyici ve empatik bir ortam yaratarak yalnızca fiziksel değil duygusal olarak da değerli hissedeceğiniz harika bir yol.`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Güzellik ve özgüven: Türkiye'de neden giderek daha fazla insan estetik cerrahiyi tercih ediyor",
            text: "Türkiye son yıllarda estetik cerrahi için popüler bir destinasyon haline geldi. Dünyanın her yerinden giderek daha fazla insan estetik isteklerini yerine getirmek için bu büyüleyici ülkeye seyahat ediyor...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL ameliyatı: Brezilya Tipi Popo Kaldırma için kapsamlı bir rehber",
            text: "Son yıllarda estetik prosedürlere olan talep önemli ölçüde artmıştır ve en popüler operasyonlardan biri Brezilya Tipi Popo Kaldırma (BBL) işlemidir. Bu prosedür özellikle kadınlar arasında popülerdir...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Erkekler için estetik cerrahi: büyüyen bir trend",
            text: "Son yıllarda güzellik ideali sadece kadınlar için değil erkekler için de önemli ölçüde değişti. Giderek daha fazla sayıda erkek, görünümlerini iyileştirmek için estetik cerrahiyi tercih ediyor...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Şifremi Unuttum",
      changePassword: "Şifre Değiştir",
      checkEmail: `E-postanızı kontrol edin`,
      sendEmail: "E-posta Gönder",
      anyProblem: `E-postayı almadınız mı? Spam klasörünüzü kontrol edin veya`,
      anyProblem1: `başka bir e-posta adresi deneyin.`,
      emailText: `*Hesabınızla ilişkili e-postayı girin ve biz de
                        şifrenizi sıfırlamak için bir e-posta talimatı gönderin.`,
      sendedEmail: `*Kayıtlı adresinize bir şifre iptal talimatı gönderdik
              e posta.`,
      successChange: "Şifre Değiştirme Başarılı",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Giriş yap",
    },
    register: {
      title: "Kayıt ol",
      doctorRegister: "Doktor Kayıt",
      hospitalRegister: "Hastane Kayıt",
      doctorInfo: "Doktor Bilgisi",
      hospitalInfo: "Hastane Bilgisi",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Hitap",
        title: "Size nasıl",
        title2: "Hitap Edelim?",
      },
      step2: {
        caption: "Operasyon",
        title: "Operasyon",
        title2: "Operasyonları",
        title3: "Düşünüyorsunuz?",
      },
      step3: {
        caption: "İletişim",

        title: "Sizinle nasıl",
        title2: "İletişim",
        title3: "kuralım?",
      },
      step4: {
        caption: "Kontakt",
        title: "İletişime geçeceğin",
        title2: "Doktoru",
        title3: "seçmek ister misin?",
      },
      step5: {
        caption: "Profil",
        title: "Profilini",
        title2: "Tamamlayalım",
      },
      selected: "Seçildi",
      viewDetails: "Datayları Gör",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "Eğer doktorsanız burdan devam edin ",
      doctorregisterbutton: "Kayıt Ol",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",

      email: "E-mail",
      emailPlaceholder: "E-Mail adresi giriniz",
      password: "Şifre",
      passwordPlaceholder: "Şifre giriniz",
      remember: "Beni hatırla",
      forgotPassword: "Şifreni mi unuttun?",
      username: "Kullanıcı adı",
      name: "İsim",
      hospitalName: "Hastane İsmi",
      surname: "Soyisim",
      phone: "Telefon",
      phonePlaceholder: "Telefon numaranızı giriniz",
      birthday: "Doğum günü",
      changePassword: "Şifre değiştir",
      emailNotification: "E-mail bildirimleri",
      phoneNotification: "Telefon bildirimleri",
      doYouHaveAccount: "Hesabınız var mı? Giriş yapın",
      subject: "Konu",
      message: "Mesaj",
      send: "Gönder",
      namePlaceholder: "İsim giriniz",
      surnamePlaceholder: "Soyisim giriniz",
      phonePlaceholder: "Telefon numaranızı giriniz",
      gender: "Cinsiyet",
      man: "Erkek",
      woman: "Kadın",
      divers: "Seçmek İstemiyorum",
      date: "Tarih",
      region: "Ülke",
      regionPlaceholder: "Ülke seçiniz",
      province: "İl",
      provincePlaceholder: "İl seçiniz",
      district: "İlçe",
      districtPlaceholder: "İlçe giriniz",
      address: "Adres",
      addressPlaceholder: "Adres giriniz",
      zipcode: "Posta Kodu",
      zipcodePlaceholder: "Posta kodu giriniz",
      passwordAgain: "Şifre tekrar",
      title: "Ünvan",
      titlePlaceholder: "Ünvan seçiniz",
      workingPlace: "İş yeri",
      workingPlacePlaceholder: "İş yerinizi giriniz",
      profileImage: "Profil resmi",
      cv: "Hakkımda",
      cvPlaceholer: "Hakkınızda kısa bilgi  ",
      reward: "Ödül/Sertifika",
      captionPlaceholder: "Başlık giriniz",

      detailPlaceholder: "Detaylı bilgi ekleyiniz",
      addReward: "Ödül/Sertifika ekle",
      document: "Diploma",
      documentTitle: "Diploma",
      addDocument: "Belge ekle",
      documentFile: "Diploma dosyası",
      addDocument: "Belge ekle",
      categoryOperation: "Kategori & Operasyon",
      selectCategory: "Kategori seçiniz",
      newOperation: "Operasyon oluştur",
      selection: "Seçenekler",
      operationDetail: "Operasyon bilgisi giriniz",
      selectOperation: "Operasyon seçiniz",
      addOperation: "Operasyon ekle",
      reference: "Referans",
      referencePlaceholder: "İsim giriniz",
      referenceTitle: "Pozisyon giriniz",
      referenceCaption: "İletişim bilgisi giriniz",
      addReference: "Referans ekle",
      operation: "Operasyon öncesi sonrası fotoğrafları",
      operationTitle: "Operasyon fotoğraf başlığı",
      operationImage: "Operasyon fotoğrafı",
      addOperationImage: "Operasyon fotoğrafı ekle",
      professionTitle: "Uzmanlık Branşı",
      doctorTitle: [
        {
          name: "Dr.",
          value: "Doktor",
        },
        {
          name: "Uzm. Dr.",
          value: "Uzman Doktor",
        },
        {
          name: "Prof. Dr.",
          value: "Profesör Doktor",
        },
      ],
      professionSelect: [
        {
          name: "Yüz Cerrahi",
        },
        {
          name: "Otorinolarengoloji/KBB",
        },
        {
          name: "Estetik ve Plastik Cerrahi",
        },
        {
          name: "Plastik, Rekonstrüktif ve Estetik Cerrahi",
        },
        {
          name: "Obezite",
        },
        {
          name: "Göz Cerrahi",
        },
        {
          name: "Ağız, Diş ve Çene Cerrahi",
        },
        {
          name: "Medikal Estetik",
        },
        {
          name: "Jinekolog",
        },
      ],
      healthTourismFile: "Sağlık Turizm Belgesi",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Hizmet şartları",
      privacy: "Gizlilik politikası",
    },
    dashboard: {
      message: "Mesajlar",
      welcome: "Hoşgeldin",
      more: "Daha fazla",
      medicalDetails: "Medikal detaylar",
      viewDetails: "Detayları görüntüle",
      giveFeedback: "Geribildirim ver",
      whatAbout: "Bizim hakkımızda ne düşünüyorsunuz?",
      thought: "Paylaşmak istediğiniz bir fikriniz var mı?",
      signOut: "Çıkış yap",
    },
    message: {
      new: "Yeni mesaj",
      message: "Mesaj",
      image: "Görsel",
      file: "Dosya",
      video: "Video",
      meetLink: "Meet Linki",
      doctorInvite: "Doktor Daveti",
      meetText: "Hey, sana meet isteği yolladım, katılmak için lütfen tıkla.",
      sendedFile: "Dosya Gönderdi",
      doctorInviteText: "Doctor'a operasyon daveti gönderin.",
      doctorInviteMessage: "Hey, sana operasyon isteği yolladım.",
    },
    operations: {
      title: "Operasyonlar",
      addOperation: "Operasyon Ekle",
      viewDetails: "Detay Göster",
      operationDetail: "Operasyon Detay",
      more: "Daha Fazla",
    },
    calendar: {
      title: "Takvim",
      flightTime: "Uçuş",
      accomodation: "Konaklama",
      operations: "Operasyonlar",
      myReminder: "Hatırlatıcım",
      newOperation: "Yeni Operasyon",
      newFlight: "Yeni Uçuş",
      newHotel: "Yeni Konaklama",
      day: "Gün",
      week: "Hafta",
      month: "Ay",
      popup: {
        title: "Başlık",
        note: "Not",
        save: "Kaydet",
        operationDate: "Operasyon Tarihi",
        operationTime: "Operasyon Saati",
        doctor: "Doktor",
        user: "Kullanıcı",
        selectOperation: "Operasyon Seçiniz",
        selectDoctor: "Doktor Seçiniz",
        selectUser: "Kullanıcı Seçiniz",
        addFile: "Dosya Ekle",
        uploadFile: "Dosya Seç",
        departureDate: "Gidiş Zamanı",
        departureTime: "Gidiş Saati",
        returnDate: "Dönüş Zamanı",
        returnTime: "Dönüş Saati",
        region: "Bölge",
        hotelName: "Otel Adı",
        checkIn: "Giriş Tarihi",
        checkInTime: "Giriş Saati",
        checkOut: "Çıkış Tarihi",
        checkOutTime: "Çıkış Saati",
      },
    },
    sideBar: {
      dashboard: "Panel",
      profile: "Profil",
      message: "Mesajlar",
      operations: "Operasyonlar",
      calendar: "Takvim",
      search: "Arama",
      credit: "Kredi",
      signOut: "Çıkış Yap",
    },
    feedBack: {
      title: "Geri Bildirim",
      subTitle: "Bizim hakkımızda ne düşünüyorsunuz?",
      text: "Paylaşmak istediğiniz herhangi bir geri bildiriminiz var mı?",
      success: "Başarıyla iletildi.",
      send: "Gönder",
    },
    search: {
      title: "Doktor, Operasyon",
      selectBody: "Vücut kısmını seçiniz",
      selectOperation: "Operasyon seçiniz",
      headArea: "Baş",
      upperArea: "Üst bölge",
      bottomArea: "Alt bölge",
      handArea: "Kol",
      legArea: "Bacak",
      showDoctors: "Doktorları gör",
      price: "Fiyat",
      rating: "Derecelendirme",
      filter: "Filtre",
      bodyArea: "Vücut bölgesi",
      gender: "Cinsiyet",
      country: "Ülke",
      city: "Şehir",
      surgeryDate: "Operasyon Tarihi",
      welcome: "Hoşgeldin",
    },
    doctorDetail: {
      reward: "Ödül",
      references: "Referanslar",
      sendMessage: "Mesaj gönder",
      moreOperation: "Fazla Operasyon",
    },
    profile: {
      changePassword: "Şifre Değiştir",
      emailNotification: "E-Mail Bildirimi",
      phoneNotification: "Telefon Bildirimi",
      addPhoto: "Fotoğraf Ekle",
      doctorProfile: "Detay Bilgiler",
      userProfile: "Kullanıcı  Bilgileri",
    },
    errors: {
      mandatoryfield: "Zorunlu Alan",
      nameshort: "İsim çok kısa",
      topicshort: "Konu çok kısa",
      messageshort: "Mesaj çok kısa",
      surnameshort: "Soyisim çok kısa",
      correctmail: "Doğru bir email adresini giriniz.",
      correctphone: "Telefon numaranız doğru formatta değil (5xxxxxxxxx)",
      correctpassword: "Şifre en az 8 karakter içermelidir.",
      repeatpassword: "Önceki şifre ile aynı olmalıdır",
      login: "Giriş bilgileri hatalıdır",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi açık rıza metnini onaylayın",
      chatOncesiAydinlatmaError:
        "Lütfen chat öncesi aydınlatma metnini onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma metnini onaylayın",
      uyelikRizaError: "Lütfen  üyelik açık rıza metnini onaylayın",
      cerezError: "Lütfen çerez politikasını onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı sözleşmesini onaylayın",
    },
  },
  sr: {
    landing: {
      menu: {
        contact: "Impressum",
        terms: "Uslovi korišćenja",
        privacy: "Politika privatnosti",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE je tvoja platforma kada je u pitanju lepota i zdravlje. Registruj se sada da bi uživao/la u svim prednostima.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "To nas čini jedinstvenim.",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Pretraga lekara",
          text: "Brzo i jednostavno pronađi odgovarajućeg lekara za svoje potrebe",
        },
        {
          title: "Komunikacija",
          text: "Stupi u kontakt s lekarom putem video konsultacija ili chata na svom maternjem jeziku",
        },
        {
          title: "Organizacija",
          text: "Imaj pregled nad svim važnim informacijama i dokumentima",
        },
        {
          title: "Pred- i posleoperativna nega",
          text: "iOstani u kontaktu s lekarom za sve potrebne korake pre i posle intervencije",
        },
        {
          title: "Sertifikovani lekari",
          text: "Operiši se samo kod sertifikovanih lekara",
        },
        {
          title: "Zaštita podataka",
          text: "Uverite se da vaši lični podaci ostanu samo između vas i vašeg lekara",
        },
        {
          title: "Povratne informacije",
          text: "Iskoristite iskustva drugih",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Mogućnosti izbora`,
        left: {
          desc: `BIzaberite odgovarajući simbol za željeni operativni zahvat`,
        },
        right: {
          desc: `Pomaknite mišem preko grafičkog prikaza i kliknite na željeni deo tela.`,
        },
      },
      faq: {
        mainTitle: "Često postavljana pitanja",
        shortTitle: "FAQ",
        data: [
          {
            title: `Mora li registracija biti obavljena kako bi se koristila YUMA CARE?`,
            desc: `Da, registracija je neophodna kako biste mogli iskoristiti sve prednosti naše platforme.`,
          },
          {
            title: `Kako je organizovana podrška na licu mesta?`,
            desc: `Svi tvoji podaci (informacije, chat, dokumenti, slike) se šalju enkriptovani lekaru, tako da treće strane nemaju pristup njima.`,
          },
          {
            title: `Kako je organizovana podrška na licu mesta? `,
            desc: `Doktor i njegov tim koje ste izabrali osiguravaju da se ne morate brinuti ni o čemu na licu mesta. Od vašeg dolaska do vašeg odlaska, bićete ispraćeni i podržani u svakom koraku.`,
          },
          {
            title: `Da li postoje određeni kriterijumi koje lekar mora ispuniti da bi se pojavio na YUMA CARE?`,
            desc: `Da, na YUMA CARE se prikazuju samo lekari sa određenom licencom. Ova dozvola osigurava da lekar može da leči pacijente iz inostranstva (specifično za svaku zemlju).`,
          },
          {
            title: `Ko može dati povratne informacije o lekaru?`,
            desc: `Samo pacijenti koji su registrovani na YUMA CARE i koji su primili lečenje od lekara mogu dobiti pristup obrascu za povratne informacije.`,
          },
          {
            title: `Da li su potrebne prethodne analize? `,
            desc: `To zavisi od planiranog tretmana. Lekar će ti dati tačne informacije u vezi sa tim da li su potrebne i koje prethodne analize tokom ličnog razgovora.`,
          },
          {
            title: `Da li mogu stupiti u kontakt sa više lekara za isto pitanje?`,
            desc: `Slobodno iskoristi mogućnost na YUMA CARE da postaviš svoje pitanje kod više lekara kako bi konačno odlučio/la kod kog lekara se osećaš najprijatnije.`,
          },
        ],
      },
      search: {
        date: "Datum",
      },
      contact: {
        title: "Kontakt",
        adress: "Adresa",
        phone: "Broj telefona",
        mail: "Email adresa",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Prijava",
    },
    register: {
      title: "Registracija",
      doctorRegister: "Registracija doktora",
      doctorInfo: "Informacije o doktoru",
      hospitalRegister: "Hastane Kayıt",
      hospitalInfo: "Hastane Bilgisi",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "E-mail",
      emailPlaceholder: "Tvoja e-mail adresa",
      password: "Lozinka",
      passwordPlaceholder: "Tvoja lozinka",
      remember: "Ostani prijavljen/a",
      forgotPassword: "Zaboravljena lozinka?",
      username: "Korisničko ime",
      name: "Ime",
      hospitalName: "Назив болнице",
      surname: "Prezime",
      phone: "Telefon",
      phonePlaceholder: "Tvoj broj telefona",
      birthday: "Datum rođenja",
      changePassword: "Promeni lozinku",
      emailNotification: "Obaveštenja putem e-maila",
      emailNotification: "Mobilna obaveštenja",
      doYouHaveAccount: "Već imaš nalog? Prijavi se sada!",
      subject: "Predmet",
      message: "Poruka",
      send: "Pošalji",
      namePlaceholder: "Ime",
      surnamePlaceholder: "Prezime",
      phonePlaceholder: "Broj telefona",
      gender: "Pol",
      man: "Muški",
      woman: "Ženski",
      divers: "Različito",
      date: "Datum rođenja",
      region: "Zemlja",
      regionPlaceholder: "Izaberi zemlju",
      province: "Pokrajina",
      provincePlaceholder: "Izaberi pokrajinu",
      district: "Grad",
      districtPlaceholder: "Unesi grad",
      address: "Adresa",
      addressPlaceholder: "Unesi svoju adresu",
      zipcode: "Poštanski broj",
      zipcodePlaceholder: "Unesi svoj poštanski broj",
      passwordAgain: "Ponovi lozinku",
      title: "Titula",
      titlePlaceholder: "Izaberi svoju titulu",
      workingPlace: "Radno mesto",
      workingPlacePlaceholder: "Navedi svoje radno mesto",

      profileImage: "Profilna slika",
      cv: "O tebi",
      cvPlaceholer: "Predstavi se",
      reward: "Nagrade i/ili sertifikati",
      captionPlaceholder: "Naslov nagrade i/ili sertifikata",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      detailPlace: "Dodaj nagrade i/ili sertifikate",
      document: "Dokument",
      documentTitle: "Diploma",
      documentFile: "Fajl",
      addDocument: "Dodaj dokument",
      documentTitle: "Sertifikat odobrenja za zdravstveni turizam",
      documentFile: "Fajl",
      addDocument: "Dodaj fajl",
      categoryOperation: "Kategorija i operacija",
      selectCategory: "Kategorija i operacija",
      newOperation: "Izaberi kategoriju",
      selection: "Kreiraj operaciju",
      operationDetail: "Opcije",
      selectOperation: "Opis operacije",
      addOperation: "Izaberi operaciju",
      reference: "Referenca",
      referencePlaceholder: "Dodaj referencu",
      referenceTitle: "Naslov reference",
      referenceCaption: "Opis reference",
      addReference: "Dodaj referencu",
      operation: "Operacija / Slike pre i posle",
      operationTitle: "Naslov slike",
      operationImage: "Slika pre i posle",
      addOperationImage: "Dodaj sliku pre i posle",
      professionTitle: "Stručna oblast",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Doktor",
        },
        {
          name: "Spets. Dr.",
          value: "Specijalist Doktor",
        },
        {
          name: "Prof. Dr.",
          value: "Profesor Doktor",
        },
      ],
      professionSelect: [
        {
          name: "Facijalna hirurgija",
        },
        {
          name: "Otorinolaringologija",
        },
        {
          name: "Estetska i plastična hirurgija",
        },
        {
          name: "Plastična, rekonstruktivna i estetska hirurgija",
        },
        {
          name: "Gojaznost",
        },
        {
          name: "Hirurgija oka",
        },
        {
          name: "Oralna, dentalna i vilica hirurgija",
        },
        {
          name: "Medicinska estetika",
        },
        {
          name: "Ginekologija",
        },
      ],
      healthTourismFile: "Dozvola za zdravstveni turizam",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Uslovi korišćenja",
      privacy: "Politika privatnosti",
    },
    dashboard: {
      message: "Poruke",
      welcome: "Dobrodošli",
      more: "Više",
      medicalDetails: "Dodatne medicinske informacije",
      viewDetails: "Detalji",
      giveFeedback: "Ocenite nas",
      whatAbout: "Kako vam se sviđa YUMA Care?",
      thought: "Imate li ideje ili sugestije za nas?",
      signOut: "Odjava",
    },
    message: {
      new: "Nova poruka",

      message: "Poruka",
      image: "Slika",
      file: "Datoteka",
      video: "Video",
      meetLink: "Link za sastanak",
      doctorInvite: "Potvrdi doktora",
      meetText: "Hey, sana meet isteği yolladım, katılmak için lütfen tıkla.",
      sendedFile: "Dosya Gönderdi",
      doctorInviteText: "Doctor'a operasyon daveti gönderin.",
      doctorInviteMessage: "Hey, sana operasyon isteği yolladım.",
    },
    operations: {
      title: "Operacije",
      addOperation: "Operasyon Ekle",
      viewDetails: "Prikaži detalje",
      operationDetail: "Operasyon Detay",
      more: "Daha Fazla",
    },
    calendar: {
      title: "Kalendar",
      flightTime: "Letovi",
      accomodation: "Smeštaj",
      operations: "Operacije",
      myReminder: "Hatırlatıcım",
      newOperation: "Yeni Operasyon",
      newFlight: "Yeni Uçuş",
      newHotel: "Yeni Konaklama",
      day: "Gün",
      week: "Hafta",
      month: "Ay",
      popup: {
        title: "Başlık",
        note: "Napomena",
        save: "Sačuvaj",
        operationDate: "Operasyon Tarihi",
        operationTime: "Operasyon Saati",
        doctor: "Doktor",
        user: "Kullanıcı",
        selectOperation: "Odaberi operaciju",
        selectDoctor: "Doktor Seçiniz",
        selectUser: "Kullanıcı Seçiniz",
        addFile: "Dosya Ekle",
        uploadFile: "Dosya Seç",
        departureDate: "Gidiş Zamanı",
        departureTime: "Gidiş Saati",
        returnDate: "Dönüş Zamanı",
        returnTime: "Dönüş Saati",
        region: "Bölge",
        hotelName: "Otel Adı",
        checkIn: "Prijava (u hotel)",
        checkInTime: "Vreme prijave (u hotel)",
        checkOut: "Odjava (iz hotela)",
        checkOutTime: "Vreme odjave (iz hotela)",
      },
    },
    sideBar: {
      dashboard: "Panel",
      profile: "Profil",
      message: "Poruka",
      operations: "Operasyonlar",
      calendar: "Takvim",
      search: "Arama",
      credit: "Kredi",
      signOut: "Odjavi se",
    },
    feedBack: {
      title: "Povratne informacije",
      subTitle: "Šta mislite o nama?",
      text: "Imate li bilo kakve povratne informacije koje želite da podelite?",
      success: "Başarıyla iletildi.",
      send: "Pošalji",
    },
    search: {
      title: "Doktor, Operacija ",
      selectBody: "Odaberi deo tela",
      selectOperation: "Odaberi operaciju",
      headArea: "Glava",
      upperArea: "Gornji deo tela",
      bottomArea: "Donji deo tela",
      handArea: "Ruke",
      legArea: "Leđa",
      showDoctors: "Prikaži mi lekare",
      price: "Cene",
      rating: "Ocene",
      filter: "Filter",
      bodyArea: "Deo tela",
      gender: "Pol",
      country: "Zemlja",
      city: "Grad",
      surgeryDate: "Operasyon Tarihi",
      welcome: "Dobrodošli",
    },
    doctorDetail: {
      reward: "Nagrade",
      references: "Reference",
      sendMessage: "Pošalji poruku",
      moreOperation: "Fazla Operasyon",
    },
    profile: {
      changePassword: "Promeni šifru",
      emailNotification: "Obaveštenje putem e-pošte",
      phoneNotification: "Obaveštenje putem telefona",
      addPhoto: "Dodaj sliku",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Obavezno polje",
      nameshort: "Ime je prekratko",
      topicshort: "Predmet je prekratak",
      messageshort: "Poruka je prekratka",
      surnameshort: "Prezime je prekratko",
      correctmail: "Unesite validnu email adresu",
      correctphone: "Broj telefona nije u ispravnom formatu",
      correctpassword: "Lozinka mora imati najmanje 8 karaktera",
      repeatpassword: "Lozinke se moraju poklapati",
      login: "Le informazioni di accesso non sono corrette",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
  bg: {
    landing: {
      menu: {
        contact: "Импресум",
        terms: "Условия за ползване",
        privacy: "Политика за поверителност",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE е твоят портал, когато става дума за красота и здраве. Регистрирай се сега, за да се възползваш от всичките предимства.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "Това ни прави уникални",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Търсене на лекар",
          text: "Намери бързо и лесно подходящ лекар за твоите нужди",
        },
        {
          title: "Комуникация",
          text: "Свържи се с лекар чрез видеоконсултация или чат на родния си език",
        },
        {
          title: "Организация",
          text: "Запази всички важни информации и документи под контрол",
        },
        {
          title: "Предоперативна и следоперативна грижа",
          text: "Останете в контакт с лекаря за всички необходими стъпки преди и след процедурата",
        },
        {
          title: "Сертифицирани лекари",
          text: "Оперирайте се само при сертифицирани лекари",
        },
        {
          title: "Защита на данните",
          text: "Уверете се, че вашите лични данни остават само между вас и вашия лекар.",
        },
        {
          title: "Обратна връзка",
          text: "Използвайте опита на другите",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Опции`,
        left: {
          desc: `Изберете съответния символ за желаната операция`,
        },
        right: {
          desc: `Плъзнете мишката върху изображението и кликнете върху желаната част на тялото`,
        },
      },
      faq: {
        mainTitle: "Често задавани въпроси",
        shortTitle: "FAQ",
        data: [
          {
            title: `Дали трябва да съм регистриран, за да мога да използвам YUMA CARE?`,
            desc: `Да, за да може да използвате нашата платформа с всички предимства, е необходима регистрация.`,
          },
          {
            title: `Как се предават моите данни на лекаря?`,
            desc: `Всички ваши данни (информация, чат, документи, снимки) се предават криптирани на лекаря, така че трети лица да нямат достъп до тях.`,
          },
          {
            title: `Каква е грижата на място?`,
            desc: `Избраният от вас лекар и неговият екип ще се погрижат да не се тревожите за нищо на място. От пристигането ви до заминаването ви, ще бъдете съпровождани и подкрепяни на всяка стъпка. За по-големи операционни намеси или по продължително престои ви препоръчваме да доведете придружител.`,
          },
          {
            title: `Има ли определени критерии, които лекар трябва да изпълнява, за да се появи в YUMA CARE?`,
            desc: `Да, само лекари със специфична лицензия се показват в YUMA CARE. Тази лицензия гарантира, че лекарят може да лекува пациенти от чужбина (в зависимост от конкретната страна).`,
          },
          {
            title: `Кой може да даде обратна връзка за лекаря?`,
            desc: `Само пациентите, които са регистрирани в YUMA CARE и са получили лечение от лекар, имат достъп до формуляра за обратна връзка.`,
          },
          {
            title: `Изискват ли се предварителни изследвания ?`,
            desc: `Това зависи от планираното лечение. Лекарят ще ви предостави точна информация по този въпрос по време на личния разговор, дали и кои предварителни изследвания са необходими.`,
          },
          {
            title: `Мога ли да се свържа с няколко лекари по същия въпрос?`,
            desc: `Не се притеснявайте да използвате опцията в YUMA CARE, за да отправите запитването си към няколко лекари и накрая да решите при кой от тях бихте се почуствали най-комфортно.`,
          },
        ],
      },
      search: {
        date: "Дата",
      },
      contact: {
        title: "Контакт",
        adress: "Адрес",
        phone: "Телефонен номер",
        mail: "Имейл адрес",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Вход",
    },
    register: {
      title: "Регистрация",
      doctorRegister: "Регистрация на лекар",
      doctorInfo: "Информация за лекар",
      hospitalRegister: "Регистрация в болница",
      hospitalInfo: "Информация за болницата",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "Имейл",
      emailPlaceholder: "Твоят имейл адрес",
      password: "Парола",
      passwordPlaceholder: "Твоята парола",
      remember: "Остави ме вписан",
      forgotPassword: "Забравена парола?",
      username: "Потребителско име",
      name: "Име",
      hospitalName: "Hastane İsmi",
      surname: "Фамилия",
      phone: "елефон",
      phonePlaceholder: "Твоят телефонен номер",
      birthday: "Дата на раждане",
      changePassword: "Промени паролата",
      emailNotification: "Известия по имейл",
      phoneNotification: "Mobile notifications",
      doYouHaveAccount: "Вече имаш акаунт? Влез сега!",
      subject: "Тема",
      message: "Съобщение",
      send: "Изпрати",
      namePlaceholder: "Име",
      surnamePlaceholder: "Фамилия",
      phonePlaceholder: "Телефонен номер",
      gender: "Пол",
      man: "Мъж",
      divers: "Различен",
      woman: "Жена",
      date: "Дата на раждане",
      region: "Държава",
      regionPlaceholder: "Избери държава",
      province: "Област",
      provincePlaceholder: "Избери област",
      district: "Град",
      districtPlaceholder: "Въведи град",
      address: "Адрес",
      addressPlaceholder: "Въведи адреса си",
      zipcode: "Пощенски код",
      zipcodePlaceholder: "Въведи пощенския си код",
      passwordAgain: "Повтори паролата",
      title: "Титла",
      titlePlaceholder: "Избери титлата си",
      workingPlace: "Работно място",
      workingPlacePlaceholder: "Въведи мястото на работа си",

      profileImage: "Профилна снимка",
      cv: "За вас",
      cvPlaceholer: "Представете се",
      reward: "Награди и/или сертификати",
      captionPlaceholder: "Заглавие на награда и/или сертификат",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      detailPlace: "Добавяне на награди и/или сертификати",
      document: "Документ",
      documentTitle: "Диплома",
      documentFile: "Файл",
      addDocument: "Добавяне на документ",
      documentTitle: "Сертификат за разрешение за медицински туризъм",
      documentFile: "Файл",
      addDocument: "Добавяне на документ",
      categoryOperation: "Категория и операция",
      selectCategory: "Изберете категория",
      newOperation: " Създайте операция",
      selection: "Опции",
      operationDetail: "Описание на операцията",
      selectOperation: "Изберете операция",
      addOperation: "Добавяне на операция",
      reference: "Препратка",
      referencePlaceholder: "Заглавие на препратка",
      referenceTitle: "Описание на препратка",
      referenceCaption: "Контактни данни на препратка",
      addReference: "Добавяне на препратка",
      operation: "Операция / Снимки преди и след",
      operationTitle: "Заглавие на снимка",
      operationImage: "Снимка преди и след",
      addOperationImage: "Добавяне на снимка преди и след",
      professionTitle: "Специалност",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Доктор",
        },
        {
          name: "Spets. Dr.",
          value: "Специалист",
        },
        {
          name: "Prof. Dr.",
          value: "Професор доктор ",
        },
      ],
      professionSelect: [
        {
          name: "Лицева хирургия",
        },
        {
          name: "Оториноларингология",
        },
        {
          name: "Естетична и пластична хирургия",
        },
        {
          name: "Пластична, реконструктивна и естетична хирургия",
        },
        {
          name: "Затлъстяване",
        },
        {
          name: "Очна хирургия",
        },
        {
          name: "Орална, зъболекарска и челюстна хирургия",
        },
        {
          name: "Медицинска естетика",
        },
        {
          name: "Гинекология",
        },
      ],
      healthTourismFile: "Сертификат за здравен туризъм",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Условия за ползване",
      privacy: "Политика за поверителност",
    },
    dashboard: {
      message: "Съобщения",
      welcome: "Добре дошъл/Добре дошла!",
      more: "повече",
      medicalDetails: "Допълнителна медицинска информация",
      viewDetails: "Детайли",
      giveFeedback: "Оцени нас",
      whatAbout: "Как оценяваш YUMA Care?",
      thought: "Имаш ли идеи или предложения за нас?",
      signOut: "Излез",
    },
    message: {
      new: "Ново съобщение",
      message: "Съобщение",
      image: "Görsel",
      file: "Файл",
      video: "Видео",
      meetLink: "Запознайте се с връзката",
      doctorInvite: "Покана за лекар",
      meetText:
        "Здравей, изпратих ти покана за среща, моля, кликни, за да се присъединиш.",
      sendedFile: "Изпратен файл",
      doctorInviteText: "Изпратете покана за операция на Доктора.",
      doctorInviteMessage: "Здравейте, изпратих ви покана за операция",
    },
    operations: {
      title: "Операции",
      addOperation: "Добавяне на операция",
      viewDetails: "Покажи подробно",
      operationDetail: "Операция Подробно",
      more: "Още",
    },
    calendar: {
      title: "Календар",
      flightTime: "Полети",
      accomodation: "Настаняване",
      operations: "Операции",

      myReminder: "Моето напомняне",
      newOperation: "Нова операция",
      newFlight: "Нов полет",
      newHotel: "Ново настаняване",
      day: "Ден",
      week: "Седмица",
      month: "Луна",
      popup: {
        title: "Заглавие",
        note: "Забележка",
        save: "Запазване",
        operationDate: "Дата на операцията",
        operationTime: "Време за работа",
        doctor: "Доктор",
        user: "Потребител",
        selectOperation: "Изберете операция",
        selectDoctor: "Изберете лекар",
        selectUser: "Изберете потребител",
        addFile: "Добавяне на файл",
        uploadFile: "Изберете „Файл",
        departureDate: "Време е да тръгнем",
        departureTime: "Време на заминаване",
        returnDate: "Време е да се върнем",
        returnTime: "Часът на завръщането",
        region: "Регион",
        hotelName: "Име на хотела",
        checkIn: "Дата на вписване",
        checkInTime: "Време за влизане",
        checkOut: "Дата на издаване",
        checkOutTime: "Време за излизане",
      },
    },
    sideBar: {
      dashboard: "Панел",
      profile: "профил",
      message: "Съобщение",
      operations: "Операции",
      calendar: "Календар",
      search: "Търсене",
      credit: "Кредит",
      signOut: "Изход",
    },
    feedBack: {
      title: "Обратна връзка",
      subTitle: "",
      text: "Имате ли обратна връзка, която бихте искали да споделите?",
      success: "Успешно предадено.",
      send: "Изпращане",
    },
    search: {
      title: "лекарска операция",
      selectBody: "Избери област на тялото",
      selectOperation: "Избери операция",
      headArea: "Глава",
      upperArea: "Горна част на тялото",
      bottomArea: "Долна част на тялото",
      handArea: "Ръце",
      legArea: "Гръб",
      showDoctors: "Покажи ми лекари",
      price: "Цени",
      rating: "Отзивиs",
      filter: "Филтър",
      bodyArea: "Област на тялото",
      gender: "Пол",
      country: "Държава",
      city: "Град",

      surgeryDate: "Дата на операцията",
      welcome: "Добре дошли",
    },
    doctorDetail: {
      reward: "Награди",
      references: "Препоръки",
      sendMessage: "Изпрати съобщение",
      moreOperation: "Още",
    },
    profile: {
      changePassword: "cСмяна на паролата",
      emailNotification: "Уведомление по електронна поща",
      phoneNotification: "Известие по телефона",
      addPhoto: "Добавяне на снимка",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Задължително поле",
      nameshort: "Името е твърде късо",
      topicshort: "Относно е твърде късо",
      messageshort: "Съобщението е твърде късо",
      surnameshort: "Фамилията е твърде къса",
      correctmail: "Въведете валиден имейл адрес",
      correctphone: "Телефонният номер не е в правилния формат",
      correctpassword: "Паролата трябва да съдържа поне 8 знака",
      repeatpassword: "Паролите трябва да съвпадат",
      login: "Информацията за вход е неправилна",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
  cz: {
    landing: {
      menu: {
        contact: "Kontakt",
        terms: "Směrnice pro používání",
        privacy: "Směrnice o ochraně osobních údajů",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE je tvá platforma pro krásu a zdraví. Zaregistruj se nyní a využij všech výhod.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "Toto nás dělá jedinečnými.",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Vyhledávání lékařů",
          text: "Rychle a snadno najdi vhodného lékaře pro své potřeby",
        },
        {
          title: "Komunikace",
          text: "Spoj se s lékařem prostřednictvím videohovoru nebo chatu ve svém rodném jazyce",
        },
        {
          title: "Organizace",
          text: "Měj přehled o všech důležitých informacích a dokumentech",
        },
        {
          title: "Před a pooperační péče",
          text: "Zůstaň v kontaktu s lékařem ohledně všech potřebných kroků před a po zákroku",
        },
        {
          title: "Certifikovaní lékaři",
          text: "Nechej se operovat pouze certifikovanými lékaři",
        },
        {
          title: "Ochrana osobních údajů",
          text: "Ujisti se, že tvoje osobní údaje zůstanou pouze mezi tebou a tvým lékařem",
        },
        {
          title: "Zpětná vazba",
          text: "Využij zkušeností jiných",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Možnosti`,
        left: {
          desc: `Vyberte odpovídající symbol pro vaši požadovanou operaci`,
        },
        right: {
          desc: `Pomocí myši přejděte přes schéma a klikněte na požadovanou část těla.`,
        },
      },
      faq: {
        mainTitle: "Často kladené otázky",
        shortTitle: "FAQ",
        data: [
          {
            title: `Musím být registrován/a, abych mohl/mohla využívat YUMA CARE?`,
            desc: `Ano, k tomu, abyste mohli využívat naši platformu se všemi výhodami, je vyžadována registrace.`,
          },
          {
            title: `Jakým způsobem jsou mé údaje předávány lékaři?`,
            desc: `Všechna vaše data (informace, chat, dokumenty, obrázky) jsou předávána lékaři zašifrovaně, aby k nim neměly přístup třetí strany.`,
          },
          {
            title: `Jaká je péče na místě? `,
            desc: `Lékař a jeho tým, který jste si vybrali, zajistí, že se na místě nemusíte o nic starat. Od vašeho příjezdu až po odjezd budete doprovázeni a podporováni v každém kroku.`,
          },
          {
            title: `Existují určité kritéria, která musí lékař splňovat, aby se objevil na YUMA CARE?`,
            desc: `Ano, na YUMA CARE jsou zobrazeni pouze lékaři s konkrétní licencí. Tato povolení zajistí, že lékař může léčit pacienty ze zahraničí (v závislosti na konkrétní zemi).`,
          },
          {
            title: `Kdo může poskytnout zpětnou vazbu na lékaře?`,
            desc: `Přístup k formuláři pro zpětnou vazbu mají pouze pacienti registrovaní v YUMA CARE, kteří obdrželi léčbu od lékaře.`,
          },
          {
            title: `Jsou předběžná vyšetření v Německu povinná?`,
            desc: `To závisí na plánovaném zákroku. Lékař ti poskytne podrobné informace o tom, zda jsou vyžadována předchozí vyšetření a jaká jsou v osobním rozhovoru.`,
          },
          {
            title: `Mohu se spojit s několika lékaři najednu ohledně stejného dotazu?`,
            desc: `Využij možnost na YUMA CARE předložit svůj dotaz několika lékařům, aby ses nakonec rozhodl, u kterého lékaře se cítíš nejlépe.`,
          },
        ],
      },
      search: {
        date: "Datum",
      },
      contact: {
        title: "Kontkt",
        adress: "Adresa",
        phone: "Telefonní číslo",
        mail: "Emailová adresa",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Přihlášení",
    },
    register: {
      title: "Registrace",
      doctorRegister: " Registrace doktora",
      doctorInfo: "Informace o doktorovi",
      hospitalRegister: "Registrace v nemocnici",
      hospitalInfo: "Informace o nemocnici",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "E-mail",
      emailPlaceholder: "Tvoje e-mailová adresa",
      password: "Heslo",
      passwordPlaceholder: "Tvé heslo",
      remember: "Pamatovat přihlášení",
      forgotPassword: "Zapomenuté heslo?",
      username: "Uživatelské jméno",
      name: "Jméno",
      hospitalName: "Název nemocnice",
      surname: "Příjmení",
      phone: "Telefon",
      phonePlaceholder: "Tvůj telefonní číslo",
      birthday: "Datum narození",
      changePassword: "Změnit heslo",
      emailNotification: "Upozornění e-mailem",
      phoneNotification: "Mobile notifications",
      doYouHaveAccount: "Máš již účet? Přihlaš se!",
      subject: "Předmět",
      message: "Zpráva",
      send: "Odeslat",
      namePlaceholder: "Jméno",
      surnamePlaceholder: "Příjmení",
      phonePlaceholder: "Telefonní číslo",
      gender: "Pohlaví",
      man: "Muž",
      woman: "Žena",
      divers: "Různý",
      date: "Datum narození",
      region: "Země",
      regionPlaceholder: "Vyberte zemi",
      province: "Spolková země",
      provincePlaceholder: "Vyberte spolkovou zemi",
      district: "Město",
      districtPlaceholder: "Uveďte město",
      address: "Adresa",
      addressPlaceholder: "Uveďte svou adresu",
      zipcode: "PSČ",
      zipcodePlaceholder: "Uveďte své PSČ",
      passwordAgain: "Zopakujte heslo",
      title: "Titul",
      titlePlaceholder: "Vyberte svůj titul",
      workingPlace: "Pracoviště",
      workingPlacePlaceholder: "Uveďte své pracoviště",

      profileImage: "Profilový obrázek",
      cv: "O tobě",
      cvPlaceholer: "Představte se",
      reward: "Ocenění a/nebo certifikáty",
      captionPlaceholder: "Název ocenění a/nebo certifikátu",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      detailPlace: "Přidat ocenění a/nebo certifikáty",
      document: "Dokument",
      documentTitle: "Diplom",
      documentFile: "Soubor",
      addDocument: "Přidat dokument",
      documentTitle: " Certifikát o povolení k léčebné turistice",
      documentFile: "Soubor",
      addDocument: "Přidat dokument",
      categoryOperation: "Kategorie a operace",
      selectCategory: "Vyberte kategorii",
      newOperation: " Vytvořte operaci",
      selection: "Možnosti",
      operationDetail: "Popis operace",
      selectOperation: "Vyberte operaci",
      addOperation: "Přidat operaci",
      reference: "Reference",
      referencePlaceholder: "Název reference",
      referenceTitle: "Popis reference",
      referenceCaption: "Kontaktní údaje reference",
      addReference: "Přidat referenci",
      operation: " Operace / Obrázky před a po",
      operationTitle: "Název obrázku",
      operationImage: " Obrázek před a po",
      addOperationImage: " Přidat obrázek před a po",
      professionTitle: "Odborná oblast",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Doktor",
        },
        {
          name: "Spets. Dr.",
          value: "Specialista",
        },
        {
          name: "Prof. Dr.",
          value: "Profesor Doktor",
        },
      ],
      professionSelect: [
        {
          name: "Chirurgie obličejová",
        },
        {
          name: "Otorhinolaryngologie",
        },
        {
          name: "Estetická a plastická chirurgie",
        },
        {
          name: "Plastická, rekonstruktivní a estetická chirurgie",
        },
        {
          name: "Obezita",
        },
        {
          name: "Oční chirurgie",
        },
        {
          name: "Orální, dentální a čelistní chirurgie",
        },
        {
          name: "Lékařská estetika",
        },
        {
          name: "Gynekologie",
        },
      ],
      healthTourismFile: "Certifikát o povolení k léčebné turistice",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Podmínky použití",
      privacy: "Zásady ochrany osobních údajů",
    },
    dashboard: {
      message: "Zprávy",
      welcome: "Vítej",
      more: "More",
      medicalDetails: "Další zdravotní informace",
      viewDetails: "Podrobnosti",
      giveFeedback: "Ohodnoť nás",
      whatAbout: "Jak se ti líbí YUMA Care?",
      thought: "Máš pro nás nějaké nápady nebo připomínky?",
      signOut: "Odhlásit se",
    },
    message: {
      new: "Nová zpráva",
      message: "Zpráva",
      image: "Vizuální",
      file: "Soubor",
      video: "Video",
      meetLink: "Seznamte se Odkaz",
      doctorInvite: "Pozvánka pro lékaře",
      meetText:
        "Ahoj, poslal jsem ti žádost o schůzku, klikni, prosím, abys ses připojil.",
      sendedFile: "Soubor odeslán",
      doctorInviteText: "Odeslat pozvánku na operaci Doktor.",
      doctorInviteMessage: "Ahoj, poslal jsem ti žádost o operaci.",
    },
    operations: {
      title: "Operace",

      addOperation: "Přidat operaci",
      viewDetails: "Zobrazit detail",
      operationDetail: "Detail operace",
      more: "Více",
    },
    calendar: {
      title: "Kalendář",
      flightTime: "Lety",
      accomodation: "Ubytování",
      operations: "Operace",
      myReminder: "Moje připomínka",
      newOperation: "Nová operace",
      newFlight: "Nový let",
      newHotel: "Nové ubytování",
      day: "Den",
      week: "Týden",
      month: "Moon",
      popup: {
        title: "Title",
        note: "Ne",
        save: "uložit",
        operationDate: "Historie operace",
        operationTime: "Operace Hlídka",
        doctor: "Doctor",
        user: "Uživatel",
        selectOperation: "Vybrat operaci",
        selectDoctor: "Vyberte lékaře",
        selectUser: "Vyberte uživatele",
        addFile: "Přidat soubor",
        uploadFile: "Vybrat soubor",
        departureDate: "Gidiş Zamanı",
        departureTime: "Čas odejít",
        returnDate: "Čas odjezdu",
        returnTime: "Hodina návratu",
        region: "Region",
        hotelName: "Název hotelu",
        checkIn: "Datum vstupu",
        checkInTime: "Čas příjezdu",
        checkOut: "Datum vydání",
        checkOutTime: "Čas výstupu",
      },
    },
    sideBar: {
      dashboard: "Panel",
      profile: "Profil",
      message: "Zpráva",
      operations: "Operace",
      calendar: "Kalendář",
      search: "Vyhledávání",
      credit: "Úvěr",
      signOut: "Podívejte se na",
    },
    feedBack: {
      title: "Zpětná vazba",
      subTitle: "",
      text: "Máte nějakou zpětnou vazbu, o kterou byste se chtěli podělit?",
      success: "Úspěšně přeneseno.",
      send: "Odeslat",
    },
    search: {
      title: "Hledat",
      selectBody: "Vyber část těla",
      selectOperation: "Vyber operaci",
      headArea: "Hlava",
      upperArea: "Horní část těla",
      bottomArea: "Dolní část těla",
      handArea: "Paže",
      legArea: "Záda",
      showDoctors: "Ukaž mi lékaře",
      price: "Ceny",
      rating: "Hodnocení",
      filter: "Filtry",
      bodyArea: "Část těla",
      gender: "Pohlaví",
      country: "Země",
      city: "Město",
      surgeryDate: "Datum operace",
      welcome: "Vítejte na",
    },
    doctorDetail: {
      reward: "Ocenění",
      references: "Reference",
      sendMessage: "Odeslat zprávu",
      moreOperation: "Operace Overkill",
    },
    profile: {
      changePassword: "Změna hesla",
      emailNotification: "E-mailové oznámení",
      phoneNotification: "Telefonní oznámení",
      addPhoto: "Přidat fotografii",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Povinné pole",
      nameshort: "Jméno je příliš krátké",
      topicshort: "Předmět je příliš krátký",
      messageshort: "Zpráva je příliš krátká",
      surnameshort: "Příjmení je příliš krátké",
      correctmail: "Zadejte platnou e-mailovou adresu",
      correctphone: "Telefonní číslo není ve správném formátu",
      correctpassword: "Heslo musí obsahovat minimálně 8 znaků",
      repeatpassword: "Hesla se musí shodovat",
      login: "Přihlašovací údaje jsou nesprávné",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
  niedl: {
    landing: {
      menu: {
        contact: "Contact",
        terms: "Gebruiksvoorwaarden",
        privacy: "Privacybeleid",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE is jouw platform als het gaat om schoonheid en gezondheid. Registreer je nu om te profiteren van alle voordelen.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "Dat maakt ons uniek.",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Artsen zoeken",
          text: "Vind snel en eenvoudig de juiste arts voor jouw behoeften",
        },
        {
          title: "Communicatie",
          text: "Neem contact op met de arts via videoconsult of chat in je moedertaal",
        },
        {
          title: "Organisatie",
          text: "Houd alle belangrijke informatie en documenten overzichtelijk",
        },
        {
          title: "Voor- en nazorg",
          text: "Blijf in contact met de arts voor alle noodzakelijke stappen vóór en na je ingreep",
        },
        {
          title: "Gecertificeerde artsen",
          text: "Laat je alleen opereren door gecertificeerde artsen",
        },
        {
          title: "Privacy",
          text: "Zorg ervoor dat je persoonlijke gegevens alleen tussen jou en je arts blijven",
        },
        {
          title: "Feedback",
          text: "Profiteer van de ervaringen van anderen",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
      },
      operationDetail: {
        mainTitle: `Opties`,
        left: {
          desc: `Kies het passende symbool voor de gewenste operatie`,
        },
        right: {
          desc: `Beweeg met de muis over het diagram en klik op het gewenste lichaamsgebied`,
        },
      },
      faq: {
        mainTitle: "Veelgestelde vragen",
        shortTitle: "VV",
        data: [
          {
            title: `Moet ik geregistreerd zijn om YUMA CARE te kunnen gebruiken?`,
            desc: `Ja, registratie is vereist om ons platform met alle voordelen te kunnen gebruiken.`,
          },
          {
            title: `Hoe worden mijn gegevens aan de arts doorgegeven?`,
            desc: `Al jouw gegevens (informatie, chat, documenten, afbeeldingen) worden versleuteld naar de arts verzonden, zodat derden er geen toegang toe hebben.`,
          },
          {
            title: `Hoe is de begeleiding ter plaatse? `,
            desc: ` De door jou gekozen arts en zijn team zorgen ervoor dat je ter plaatse nergens naar hoeft om te kijken. Vanaf je aankomst tot vertrek word je in elke stap begeleid en ondersteund. Bij grotere operaties of langere verblijven raden we echter nog steeds aan om een begeleider mee te nemen.`,
          },
          {
            title: `Zijn er specifieke criteria waaraan een arts moet voldoen om op YUMA CARE te verschijnen?`,
            desc: `Ja, alleen artsen met een specifieke licentie worden getoond op YUMA CARE. Deze goedkeuring zorgt ervoor dat de arts patiënten uit het buitenland mag behandelen (afhankelijk van het land).`,
          },
          {
            title: `Wie kan feedback geven over de arts?`,
            desc: `Alleen patiënten die geregistreerd zijn bij YUMA CARE en een behandeling hebben ontvangen van de arts krijgen toegang tot het feedback-formulier.`,
          },
          {
            title: `Zijn vooronderzoeken vereist?`,
            desc: `Dit hangt af van de geplande behandeling. De arts zal je tijdens het persoonlijke gesprek gedetailleerde informatie geven over welke vooronderzoeken nodig zijn.`,
          },
          {
            title: `Kan ik met meerdere artsen contact opnemen over dezelfde kwestie?`,
            desc: `Maak gerust gebruik van de mogelijkheid op YUMA CARE om jouw vraag bij meerdere artsen neer te leggen, zodat je uiteindelijk kunt beslissen bij welke arts je je het meest op je gemak voelt.`,
          },
        ],
      },
      search: {
        date: "Date",
      },
      contact: {
        title: "Contact",
        adress: "Adres",
        phone: "Telefoonnummer",
        mail: "E-mailadres",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "loggen",
    },
    register: {
      title: "Registratie",
      doctorRegister: "Doktor-Registratie",
      doctorInfo: "Doktorsinformatie",
      hospitalRegister: "Hastane Kayıt",
      hospitalInfo: "Hastane Bilgisi",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "E-mail",
      emailPlaceholder: "Jouw e-mailadres",
      password: "Wachtwoord",
      passwordPlaceholder: "Jouw wachtwoord",
      remember: "Aangemeld blijven",
      forgotPassword: "Wachtwoord vergeten?",
      username: "Gebruikersnaam",
      name: "Voornaam",
      hospitalName: "Hastane İsmi",
      surname: "Achternaam",
      phone: "Telefoon",
      phonePlaceholder: "Jouw telefoonnummer",
      birthday: "Geboortedatum",
      changePassword: "Wachtwoord wijzigen",
      emailNotification: "Meldingen via e-mail",
      phoneNotification: "Meldingen op je mobile",
      doYouHaveAccount: "Heb je al een account? Meld je nu aan!",
      subject: "Onderwerp",
      message: "Bericht",
      send: "Verzenden",
      namePlaceholder: " Voornaam",
      surnamePlaceholder: "Achternaam",
      phonePlaceholder: "Telefoonnummer",
      gender: "Geslacht",
      man: "Mannelijk",
      woman: "Vrouwelijk",
      divers: "Divers",
      date: "Geboortedatum",
      region: "Land",
      regionPlaceholder: "Selecteer een land",
      province: "Provincie",
      provincePlaceholder: "Selecteer een provincie",
      district: "Stad",
      districtPlaceholder: "Voer een stad in",
      address: "Adres",
      addressPlaceholder: "Geef je adres op",
      zipcode: "Postcode",
      zipcodePlaceholder: "Voer je postcode in",
      passwordAgain: "Herhaal wachtwoord",
      title: "Titel",
      titlePlaceholder: "Kies je titel",
      workingPlace: "Werkplek",
      workingPlacePlaceholder: "Voer je werkplek in",

      profileImage: "Profielfoto",
      cv: "Over jou",
      cvPlaceholer: "Stel jezelf voor",
      reward: "Onderscheidingen en/of Certificaten",
      captionPlaceholder: "Titel van onderscheiding en/of certificaat",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      detailPlace: "Onderscheidingen en/of certificaten toevoegen",
      document: "Document",
      documentTitle: "Diploma",
      documentFile: "Bestand",
      addDocument: "Document toevoegen",
      documentTitle: "Toestemmingscertificaat voor gezondheidstoerisme",
      documentFile: "Bestand",
      addDocument: "Document toevoegen",
      categoryOperation: "Categorie & Operatie",
      selectCategory: "Kies een categorie",
      newOperation: "Maak een operatie aan",
      selection: "Opties",
      operationDetail: "Omschrijving van de operatie",
      selectOperation: "Kies een operatie",
      addOperation: "Operatie toevoegen",
      reference: "Referentie",
      referencePlaceholder: "Titel van referentie",
      referenceTitle: "Omschrijving van referentie",
      referenceCaption: "Contactgegevens van referentie",
      addReference: "Referentie toevoegen",
      operation: "Operatie / Voor en Na Foto`s",
      operationTitle: "Titel van de foto",
      operationImage: "Voor en Na Foto",
      addOperationImage: "Voeg Voor en Na Foto toe",
      professionTitle: "Specialisatiegebied",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Dokter",
        },
        {
          name: "Med. Spec.",
          value: "Medisch Specialist",
        },
        {
          name: "Prof. Dr.",
          value: "Hoogleraar",
        },
      ],
      professionSelect: [
        {
          name: "Gezichtschirurgie",
        },
        {
          name: "Otorhinolaryngologie",
        },
        {
          name: "Esthetische en plastische chirurgie",
        },
        {
          name: "Plastische, reconstructieve en esthetische chirurgie",
        },
        {
          name: "Obesitas",
        },
        {
          name: "Oogchirurgie",
        },
        {
          name: "Orale, tandheelkundige en kaakchirurgie",
        },
        {
          name: "Medische esthetiek",
        },
        {
          name: "Gynaecologie",
        },
      ],
      healthTourismFile: "Toestemmingscertificaat voor gezondheidstoerisme",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Gebruiksvoorwaarden",
      privacy: "Privacybeleid",
    },
    dashboard: {
      message: "Berichten",
      welcome: "Welkom",
      more: "Meer",
      medicalDetails: "Medische informatie",
      viewDetails: "Details",
      giveFeedback: "Beoordeel ons",
      whatAbout: "Hoe vind je YUMA Care?",
      thought: "Heb je ideeën of suggesties voor ons?",
      signOut: "Afmelden",
    },
    message: {
      new: "Nieuw bericht",
      message: "Mesaj",
      image: "Görsel",
      file: "Dosya",
      video: "Video",
      meetLink: "Meet Linki",
      doctorInvite: "Doktor Daveti",
      meetText: "Hey, sana meet isteği yolladım, katılmak için lütfen tıkla.",
      sendedFile: "Dosya Gönderdi",
      doctorInviteText: "Doctor'a operasyon daveti gönderin.",
      doctorInviteMessage: "Hey, sana operasyon isteği yolladım.",
    },
    operations: {
      title: "Operaties",

      addOperation: "Operasyon Ekle",
      viewDetails: "Toon details",
      operationDetail: "Operasyon Detay",
      more: "Daha Fazla",
    },
    calendar: {
      title: "Kalender",
      flightTime: "Vluchten",
      accomodation: "Accommodatie",
      operations: "Operaties",
      myReminder: "Hatırlatıcım",
      newOperation: "Yeni Operasyon",
      newFlight: "Yeni Uçuş",
      newHotel: "Yeni Konaklama",
      day: "Gün",
      week: "Hafta",
      month: "Ay",
      popup: {
        title: "Başlık",
        note: "Not",
        save: "Kaydet",
        operationDate: "Operasyon Tarihi",
        operationTime: "Operasyon Saati",
        doctor: "Doktor",
        user: "Kullanıcı",
        selectOperation: "Operasyon Seçiniz",
        selectDoctor: "Doktor Seçiniz",
        selectUser: "Kullanıcı Seçiniz",
        addFile: "Dosya Ekle",
        uploadFile: "Dosya Seç",
        departureDate: "Gidiş Zamanı",
        departureTime: "Gidiş Saati",
        returnDate: "Dönüş Zamanı",
        returnTime: "Dönüş Saati",
        region: "Bölge",
        hotelName: "Otel Adı",
        checkIn: "Giriş Tarihi",
        checkInTime: "Giriş Saati",
        checkOut: "Çıkış Tarihi",
        checkOutTime: "Çıkış Saati",
      },
    },
    sideBar: {
      dashboard: "Panel",
      profile: "Profil",
      message: "Mesaj",
      operations: "Operasyonlar",
      calendar: "Takvim",
      search: "Arama",
      credit: "Kredi",
      signOut: "Çıkış Yap",
    },
    feedBack: {
      title: "Feedback",
      subTitle: "Wat denk je van ons?",
      text: "Heb je enige feedback die je wilt delen?",
      success: "Başarıyla iletildi.",
      send: "Gönder",
    },
    search: {
      title: "Arts, Operatie",
      selectBody: "Kies een lichaamsgebied",
      selectOperation: "Kies een operatie",
      headArea: "Hoofd",
      upperArea: "Bovenlichaam",
      bottomArea: "Onderlichaam",
      handArea: "Armen",
      legArea: "Rug",
      showDoctors: "Toon mij artsen",
      price: "Prijzen",
      rating: "Beoordelingen",
      filter: "Filter",
      bodyArea: "Lichaamsgebied",
      gender: "Geslacht",
      country: "Land",
      city: "Stad",
      surgeryDate: "Operasyon Tarihi",
      welcome: "Hoşgeldin",
    },
    doctorDetail: {
      reward: "Onderscheidingen",
      references: "Referenties",
      sendMessage: "Bericht versturen",
      moreOperation: "Fazla Operasyon",
    },
    profile: {
      changePassword: "Change Password",
      emailNotification: "E-Mail Notification",
      phoneNotification: "Phone Notification",
      addPhoto: "Afbeelding toevoegen",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Verplicht veld",
      nameshort: "Naam is te kort",
      topicshort: "Onderwerp is te kort",
      messageshort: "Bericht is te kort",
      surnameshort: "Achternaam is te kort",
      correctmail: "Voer een geldig e-mailadres in",
      correctphone: "Telefoonnummer is niet in het juiste formaat",
      correctpassword: "Wachtwoord moet minimaal 8 tekens bevatten",
      repeatpassword: "Wachtwoorden moeten overeenkomen",
      login: "Inloggegevens zijn onjuist",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
  fr: {
    landing: {
      menu: {
        contact: "Contact",
        terms: "Conditions d´utilisation",
        privacy: "Politique de confidentialité",
      },
      head: {
        title: "feel comfortable",
        text: `YUMA CARE est ta plateforme dédiée à la beauté et à la santé. Inscris-toi dès maintenant pour profiter de tous les avantages.`,
        title2: "Aesthetic",
        seeDoctors: "SEE +200 DOCTOR",
        contact: "Contact with us",
      },
      infoPartTitle: "C`est ce qui nous rend uniques",
      infoPartSubtitle: "YUMACARE AYRICALIĞI İLE",
      infoPart: [
        {
          title: "Recherche de médecins",
          text: "Trouve rapidement et facilement le médecin adapté à tes besoins",
        },
        {
          title: "Communication",
          text: "Entre en contact avec le médecin par visioconsultation ou chat dans ta langue maternelle",
        },
        {
          title: "Organisation",
          text: "Garde une vue d`ensemble de toutes les informations et documents importants",
        },
        {
          title: "Pré et post-opératoire",
          text: "Reste en contact avec le médecin pour toutes les étapes nécessaires avant et après ton intervention",
        },
        {
          title: "Médecins certifiés",
          text: "Faites-vous opérer uniquement par des médecins certifiés",
        },
        {
          title: "Protection des données",
          text: "Assurez-vous que vos données personnelles restent confidentielles entre vous et votre médecin",
        },
        {
          title: "Retours d´expérience",
          text: "Bénéficiez de l´expérience des autres",
        },
      ],
      featuredDoctor: {
        selectOperation: "Auswahl der Operation",
        mainTitle: "Unsere Ärzte",
        card1: {
          title: "Finanziere deine Wünsche",
          desc: "Informiere dich über mögliche Finanzierungsmöglichkeiten um nicht länger auf die Erfüllung deines Traumes warten zu müssen",
        },
        card2: {
          title: `Finde den passenden Spezialisten`,
          desc: `Wähle den Arzt aus, der dir bei der Verwirklichung deines Wunsches hilft `,
        },
        card3: {
          title: `Kommuniziere ohne Sprachbarriere `,
          desc: `Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt`,
        },
        card4: {
          title: `Profitiere aus den Erfahrungen Anderer `,
          desc: `Durch das Feedback anderer Patienten erhältst du reale Rückmeldungen zur Arbeit des Arztes`,
        },
        subTitle: "ARADIĞINIZ TÜM OPERASYONLAR",
        doctorNumber: "+ 200 Doktoru",
        doctorText: "görmek için tıklayın",
        viewDetails: "View Details",
        operation: "Operation",
      },
      operationDetail: {
        mainTitle: `Options`,
        left: {
          desc: `Sélectionnez le symbole approprié pour l'opération de votre choix`,
        },
        right: {
          desc: `Passez la souris sur le schéma et cliquez sur la partie du corps souhaitée`,
        },
      },
      faq: {
        mainTitle: "Foire aux questions",
        shortTitle: "FAQ",
        data: [
          {
            title: `Dois-je être enregistré pour pouvoir utiliser YUMA CARE?`,
            desc: `Oui, l'inscription est nécessaire pour pouvoir profiter de toutes les fonctionnalités de notre plateforme.`,
          },
          {
            title: `Comment mes données sont-elles transmises au médecin?`,
            desc: `Toutes tes données (informations, chat, documents, images) sont transmises de manière cryptée au médecin afin que des tiers n'y aient pas accès.`,
          },
          {
            title: `Comment se déroule l'accompagnement sur place? `,
            desc: `Le médecin et son équipe que tu as choisis veillent à ce que tu n'aies pas à te soucier de quoi que ce soit sur place. De ton arrivée à ton départ, tu seras accompagné et soutenu à chaque étape. Cependant, pour les opérations importantes ou les séjours prolongés, nous recommandons toujours d'être accompagné d'une personne de confiance.`,
          },
          {
            title: `Y a-t-il des critères spécifiques que le médecin doit remplir pour apparaître sur YUMA CARE?`,
            desc: `Oui, seuls les médecins titulaires d'une licence spécifique sont affichés sur YUMA CARE. Cette autorisation garantit que le médecin peut traiter des patients étrangers (selon le pays).`,
          },
          {
            title: `Qui peut donner un avis sur le médecin?`,
            desc: `Seuls les patients enregistrés auprès de YUMA CARE et ayant reçu un traitement médical par un médecin auront accès au formulaire de rétroaction.`,
          },
          {
            title: `Des examens préliminaires sont-ils nécessaires?`,
            desc: `Cela dépend du traitement prévu. Le médecin te donnera des informations précises lors de l'entretien personnel sur les examens préliminaires éventuellement nécessaires.`,
          },
          {
            title: `Puis-j´entre en contact avec plusieurs médecins pour la même demande?`,
            desc: `N'hésite pas à profiter de la possibilité offerte par YUMA CARE de soumettre ta demande à plusieurs médecins afin de décider définitivement avec lequel tu te sens le plus à l'aise.`,
          },
        ],
      },
      search: {
        date: "Date",
      },
      contact: {
        title: "Contact",
        adress: "Adresse",
        phone: "Numéro de téléphone",
        mail: "Adresse e-mail",
        social: {
          facebook: "test",
          twitter: "test",
          instagram: "test",
        },
        dataOwner: "Veri Sahibi Başvuru Formu",
      },
      stories: {
        title: "Gerçek Hikayeler",
        subTitle: "YUMA CARE ile",
        cards: [
          {
            name: "Ilaria",
            job: "Actor",
            title: "Kendimi daha iyi hissediyorum!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Ilaria,
          },
          {
            name: "Branko",
            job: "Fitness Coach",
            title: "Saçlarıma Bayılıyorum",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Branko,
          },
          {
            name: "Sulo",
            job: "Developer",
            title: "Dişlerim çok daha iyi!",
            text: `Durch meine Magenverkleinerung habe ich nicht nur mein Gewicht
              reduziert, sondern auch meine Lebensqualität und Gesundheit
              deutlich verbessert. Ich fühle mich großartig und freue mich auf
              ein gesünderes und glücklicheres Leben!`,
            image: Sulo,
          },
        ],
      },
      yusufMaria: {
        title: "YUSUF & MARIA",
        subTitle: "YUMACARE KURUCULARI",
        yusuf: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
        maria: {
          title: "We Create",
          title2: "Aesthetic",
          text: `Es ist für mich eine Herzensangelegenheit, unseren Usern dabei zu
              helfen, ihr Selbstbewusstsein zu stärken und ihr ästhetisches
              Empfinden zu verbessern. Wir stehen für Professionalität,
              Sicherheit und individuelle Beratung, um jedem Kunden ein
              maßgeschneidertes und zufriedenstellendes Ergebnis zu ermöglichen.
              Mit unserem engagierten Team und einem breiten Spektrum an
              Behandlungsoptionen sind wir stolz darauf, unseren Beitrag zur
              Steigerung des Wohlbefindens und Selbstwertgefühls unserer Kunden
              leisten zu können`,
        },
      },
      blog: {
        title: "Blog Yazıları",
        subtitle: "YUMACARE",
        readMore: "Yakında",
        blogs: [
          {
            title:
              "Schönheit und Selbstbewusstsein: Warum immer mehr Menschen sich für Schönheitsoperationen in der Türkei entscheiden",
            text: "In den letzten Jahren hat sich die Türkei zu einem beliebten Ziel für Schönheitsoperationen entwickelt. Immer mehr Menschen aus aller Welt reisen in dieses faszinierende Land, um sich ihren ästhetischen Wünschen zu ...",
            person: "Nilhan Kurter",
            date: "02.06.2024",
          },
          {
            title:
              "BBL-Operation: Ein umfassender Leitfaden zur Brazilian Butt Lift",
            text: "In den letzten Jahren hat die Nachfrage nach ästhetischen Eingriffen stark zugenommen, und eine der beliebtesten Operationen ist der Brazilian Butt Lift (BBL). Dieser Eingriff erfreut sich vor allem bei Frauen großer...",
            person: "İpek Çengel",
            date: "23.06.2024",
          },
          {
            title: "Schönheitsoperationen bei Männern: Ein wachsender Trend",
            text: "In den letzten Jahren hat sich das Schönheitsideal nicht nur bei Frauen, sondern auch bei Männern stark gewandelt. Immer mehr Männer entscheiden sich dafür, Schönheitsoperationen in Anspruch zu nehmen, um ihr äußeres...",
            person: "Yusuf Aslan",
            date: "05.07.2024",
          },
        ],
      },
    },
    forgotPassword: {
      mainTitle: "Forgot Password",
      changePassword: "Change Password",
      checkEmail: `Check your e-mail`,
      sendEmail: "Send Email",
      anyProblem: `Did not receive the email? Check your spam folder or`,
      anyProblem1: `try another email address.`,
      emailText: `*Enter the email associated with your account and we'll
                        send an email instruction to reset your password.`,
      sendedEmail: `*We have send a password revocery insturection to your registered
              e mail.`,
      successChange: "Successfully Change",
      errorChange: "Şifre değişikliğinde bir hata oluştu",
      errorMail: "Mail iletilirken bir hata oluştu",
    },
    login: {
      title: "Connexion",
    },
    register: {
      title: "nscription",
      doctorRegister: "Inscription du docteur",
      doctorInfo: "Informations du docteur",
      hospitalRegister: "Registre des hôpitaux",
      hospitalInfo: "Informations sur l'hôpital",
      backButton: "Bir Önceki Adım",
      step1: {
        caption: "Call",
        title: "What Should We",
        title2: "Call You?",
      },
      step2: {
        caption: "Operation",
        title: "Which",
        title2: "Operations Are You",
        title3: "Considering?",
      },
      step3: {
        caption: "Contact",
        title: "How to",
        title2: "Contact",
        title3: "You?",
      },
      step4: {
        caption: "",
        title: "I'll be in touch",
        title2: "Doctor",
        title3: "you want to choose?",
      },
      step5: {
        caption: "Profile",
        title: "Complete",
        title2: "Your Profile",
      },
      selected: "Seçildi",
      viewDetails: "View Details",
      searchOperation: "Operasyon ara",
      continue: "Devam",
      skip: "Atla",
      doctorregistertitle: "If you are a doctor, continue here ",
      doctorregisterbutton: "Registration",
    },
    form: {
      uyelikSozlesmesi: "Üyelik Sözleşmesi",
      chatOncesiAydinlatma: "Chat Öncesi Aydınlatma Metni",
      chatOncesiRiza: "Chat Öncesi Açık Rıza Metni",
      uyelikRiza: "Üyelik Açık Rıza Metni",
      uyelikAydinlatma: "Üyelik Aydınlatma Metni",
      cerez: "Çerez Politikası",
      isOrtakligi: "İş Ortaklığı Sözleşmesi",
      acceptAll: "Tümünü Kabul Et",
      email: "E-mail",
      emailPlaceholder: "Ton adresse e-mail",
      password: "Mot de passe",
      passwordPlaceholder: "Ton mot de passe",
      remember: "Rester connecté(e)",
      forgotPassword: "Mot de passe oublié?",
      username: "Nom d`utilisateur",
      name: "Prénom",
      hospitalName: "Nom de l'hôpital",
      surname: "Nom de famille",
      phone: "Téléphone",
      phonePlaceholder: "Ton numéro de téléphone",
      birthday: "Date de naissance",
      changePassword: "Changer de mot de passe",
      emailNotification: "Notifications par e-mail",
      phoneNotification: "Notifications sur téléphone portable",
      doYouHaveAccount: "Tu as déjà un compte? Connecte-toi maintenant!",
      subject: "Sujet",
      message: "Message",
      send: "Envoyer",
      namePlaceholder: "Prénom",
      surnamePlaceholder: "Nom de famille",
      phonePlaceholder: "Numéro de téléphone",
      gender: "Sexe",
      man: "Masculin",
      woman: "Féminin",
      divers: "Divers",
      date: "Date de naissance",
      region: "Pays",
      regionPlaceholder: "Choisissez un pays",
      province: "État fédéral",
      provincePlaceholder: "Choisissez un État fédéral",
      district: "Ville",
      districtPlaceholder: "Indiquez une ville",
      address: "Adresse",
      addressPlaceholder: "Indiquez votre adresse",
      zipcode: "Code postal",
      zipcodePlaceholder: "Indiquez votre code postal",
      passwordAgain: "Répéter le mot de passe",
      title: "Titre",
      titlePlaceholder: "Choisissez votre titre",
      workingPlace: "Lieu de travail",
      workingPlacePlaceholder: "Indiquez votre lieu de travail",

      profileImage: "Photo de profil",
      cv: "À propos de vous",
      cvPlaceholer: "Présentez-vous",
      reward: "Récompenses et/ou certificats",
      captionPlaceholder: "Titre de la récompense et/ou du certificat",
      detailPlaceholder: "Details",
      addReward: "Add rewards/certificates",
      detailPlace: "Ajouter des récompenses et/ou des certificats",
      document: "Document",
      documentTitle: "Diplôme",
      documentFile: "Fichier",
      addDocument: "Ajouter un document",
      documentTitle: "Certificat d´approbation pour le tourisme médical",
      documentFile: "Fichier",
      addDocument: "Ajouter un document",
      categoryOperation: "Catégorie et opération",
      selectCategory: "Choisissez une catégorie",
      newOperation: "Créez une opération",
      selection: "Options",
      operationDetail: "Description de l´opération",
      selectOperation: "Choisissez une opération",
      addOperation: "Ajouter une opération",
      reference: "Référence",
      referencePlaceholder: "Titre de référence",
      referenceTitle: "Description de la référence",
      referenceCaption: "Coordonnées de référence",
      addReference: "Ajouter une référence",
      operation: "Opération / Photos avant-après",
      operationTitle: "Titre de l´image",
      operationImage: "Photo avant-après",
      addOperationImage: "Ajouter une photo avant-après",
      professionTitle: "Domaine d`expertise",

      doctorTitle: [
        {
          name: "Dr.",
          value: "Docteur",
        },
        {
          name: "Med. Spécialiste",
          value: "Médecin Spécialiste",
        },
        {
          name: "Prof. Dr.",
          value: "Professeur Doktor",
        },
      ],
      professionSelect: [
        {
          name: "Chirurgie faciale",
        },
        {
          name: "Otorhinolaryngologie",
        },
        {
          name: "Chirurgie esthétique et plastique",
        },
        {
          name: "Chirurgie plastique, reconstructive et esthétique",
        },
        {
          name: "Obésité",
        },
        {
          name: "Chirurgie oculaire",
        },
        {
          name: "Chirurgie buccale, dentaire et maxillo-faciale",
        },
        {
          name: "Esthétique médicale",
        },
        {
          name: "Gynécologie",
        },
      ],
      healthTourismFile: "Certificat d`approbation pour le tourisme médical",
      rewardFile: "Ödül Sertifikası Dosyası",
    },
    footer: {
      terms: "Conditions d`utilisation",
      privacy: "Politique de confidentialité",
    },
    dashboard: {
      message: "Messages",
      welcome: "Bienvenue",
      more: "Plus",
      medicalDetails: "Informations médicales",
      viewDetails: "Détails",
      giveFeedback: "Évalue-nous",
      whatAbout: "Comment trouves-tu YUMA Care?",
      thought: "As-tu des idées ou des suggestions pour nous?",
      signOut: "Se déconnecter",
    },
    message: {
      new: "Nouveau message",
      message: "Message",
      image: "Visuel",
      file: "Fichier",
      video: "Vidéo",
      meetLink: "Lien de rencontre",
      doctorInvite: "Invitation du médecin",
      meetText:
        " Hey, je vous ai envoyé une demande de rencontre, veuillez cliquer pour la rejoindre",
      sendedFile: "Fichier envoyé",
      doctorInviteText: "Envoyer une invitation d'opération au docteur",
      doctorInviteMessage: "Hé, je vous ai envoyé une demande d'opération",
    },
    operations: {
      title: "Opérations",
      addOperation: "Ajouter une opération",
      viewDetails: "Afficher les détails",
      operationDetail: "Détail de l'opération",
      more: "Plus",
    },
    calendar: {
      title: "Calendrier",
      flightTime: "Vols",
      accomodation: "Hébergement",
      operations: "Opérations",
      myReminder: "Mon rappel",
      newOperation: "Nouvelle opération ",
      newFlight: "Nouveau vol",
      newHotel: "Nouvel hébergement",
      day: "Jour",
      week: "Semaine",
      month: "Lune",
      popup: {
        title: "Titre",
        note: "Pas",
        save: "Save",
        operationDate: "Date de l'opération",
        operationTime: "Heure de l'opération",
        doctor: "Docteur",
        user: "Utilisateur",
        selectOperation: "Sélectionner l'opération",
        selectDoctor: "Sélectionnez un médecin",
        selectUser: "Sélectionner l'utilisateur",
        addFile: "Ajouter un fichier",
        uploadFile: "Sélectionner un fichier",
        departureDate: "Heure de départ",
        departureTime: "Heure de départ",
        returnDate: "Heure de retour",
        returnTime: "Heure de retour",
        region: "Région",
        hotelName: "Nom de l'hôtel",
        checkIn: "Date d'arrivée",
        checkInTime: "Heure de connexion",
        checkOut: "Date de sortie",
        checkOutTime: "Heure de sortie",
      },
    },
    sideBar: {
      dashboard: "Dashboard",
      profile: "Profil",
      message: "Message",
      operations: "Opérations",
      calendar: "Calendrier",
      search: "Recherche",
      credit: "Credit",
      signOut: "Vérifier",
    },
    feedBack: {
      title: "Feedback",
      subTitle: "",
      text: "Avez-vous des commentaires à formuler ?",
      success: "Transmis avec succès",
      send: "Envoyer",
    },
    search: {
      title: "Médecin, Opération",
      selectBody: "Choisis une partie du corps",
      selectOperation: "Choisis une opération",
      headArea: "Tête",
      upperArea: "Haut du corps",
      bottomArea: "Bas du corps",
      handArea: "Bras",
      legArea: "Dos",
      showDoctors: "Montre-moi les Médecins",
      price: "Prix",
      rating: "Évaluations",
      filter: "Filtre",
      bodyArea: "Partie du corps",
      gender: "Genre",
      country: "Pays",
      city: "Ville",
      surgeryDate: "Date de l'opération",
      welcome: "Bienvenue",
    },
    doctorDetail: {
      reward: "Récompenses",
      references: "Références",
      sendMessage: "Envoyer un message",
      moreOperation: "Opération Overkill",
    },
    profile: {
      changePassword: "Changer de mot de passe",
      emailNotification: "Notification par courrier électronique",
      phoneNotification: "Notification par téléphone",
      addPhoto: "Ajouter une photo",
      doctorProfile: "Doctor Profile",
      userProfile: "User Profile",
    },
    errors: {
      mandatoryfield: "Champ obligatoire",
      nameshort: "Le nom est trop court",
      topicshort: "Le sujet est trop court",
      messageshort: "Le message est trop court",
      surnameshort: "Le nom de famille est trop court",
      correctmail: "Veuillez saisir une adresse e-mail valide",
      correctphone: "Le numéro de téléphone n'est pas au bon format",
      correctpassword: "Le mot de passe doit comporter au moins 8 caractères",
      repeatpassword: "Les mots de passe doivent correspondre",
      login: "Les informations de connexion sont incorrectes",
      uyelikSozlesmesiError: "Lütfen üyelik sözleşmesini onaylayın",
      chatOncesiRizaError: "Lütfen chat öncesi rıza onaylayın",
      chatOncesiAydinlatmaError: "Lütfen chat öncesi aydınlatma onaylayın",
      uyelikAydinlatmaError: "Lütfen üyelik aydınlatma onaylayın",
      uyelikRizaError: "Lütfen  üyelik rıza onaylayın",
      cerezError: "Lütfen çerez onaylayın",
      isOrtakligiError: "Lütfen iş ortaklığı onaylayın",
    },
  },
};

// DEUTCH
//ENGLISH
//ESPONAL
//ITALY
//TÜRKÇE
//SIRPÇA
//BULGARCA
//ÇEK
//HOLLANDA
//FRANSIZCA
