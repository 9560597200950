import TitleWrapper from "../TitleWrapper/TitleWrapper";
import Branko from "../../../assets/img/stories/branko.jpg";
import Ilaria from "../../../assets/img/stories/ilaria.jpg";
import Sulo from "../../../assets/img/stories/sulo.jpg";
import style from "./stories.module.scss";
import { LangData } from "../../../data/LangData";
import { useState } from "react";

const Stories = ({ lang }) => {
  const [selected, setSelected] = useState(0);
  return (
    <div className={style.wrapper}>
      <TitleWrapper
        title={LangData[lang].landing.stories.title}
        subTitle={LangData[lang].landing.stories.subTitle}
      />
      <div className={style.cardWrapper}>
        <div className={style.card}>
          <img
            key={LangData[lang].landing.stories.cards[selected].name}
            src={LangData[lang].landing.stories.cards[selected].image}
          />
          <div className={style.title}>
            <h5>{LangData[lang].landing.stories.cards[selected].name}</h5>
            <h6>{LangData[lang].landing.stories.cards[selected].job}</h6>
          </div>
        </div>
        <div className={style.textWrapper}>
          <div className={style.text}>
            <h4>{LangData[lang].landing.stories.cards[selected].title}</h4>
            <p>{LangData[lang].landing.stories.cards[selected].text}</p>
          </div>
          <div className={style.buttonWrapper}>
            {LangData[lang].landing.stories.cards.map((item, index) => {
              return (
                <>
                  {index !== selected ? (
                    <button onClick={() => setSelected(index)}>
                      <img src={item.image} />
                      <p>{item.title}</p>
                    </button>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
