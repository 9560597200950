import style from "./contentpopup.module.scss";

const ContentPopup = ({ closeClick, type, url }) => {
  return (
    <div className={style.contentPopup}>
      <button onClick={closeClick} className={style.close}>
        X
      </button>
      {type === "video" ? (
        <video autoPlay muted controls>
          <source src={url} type="video/mp4" />
        </video>
      ) : (
        <img src={url} />
      )}
    </div>
  );
};

export default ContentPopup;
