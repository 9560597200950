import style from "./calendar.module.scss";
import { CalendarArrow } from "../../../assets/icon";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useMemo, useState, useEffect } from "react";
import axios from "axios";
import { ApiRoutes } from "../../../utils/ApiRoutes";
import moment from "moment";

const HeadPart = ({ month, leftClick, rightClick }) => {
  return (
    <div className={style.headPart}>
      <button onClick={leftClick} className={style.left}>
        <CalendarArrow />
      </button>
      <p>{month}</p>
      <button onClick={rightClick} className={style.right}>
        <CalendarArrow />
      </button>
    </div>
  );
};

const WeekPart = ({ week }) => {
  return (
    <div className={style.weekWrapper}>
      {week.map((day, index) => (
        <div className={style.weekRow} key={`week-day-${index}`}>
          {dayjs(day).format("dd")}
        </div>
      ))}
    </div>
  );
};

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const getCalendarData = () => {
    axios
      .get(`${ApiRoutes.auth.user.calendar}`)
      .then((res) => {
        const rawData = JSON.parse(res.data.data);
        const filterData = rawData.map((item) => {
          return {
            year: `${moment(item.Datetime)
              .format("MMMM YYYY")
              .replace("June", "Jun")
              .replace("July", "Jul")
              .replace("August", "Aug")
              .replace("September", "Sep")
              .replace("October", "Oct")
              .replace("November", "Nov")
              .replace("December", "Dec")
              .replace("January", "Jan")
              .replace("February", "Feb")
              .replace("March", "Mar")
              .replace("April", "Apr")}`,
            day: parseFloat(moment(item.Datetime).format("D")),
            type: item.Type.toLowerCase(),
            doctorName:
              item.Type === "Flight"
                ? item.DoctorNameSurname
                : item.DoctorNameSurname,
            desc: item.OperationName,
            time: item.Time,
            title: item.Type === "Hotel" ? item.HotelName : item.Title,
          };
        });

        setData(filterData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCalendarData();
  }, []);

  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf("month"),
    [selectedDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    let dates = [];
    for (let i = 0; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    let datesWeek = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day").toDate();
      datesWeek.push(date);
    }
    return datesWeek;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  return (
    <>
      {!loading && data ? (
        <div className={style.calendar}>
          <HeadPart
            leftClick={() => {
              setSelectedDate((date) => date.subtract(1, "month"));
            }}
            rightClick={() => {
              setSelectedDate((date) => date.add(1, "month"));
            }}
            month={selectedDate.clone().format("MMM YYYY")}
          />
          <WeekPart week={generateWeeksOfTheMonth[0]} />

          <a href="/calendar" className={style.dayWrapper}>
            {generateWeeksOfTheMonth.map((week, weekIndex) => (
              <div className={style.dayWeek} key={`week-${weekIndex}`}>
                {week.map((day, dayIndex) => {
                  const findDate = data.filter((i) => {
                    return (
                      i.day === day.getDate() &&
                      selectedDate.clone().format("MMM YYYY") === i.year
                    );
                  })[0];

                  const isThisMonth =
                    selectedDate.clone().toDate().getMonth() === day.getMonth();

                  return (
                    <div
                      key={`day-${dayIndex}`}
                      className={`${style.dayCell} ${
                        selectedDate.clone().toDate().getMonth() !==
                        day.getMonth()
                          ? style.nextMonth
                          : dayjs(currentDay).isSame(day, "date")
                          ? style.today
                          : style.default
                      }`}
                    >
                      {findDate && isThisMonth ? (
                        findDate.type === "flight" ? (
                          <div className={style.orangeCircle} />
                        ) : findDate.type === "operation" ? (
                          <div className={style.blueCircle} />
                        ) : (
                          <div className={style.secondaryBlueCircle} />
                        )
                      ) : null}
                      <span>{day.getDate()}</span>
                    </div>
                  );
                })}
              </div>
            ))}
          </a>
        </div>
      ) : null}
    </>
  );
};

export default Calendar;
