import style from "./feedback.module.scss";
import { FeedbackIcon } from "../../../assets/icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { ApiRoutes } from "../../../utils/ApiRoutes";
import { LangData } from "../../../data/LangData";
import { useSelector } from "react-redux";

const Feedback = () => {
  const [currentVal, setCurrentVal] = useState("");
  const [success, setSucess] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);

  const sendFeeback = () => {
    axios
      .post(`${ApiRoutes.auth.user.sendFeedback}?note=${currentVal}`)
      .then((res) => {
        setSucess(true);
        setCurrentVal("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setSucess(false);
    }, 5000);
  }, [success]);

  console.log("success", success);

  return (
    <div className={style.feedback}>
      <div className={style.title}>
        <h2>{LangData[lang].feedBack.title}</h2>
        <h3>{LangData[lang].feedBack.subTitle}</h3>
      </div>
      <div className={style.content}>
        {/* <div className={style.buttonWrapper}>
          <button>
            <FeedbackIcon type={'1'} />
          </button>
          <button>
            <FeedbackIcon type={'2'} />
          </button>
          <button>
            <FeedbackIcon />
          </button>
        </div> */}
        <label>
          <h5>{LangData[lang].feedBack.text}</h5>
          <textarea
            value={currentVal}
            onChange={(e) => {
              setCurrentVal(e.target.value);
            }}
          />
        </label>
        {success ? (
          <span className={style.success}>
            {LangData[lang].feedBack.success}
          </span>
        ) : null}
        <button
          onClick={() => {
            if (currentVal.length > 5) {
              sendFeeback();
            }
          }}
          disabled={success}
          className={style.feedbackButton}
        >
          {LangData[lang].feedBack.send}
        </button>
      </div>
    </div>
  );
};

export default Feedback;
