import style from "./topbar.module.scss";
import { ListIcon } from "../../../assets/icon";
import { useState } from "react";

const TopFilterBar = ({ typeChange, lang, click }) => {
  const [type, setType] = useState("grid");
  return (
    <div className={style.topbar}>
      {/* <select>
        <option>The next most convenient operation</option>
        <option>The next least convenient operation</option>
      </select>
      <select>
        <option>Price</option>
        <option>Rating</option>
      </select>
      <select>
        <option>Rank</option>
        <option>Resume</option>
      </select>
      <select>
        <option>Top Ranking</option>
        <option>Least Ranking</option>
      </select> */}
      <div className={style.buttonWrapper}>
        <button
          className={type === "grid" && style.active}
          onClick={() => {
            setType("grid");
            typeChange("grid");
          }}
        >
          <ListIcon type={"grid"} />
        </button>
        <button
          className={type === "list" && style.active}
          onClick={() => {
            setType("list");
            typeChange("list");
          }}
        >
          <ListIcon />
        </button>
      </div>
      <button onClick={click} className={style.filter}>
        Filter
      </button>
    </div>
  );
};

export default TopFilterBar;
