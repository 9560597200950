import style from "./hospitalRegister.module.scss";
import {
  EmailInput,
  PasswordInput,
  RememberMe,
  TextInput,
  PhoneInput,
  DateInput,
  SelectInput,
  RegionInput,
  CityInput,
} from "../../components/Input/Input";
import { AuthTitle } from "../../components/Title/Title";
import { AuthButton } from "../../components/Button/Button";
import { AuthFooter, LangSelection } from "../../components";
import HospitalInfo from "./HospitalInfo";

import LoginImage from "../../assets/img/auth/register.png";
import Logo from "../../assets/img/logo/primary-logo.svg";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { ApiRoutes } from "../../utils/ApiRoutes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import WhiteLogo from "../../assets/img/logo/white-logo.svg";
import useWindowDimensions from "../../helpers/windowWidth";
import { useEffect, useState } from "react";

import { BASE_URL } from "../../utils/ApiRoutes";
import { LangData } from "../../data/LangData";

const DoctorRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [hospitalGuid, setHospitalGuid] = useState("");
  const [step, setStep] = useState("register");
  const lang = useSelector((state) => state.settings.setting.lang);
  const [isLoad, setLoad] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.hospital.guid}?lang=${lang}`)
      .then((res) => {
        setHospitalGuid(res.data.data.split(`"`)[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const yesterday = new Date(Date.now() - 86400000);
  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, `${LangData[lang].errors.nameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    phone: Yup.string()
      .matches(phoneRegExp, `${LangData[lang].errors.correctphone}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),

    region: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    provice: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    district: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    address: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    password: Yup.string()
      .min(8, `${LangData[lang].errors.correctpassword}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    passwordAgain: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${LangData[lang].errors.repeatpassword}`
      )
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const registerSubmit = (values) => {
    axios
      .post(`${ApiRoutes.auth.user.register}?lang=${lang}`, {
        mail: values.email,
        password: values.password,
        typeId: 2,
        name: values.name,
        phone: values.phone,
        region: values.region,
        district: values.district,
        provice: values.provice,
        country: values.provice,
        address: values.address,
        guid: hospitalGuid,
        zipcode: values.zipcode,
      })
      .then(async (res) => {
        console.log(res.data);

        if (res.data.caption === "Success") {
          const userData = await JSON.parse(res.data.data);

          console.log("userTOKEN", userData.token);

          localStorage.removeItem("preRegisterDocto");

          axios.defaults.baseURL = BASE_URL;
          await axios.interceptors.request.use(
            async (config) => {
              // const token = await AsyncStorage.getItem('token');
              config.headers["Authorization"] = `Bearer ${userData.token}`;
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );

          await localStorage.setItem("preRegisterDoctor", hospitalGuid);
          await localStorage.setItem("token", userData.token);
          await setStep("info");
          await setLoad(false);

          // dispatch(
          //   userLogin({
          //     token: userData.token,
          //     userData: userData.account,
          //   })
          // );

          //   navigate('/dashboard');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
  return (
    <div className={style.register}>
      <div
        style={{
          position: "fixed",
          right: "30px",
          bottom: "30px",
        }}
      >
        <LangSelection />
      </div>
      <img
        className={style.logo}
        src={width < 940 ? WhiteLogo : Logo}
        alt="primary-logo"
      />
      {hospitalGuid && (
        <>
          {step === "register" ? (
            <div className={style.content}>
              <div className={style.wrapper}>
                <AuthTitle title={LangData[lang].register.hospitalRegister} />
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    region: "",
                    provice: "",
                    district: "",
                    address: "",
                    password: "",
                    passwordAgain: "",
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={(values) => {
                    console.log(values);
                    registerSubmit(values);
                    setLoad(true);
                  }}

                  // innerRef={formikRef}
                >
                  {({ errors, touched, values, handleSubmit }) => (
                    <Form id="doctorRegisterForm" onSubmit={handleSubmit}>
                      <div className={style.inputWrapper}>
                        <div className={style.column}>
                          <TextInput
                            name={"name"}
                            value={values.name}
                            title={LangData[lang].form.hospitalName}
                            placeholder={LangData[lang].form.hospitalName}
                          />
                          {errors.name && touched.name ? (
                            <span>{errors.name}</span>
                          ) : null}

                          <EmailInput name={"email"} value={values.email} />
                          {errors.email && touched.email ? (
                            <span>{errors.email}</span>
                          ) : null}
                          <PhoneInput name={"phone"} value={values.phone} />
                          {errors.phone && touched.phone ? (
                            <span>{errors.phone}</span>
                          ) : null}
                          <TextInput
                            name={"address"}
                            value={values.address}
                            title={LangData[lang].form.address}
                            placeholder={LangData[lang].form.addressPlaceholder}
                          />
                          {errors.address && touched.address ? (
                            <span>{errors.address}</span>
                          ) : null}
                          <TextInput
                            name={"zipcode"}
                            value={values.zipcode}
                            title={LangData[lang].form.zipcode}
                            placeholder={LangData[lang].form.zipcodePlaceholder}
                          />
                          {errors.zipcode && touched.zipcode ? (
                            <span>{errors.zipcode}</span>
                          ) : null}
                        </div>
                        <div className={style.column}>
                          <RegionInput
                            name={"region"}
                            value={values.region}
                            title={LangData[lang].form.region}
                            placeholder={LangData[lang].form.regionPlaceholder}
                          />
                          {errors.city && touched.city ? (
                            <span>{errors.city}</span>
                          ) : null}
                          <CityInput
                            name={"provice"}
                            value={values.provice}
                            title={LangData[lang].form.province}
                            placeholder={
                              LangData[lang].form.provincePlaceholder
                            }
                            data={values.region}
                          />
                          {errors.provice && touched.provice ? (
                            <span>{errors.provice}</span>
                          ) : null}

                          <TextInput
                            name={"district"}
                            value={values.district}
                            title={LangData[lang].form.district}
                            placeholder={
                              LangData[lang].form.districtPlaceholder
                            }
                          />
                          {errors.district && touched.district ? (
                            <span>{errors.district}</span>
                          ) : null}

                          <PasswordInput
                            value={values.password}
                            name="password"
                          />
                          {errors.password && touched.password ? (
                            <span>{errors.password}</span>
                          ) : null}
                          <PasswordInput
                            value={values.passwordAgain}
                            name="passwordAgain"
                            again
                          />
                          {errors.passwordAgain && touched.passwordAgain ? (
                            <span>{errors.passwordAgain}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className={style.buttonWrapper}>
                        <AuthButton
                          text={LangData[lang].register.title}
                          type={"submit"}
                          isLoad={isLoad}
                        />
                        <div className={style.login}>
                          {LangData[lang].form.doYouHaveAccount}{" "}
                          <a href="/login">{LangData[lang].login.title}</a>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <HospitalInfo hospitalId={hospitalGuid} />
          )}
        </>
      )}
      <AuthFooter />
    </div>
  );
};

export default DoctorRegister;
