import Logo from "../../assets/img/logo/primary-logo.svg";
import style from "./home.module.scss";
import {
  HomeHeader,
  Head,
  InfoPart,
  Faq,
  HomeFooter,
  TwoCard,
  FeaturedDoctor,
  Stories,
  Blog,
} from "../../components";
import { CountryIconFlag, BigCalendarArrow } from "../../assets/icon";
import { useSelector, useDispatch } from "react-redux";
import { langChange } from "../../redux/actions";
import YusufMaria from "../../components/Home/YusufMaria/YusufMaria";

const SVG1 = () => {
  return (
    <svg
      width="1248"
      height="2078"
      viewBox="0 0 1248 2078"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1897_18192)">
        <ellipse
          cx="289.904"
          cy="1038.73"
          rx="549.842"
          ry="312.202"
          transform="rotate(55.2549 289.904 1038.73)"
          fill="url(#paint0_linear_1897_18192)"
          fill-opacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1897_18192"
          x="-668.09"
          y="0.0273438"
          width="1915.99"
          height="2077.42"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="276.5"
            result="effect1_foregroundBlur_1897_18192"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1897_18192"
          x1="874.316"
          y1="1079.83"
          x2="172.626"
          y2="1551.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#783CC4" />
          <stop offset="0.355302" stop-color="#9346F5" stop-opacity="0.37" />
          <stop offset="0.650852" stop-color="#8AE3FF" stop-opacity="0.73" />
          <stop offset="1" stop-color="#2602FF" stop-opacity="0.58" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SVG2 = () => {
  return (
    <svg
      width="933"
      height="1794"
      viewBox="0 0 933 1794"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1897_18193)">
        <ellipse
          cx="868.031"
          cy="896.703"
          rx="345.678"
          ry="312.202"
          transform="rotate(105 868.031 896.703)"
          fill="url(#paint0_linear_1897_18193)"
          fill-opacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1897_18193"
          x="0.390625"
          y="0.0859375"
          width="1735.28"
          height="1793.23"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="276.5"
            result="effect1_foregroundBlur_1897_18193"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1897_18193"
          x1="1235.44"
          y1="937.795"
          x2="691.911"
          y2="1167.64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#783CC4" />
          <stop offset="0.355302" stop-color="#9346F5" stop-opacity="0.37" />
          <stop offset="0.650852" stop-color="#8AE3FF" stop-opacity="0.73" />
          <stop offset="1" stop-color="#2602FF" stop-opacity="0.58" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SVG3 = () => {
  return (
    <svg
      width="1036"
      height="1766"
      viewBox="0 0 1036 1766"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1897_20976)">
        <ellipse
          cx="153.19"
          cy="883.191"
          rx="345.678"
          ry="312.202"
          transform="rotate(45 153.19 883.191)"
          fill="url(#paint0_linear_1897_20976)"
          fill-opacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1897_20976"
          x="-729.176"
          y="0.823242"
          width="1764.73"
          height="1764.74"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="276.5"
            result="effect1_foregroundBlur_1897_20976"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1897_20976"
          x1="520.602"
          y1="924.284"
          x2="-22.9291"
          y2="1154.12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#783CC4" />
          <stop offset="0.355302" stop-color="#9346F5" stop-opacity="0.37" />
          <stop offset="0.650852" stop-color="#8AE3FF" stop-opacity="0.73" />
          <stop offset="1" stop-color="#2602FF" stop-opacity="0.58" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SVG4 = () => {
  return (
    <svg
      width="1008"
      height="1688"
      viewBox="0 0 1008 1688"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1897_14024)">
        <ellipse
          cx="982.641"
          cy="1138.34"
          rx="630.5"
          ry="358"
          transform="rotate(117.06 982.641 1138.34)"
          fill="url(#paint0_linear_1897_14024)"
          fill-opacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1897_14024"
          x="0.789062"
          y="0.568359"
          width="1963.7"
          height="2275.55"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="276.5"
            result="effect1_foregroundBlur_1897_14024"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1897_14024"
          x1="1652.78"
          y1="1185.46"
          x2="848.159"
          y2="1726.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#783CC4" />
          <stop offset="0.355302" stop-color="#9346F5" stop-opacity="0.37" />
          <stop offset="0.650852" stop-color="#8AE3FF" stop-opacity="0.73" />
          <stop offset="1" stop-color="#2602FF" stop-opacity="0.58" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Home = () => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const dispatch = useDispatch();

  return (
    <div className={style.home}>
      <div className={style.svg1}>
        <SVG1 />
      </div>
      <div className={style.svg2}>
        <SVG2 />
      </div>
      <div className={style.svg3}>
        <SVG3 />
      </div>
      <div className={style.svg4}>
        <SVG4 />
      </div>
      <div className={style.langWrapper}>
        <div className={style.countryFlag}>
          <CountryIconFlag type={lang} />
        </div>
        <div className={style.arrow}>
          <BigCalendarArrow />
        </div>
        <select
          onChange={(e) => {
            dispatch(langChange(e.target.value));
          }}
          value={lang}
          className={style.langWrapper}
        >
          <option value={"de"}>Deutsch</option>
          <option value={"tr"}>Türkçe</option>
          <option value={"en"}>English</option>
          <option value={"italy"}>Italiano</option>
          <option value={"es"}>Español</option>
          <option value={"sr"}>Srpski</option>
          <option value={"bg"}>Cрпски</option>
          <option value={"cz"}>Čeština</option>
          <option value={"niedl"}>Nederlands</option>
          <option value={"fr"}>Français</option>
        </select>
      </div>
      <div className={style.wrapper}>
        <HomeHeader />
        <Head lang={lang} />
        <FeaturedDoctor lang={lang} />
        <Stories lang={lang} />
        <InfoPart lang={lang} />
        <YusufMaria lang={lang} />
       {/* <TwoCard lang={lang} />*/}
         <Blog lang={lang} /> 
        <Faq lang={lang} />
        <HomeFooter />
      </div>
    </div>
  );
};

export default Home;
