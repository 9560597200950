import style from "./button.module.scss";

const AuthButton = ({ text, type, isLoad, click }) => {
  return type === "register" ? (
    <a href="/register" className={`${style.authButton}`}>
      {text}
    </a>
  ) : (
    <button
      className={`${style.authButton} ${type === "submit" && style.submit} ${
        isLoad ? style.load : null
      }`}
      type={type === "submit" ? "submit" : "button"}
      disabled={isLoad}
      onClick={click}
    >
      {isLoad ? (
        <div class={style.ldsRipple}>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>{text}</>
      )}
    </button>
  );
};

export { AuthButton };
