import {
  NotificationIcon,
  SearchIcon,
  SettingsIcon,
  SignOutIcon,
} from "../../assets/icon";
import style from "./topbar.module.scss";
import EmptyPhoto from "../../assets/img/empty-photo.png";
import Logo from "../../assets/img/logo/primary-logo.svg";
import useWindowDimensions from "../../helpers/windowWidth";
import { HamburgerIcon, HeaderArrow } from "../../assets/icon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import LangSelection from "../LangSelection/LangSelection";
import { LangData } from "../../data/LangData";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { signOut } from "../../redux/actions";

const TopBar = ({ hamburgerClick }) => {
  const { width } = useWindowDimensions();
  // const userData = useSelector((state) => state.user.user);
  const [userData, setUserdata] = useState();
  const dispatch = useDispatch();
  const [word, setWord] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.settings.setting.lang);
  const currentPath = location.pathname;
  const hideArr = ["/dashboard", "/search-body"];
  const titleArr = [
    {
      path: "/profile",
      title: "Profile",
    },
    {
      path: "/message",
      title: "Message",
    },
    {
      path: "/operations",
      title: "Operations",
    },
    {
      path: "/calendar",
      title: "Calendar",
    },
    {
      path: "/doctor/farah",
      title: "Dr. Farah",
    },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?keyword=${word}`);
    }
  };

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.user.getProfile}?lang=${lang}`)
      .then((res) => {
        setUserdata(JSON.parse(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {userData ? (
        <div className={style.topBar}>
          {width < 940 ? (
            <a href="/dashboard" className={style.logo}>
              <img src={Logo} alt="logo" />
            </a>
          ) : null}
          <label>
            <input
              onChange={(e) => setWord(e.target.value)}
              type={"text"}
              placeholder={LangData[lang].search.title}
              onKeyDown={handleKeyDown}
            />
            <button
              onClick={() => {
                navigate(`/search?keyword=${word}`);
              }}
            >
              <SearchIcon />
            </button>
          </label>
          <div className={style.right}>
            <div className={style.userCard}>
              {userData.Name ? (
                <img
                  src={userData.Picture ? userData.Picture : EmptyPhoto}
                  alt="person"
                />
              ) : null}
              {width < 940 ? (
                <button onClick={hamburgerClick}>
                  <HamburgerIcon />
                </button>
              ) : null}
              <div className={style.info}>
                <h6>{LangData[lang].search.welcome},</h6>
                <h4>{userData.Name + " " + userData.Surname}</h4>
              </div>
            </div>
            <div className={style.buttonWrapper}>
              <LangSelection />

              <button className={style.main}>{<NotificationIcon />}</button>
              <button className={style.main}>
                {<SettingsIcon />}
                <div className={style.popupButton}>
                  <button
                    onClick={() => {
                      delete axios.defaults.headers["Authorization"];
                      dispatch(signOut());
                      localStorage.removeItem("token");
                    }}
                  >
                    <SignOutIcon /> {LangData[lang].sideBar.signOut}
                  </button>
                </div>
              </button>
            </div>
          </div>
          {width < 940 && !hideArr.includes(currentPath) && (
            <div className={style.headerBottom}>
              <a href="/dashboard">
                <HeaderArrow />
              </a>
              <span>
                <a href="/dashboard">{LangData[lang].sideBar.dashboard} / </a>
                {titleArr.filter((i) => i.path === currentPath)[0]?.title}
              </span>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default TopBar;
