import style from "./credit.module.scss";
import { useState } from "react";
import useWindowDimensions from "../../helpers/windowWidth";
import { Sidebar, TopBar } from "../../components";

const Credit = () => {
  const [isOpen, setOpen] = useState(false);
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  return (
    <div className={style.credit}>
      <Sidebar
        isOpen={sideBar || width > 940}
        closeClick={() => setSidebar(false)}
        searchTrigger={() => {}}
      />
      <TopBar hamburgerClick={() => setSidebar(true)} />
      <div className={style.content}>
        <iframe src="/credit.html" />
      </div>
    </div>
  );
};

export default Credit;
