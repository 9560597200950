import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { ProtectedRoute } from './routes/ProtectedRoute';
import ScrollTop from './helpers/ScrollTop';
// import { Preloader } from './components';
import axios from 'axios';
import { BASE_URL } from './utils/ApiRoutes';

export const history = createBrowserHistory();

const App = () => {
  // const [isLoad, setLoad] = useState(false);
  const [isLanguageLoad, setLanguageLoad] = useState(false);
  const isAuth = localStorage.getItem('token');

  axios.defaults.baseURL = BASE_URL;
  axios.interceptors.request.use(
    async config => {
      // const token = await AsyncStorage.getItem('token');
      if (isAuth) {
        config.headers['Authorization'] = `Bearer ${isAuth}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const currentLanguage = localStorage.getItem('lang');

    if (!currentLanguage) {
      localStorage.setItem('lang', 'tr');
      setLanguageLoad(true);
    } else {
      setLanguageLoad(true);
    }
  }, []);

  // useEffect(() => {

  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoad(true);
  //   }, 1000);
  // }, []);

  // function docReady(fn) {
  //   // see if DOM is already available
  //   console.log(document.readyState);
  //   if (
  //     document.readyState === 'complete' ||
  //     document.readyState === 'interactive'
  //   ) {
  //     // call on next available tick
  //     setTimeout(fn, 1);
  //   } else {
  //     document.addEventListener('DOMContentLoaded', fn);
  //   }
  // }

  // useEffect(() => {
  //   docReady(function () {
  //     // DOM is loaded and ready for manipulation here
  //     setLoad(true);
  //   });
  // }, []);

  if (
    isAuth &&
    (window.location.pathname === '/login' ||
      window.location.pathname === '/register' ||
      window.location.pathname === '/doctor/register')
  ) {
    window.location.href = '/dashboard';
    return;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        {/* {!isLoad && <Preloader />} */}

        <Routes>
          {isLanguageLoad &&
            routes.map(route => {
              return (
                <Route
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedRoute>{route.component}</ProtectedRoute>
                    ) : (
                      route.component
                    )
                  }
                />
              );
            })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
