import style from './questioncard.module.scss';
import {
  FlightIcon,
  OperationIcon,
  StarIcon,
  AnswerCheck,
} from '../../../assets/icon';
import DoctorImage from '../../../assets/img/doctor-image.png';
import { useState } from 'react';

const questionData = [
  {
    question:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    answers: [
      'It is a long established fact that a reader will be distracted by.',
      "opposed to using 'Content here, content here', making it look like readable English.",
      "and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
      'also the leap into electronic typesetting',
    ],
  },
  {
    question:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    answers: [
      'It is a long established fact that a reader will be distracted by.',
      "opposed to using 'Content here, content here', making it look like readable English.",
      "and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
      'also the leap into electronic typesetting',
    ],
  },
  {
    question:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    answers: [
      'It is a long established fact that a reader will be distracted by.',
      "opposed to using 'Content here, content here', making it look like readable English.",
      "and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
      'also the leap into electronic typesetting',
    ],
  },
  {
    question:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    answers: [
      'It is a long established fact that a reader will be distracted by.',
      "opposed to using 'Content here, content here', making it look like readable English.",
      "and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
      'also the leap into electronic typesetting',
    ],
  },
];

const QuestionCard = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedStar, setSelectedStar] = useState(0);

  return (
    <div className={style.questionWrapper}>
      <div className={style.questionCard}>
        <div className={style.cardInfo}>
          <div className={style.infoInner}>
            <div className={style.right}>
              <button
                onClick={() => {
                  setSelectedStar(1);
                }}
                className={selectedStar > 0 && style.active}
              >
                <StarIcon type={selectedStar > 0 && 'on'} />
              </button>
              <button
                onClick={() => {
                  setSelectedStar(2);
                }}
                className={selectedStar > 1 && style.active}
              >
                <StarIcon type={selectedStar > 1 && 'on'} />
              </button>
              <button
                onClick={() => {
                  setSelectedStar(3);
                }}
                className={selectedStar > 2 && style.active}
              >
                <StarIcon type={selectedStar > 2 && 'on'} />
              </button>
              <button
                onClick={() => {
                  setSelectedStar(4);
                }}
                className={selectedStar > 3 && style.active}
              >
                <StarIcon type={selectedStar > 3 && 'on'} />
              </button>
              <button
                onClick={() => {
                  setSelectedStar(5);
                }}
                className={selectedStar > 4 && style.active}
              >
                <StarIcon type={selectedStar > 4 && 'on'} />
              </button>
            </div>
            <div className={style.doctor}>
              <img src={DoctorImage} alt="doctor" />
              <h5>{'Doc. Dr. Stephan'}</h5>
            </div>
            <div className={style.operation}>
              <div className={style.icon}>
                {<OperationIcon icon={'rhinoplasty'} />}
              </div>
              <h5>{'Rhinoplasty'}</h5>
            </div>
            <div className={style.flight}>
              <div className={style.icon}>
                <FlightIcon />
              </div>
              <div className={style.info}>
                <h6>{'America - Istanbul'}</h6>
                <p>{'00:00 - 08:30'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.questionSide}>
          {questionData.map((item, index) => {
            return (
              index === currentQuestion && (
                <div className={style.currentQuestion}>
                  <h2>Question {index + 1}</h2>
                  <h3>{item.question}</h3>
                  <div className={style.answerWrapper}>
                    {item.answers.map((answer, answerIndex) => {
                      return (
                        <button
                          className={`${style.answer} ${
                            answers.length &&
                            answers[index].includes(answerIndex) &&
                            style.active
                          }`}
                          onClick={() => {}}
                        >
                          <div>{<AnswerCheck />}</div>
                          <span>{answer}</span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              )
            );
          })}
        </div>
        <div className={style.bottomBar}>
          <div className={style.bottomInfo}>
            <div className={style.barWrapper}>
              <div
                style={{
                  width: `${
                    (100 / questionData.length) * (currentQuestion + 1)
                  }%`,
                }}
                className={style.bar}
              />
            </div>
            <span>
              {currentQuestion + 1} / {questionData.length}
            </span>
          </div>
          <button
            onClick={() => {
              if (currentQuestion !== questionData.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
              }
            }}
          >
            {currentQuestion === questionData.length - 1
              ? 'Give Feedback'
              : 'Next Question'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
