import style from "./optioncard.module.scss";
import { FlightIcon, LocationIcon } from "../../../assets/icon";

const Card = ({ title, location, hotel, date, time, type }) => {
  console.log("type", type);
  return (
    <div className={style.card}>
      <h3>{title}</h3>
      <div className={style.infoWrapper}>
        <div className={style.info}>
          {type === "flight" ? <FlightIcon /> : <LocationIcon />}
          <div>
            <h4>{date}</h4>
            <h5>{time}</h5>
          </div>
        </div>
      </div>
      {/* <a href="/dashboard">View Details</a> */}
    </div>
  );
};

// const data = [
//   {
//     title: 'Option 1',
//     date: '10.12.22 - 26.12.22',
//     time: '00:00 - 08:30',
//     hotel: 'Point Hotel',
//     location: 'America - İstanbul',
//   },
//   {
//     title: 'Option 1',
//     date: '10.12.22 - 26.12.22',
//     time: '00:00 - 08:30',
//     hotel: 'Point Hotel',
//     location: 'America - İstanbul',
//   },
//   {
//     title: 'Option 1',
//     date: '10.12.22 - 26.12.22',
//     time: '00:00 - 08:30',
//     hotel: 'Point Hotel',
//     location: 'America - İstanbul',
//   },
// ];

const OptionCard = ({ data }) => {
  return (
    <div className={style.optionCard}>
      <div className={style.cardWrapper}>
        {data.map((item) => {
          return (
            <Card
              time={item.time}
              title={item.desc}
              date={item.date}
              hotel={item.hotel}
              location={item.location}
              type={item.type}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OptionCard;
