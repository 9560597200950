import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

const ScrollTop = () => {
  const navigate = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return null;
};

export default ScrollTop;
